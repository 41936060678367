import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { OptionsWrapper } from '../../../../components/MiniPlaylist/NewPlaylist2023/styled';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import EditPlaylist from '../../../../redux/playlists/types';
import { GlobalOneLineTextWrapped } from '../../../../components/ComponentDescription/2023/styled';
import {
  PlaylistSelectorPlaylistTitleWrapper,
  PlaylistSelectorScrollWrapper,
  PlaylistSelectorSearchWrapper,
  PlaylistSelectorSelectorWrapper,
  PlaylistSelectorSeparator,
} from './styled';
import { DATA_PARENTS } from '../../../../utils/constants';

const PlaylistSelector = ({
  isWholePlaylistOperation,
  isForLib,
  pageID,
  lcID,
  refMenu,
  setIsDropDownVisible,
  upvCard,
  ...props
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [positionOfPlaylistSelector, setPositionOfPlaylistSelector] = useState('left');
  const [search, setSearch] = useState('');

  const playlists = useSelector((state) => state.playlists);
  const currentPage = useSelector((state) => state.currentPage);
  const [displayItems, setDisplayItems] = useState(
    Object.values(playlists).filter(
      (item) => !item.movedToTrash && !!item?.title?.includes(search),
    ),
  );

  const movePageToPlaylist = useCallback((playlistId, title) => (e) => {
    e?.stopPropagation();
    if (props.isMultiselect) {
      props.createPlaylist(playlistId);
      return;
    }
    const playlistIdTo = playlistId || uuid();

    if (isWholePlaylistOperation) {
      dispatch(
        actionCreator(EditPlaylist.AddAllDownloadableItemsIntoPlaylist, {
          fromPlaylist: currentPage.id,
          history,
          toPlaylist: playlistIdTo,
          title,
          upvCard,
        }),
      );
      if (setIsDropDownVisible) {
        setIsDropDownVisible(false);
      }
      return;
    }
    if (props.isMultiActionPanel && props.selectedItemsArray.length > 0) {
      dispatch(
        actionCreator(EditPlaylist.AddAllDownloadableItemsIntoPlaylist, {
          fromPlaylist: currentPage.id,
          history,
          toPlaylist: playlistIdTo,
          selectedItems: props.selectedItemsArray,
          title,
          upvCard,
        }),
      );
      if (setIsDropDownVisible) {
        setIsDropDownVisible(false);
      }
      return;
    }
    dispatch(
      actionCreator(EditPlaylist.AddAllDownloadableItemsIntoPlaylist, {
        fromPlaylist: currentPage.id,
        history,
        toPlaylist: playlistIdTo,
        selectedItems: [pageID || currentPage.id],
        title,
        upvCard,
      }),
    );
    if (setIsDropDownVisible) {
      setIsDropDownVisible(false);
    }
  }, [currentPage.id, isWholePlaylistOperation, lcID, pageID, props, setIsDropDownVisible, upvCard]);

  useEffect(() => {
    if (refMenu?.current?.dataset?.parent === 'miniPage') {
      const { x } = refMenu?.current?.getBoundingClientRect();
      if (x < 600) {
        setPositionOfPlaylistSelector('right');
      } else {
        setPositionOfPlaylistSelector('left');
      }
    }
    if (refMenu?.current?.dataset?.parent === 'PlaylistBuilderLibrary') {
      const { x } = refMenu?.current?.getBoundingClientRect();
      if ((document.body.clientWidth - x) > 600) {
        setPositionOfPlaylistSelector('right');
      } else {
        setPositionOfPlaylistSelector('left');
      }
    }
  }, [refMenu.current]);

  useEffect(() => {
    dispatch(
      actionCreator(EditPlaylist.RequestUserPlaylists, { parentId: lcID }),
    );
  }, []);

  useEffect(() => {
    setDisplayItems(
      Object.values(playlists).filter(
        (item) => !item.movedToTrash && !!(item?.title || t('defaultSmartFileTitleT'))?.toLowerCase()
          .includes(search.toLowerCase()),
      ),
    );
  }, [playlists, search]);

  // if (!show) {
  //   return <></>;
  // }
  return (
    <PlaylistSelectorScrollWrapper
      side={positionOfPlaylistSelector}
      isBatchActions={props.isBatchActions}
      data-parent={`${DATA_PARENTS.checkItem} miniPage`}
      isMultiselect={props.isMultiselect}
      className="hidePlaylistSelector"
      isMultiActionPanel={props.isMultiActionPanel}
    >
      <PlaylistSelectorSelectorWrapper
        ref={refMenu}
        // onMouseLeave={hide}
        data-parent={`${DATA_PARENTS.checkItem} miniPage`}
        isForLib={isForLib}
      >
        <OptionsWrapper
          data-parent={`${DATA_PARENTS.checkItem} miniPage`}
          isFirst
        >
          {t('selectSmartFileUpT')}
        </OptionsWrapper>
        <PlaylistSelectorSearchWrapper
          value={search}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t('searchaSmartFileT')}
          data-parent={`${DATA_PARENTS.checkItem} miniPage`}
        />
        <PlaylistSelectorPlaylistTitleWrapper
          onClick={movePageToPlaylist()}
          data-parent={DATA_PARENTS.checkItem}
        >
          {t('createNewSmartFileT')}
        </PlaylistSelectorPlaylistTitleWrapper>
        {!!displayItems.length && <PlaylistSelectorSeparator data-parent={DATA_PARENTS.checkItem} />}
        {displayItems.map((res) => (
          <PlaylistSelectorPlaylistTitleWrapper
            data-parent={`${DATA_PARENTS.checkItem} miniPage`}
            onClick={movePageToPlaylist(res.id, res.title)}
            key={res.id}
          >
            <GlobalOneLineTextWrapped data-parent={DATA_PARENTS.checkItem}>
              {' '}
              {res.title ? res.title : t('defaultSmartFileTitleT')}
            </GlobalOneLineTextWrapped>
          </PlaylistSelectorPlaylistTitleWrapper>
        ))}
        {!displayItems.length && search && (
          <OptionsWrapper data-parent={DATA_PARENTS.checkItem} isFirst>{t('noResultT')}</OptionsWrapper>
        )}
      </PlaylistSelectorSelectorWrapper>
    </PlaylistSelectorScrollWrapper>
  );
};

export default PlaylistSelector;
