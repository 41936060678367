import {
  call,
  put,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { all, delay, select } from '@redux-saga/core/effects';
import { v4 as uuidv4, v4 } from 'uuid';
import axios from 'axios';
import { convertToRaw } from 'draft-js';
import EditPlaylist from '../redux/playlists/types';
import { CurrentPage, GET_CURRENT_PLAYLIST, SAVE_CURRENT_PLAYLIST } from '../redux/currentPage/types';
import {
  actionAddPlaylistToChannelR,
  actionDuplicatePlaylist,
  actionEditDescriptionPlaylistR,
  actionEditPublishStateR,
  actionPublishPlaylistS,
  actionRemoveImageRedux,
  actionRemovePlaylistFromChannelR,
  actionUpdatePlaylistTitle,
} from '../redux/playlists/action';
import {
  request,
  requestChannelRoleChange,
  requestCreateContactContact,
  requestCreatePlaylist,
  requestCreatePlaylistManager,
  requestCreatePlaylistWithSelectedLibItems,
  requestCropPlaylistCover,
  requestDeleteChannels,
  requestDeletePlaylistPlacement,
  requestGetLinkPagesInfo,
  requestGetPublishOptions,
  requestGetSomePlaylistContent,
  requestGetSomeUserContent,
  requestGetUserShareOptions, requestManyPlaylistPublish,
  requestPlaylistDiscard,
  requestPlaylistMoveToTrash,
  requestPlaylistPublish,
  requestSendEmailToSharingPlaylist,
  requestSendEmailToSharingPlaylistMany, requestShareToWebPlaylistMany,
  requestUnPublishPlaylist,
  requestUpdateChannels,
  requestUpdateLibraryComponent,
  requestUpdateLinkPage,
  requestUpdatePlaylist,
  requestUpdatePlaylistManager,
  requestUpdatePlaylistManagerInChannel,
  requestUpdatePlaylistManagerOpen,
  requestUpdatePlaylistManagerPlacement,
  requestUpdateSingleUserShareOptions,
} from '../utils/request';
import {
  actionAddToBeScrollTo,
  actionCurrentEditTitle,
  actionDragPage,
  actionPageWillDownload,
  actionSaveCurrentPlaylist,
  actionSetAllUserShareState,
  actionSetGlobalShareStateToSingleUserState,
  actionSetSingleUserShareState,
  actionSwitchPage,
  actionTryPublishPlaylist,
  actionUpdatePlaylistLastModified,
  actionUpdateShareState,
  actionCreateLinkPageR,
} from '../redux/currentPage/action';
import {
  ADD_PAGE_AND_ADD_TO_BUILDER,
  APIGetPlaylistSharing,
  DEFAULT_DURATION,
  DEFAULT_POSITION_STEP,
  DEFAULT_TITLE,
  DUPLICATE_PLAYLIST,
  Group,
  MessageType,
  METHOD,
  NOTIFICATION_DURATIONS,
  PLAYLIST_ELEMENTS,
  REMOVE_CROP_URL,
  SPINNER_USAGE,
  TYPE,
} from '../utils/constants';
import {
  currentPage,
  getContentData,
  getCurrentPage,
  getSidebarContacts,
  getLibraryComponent,
  getCurrentPageLinkPages,
  getDashboardColumns,
  getHelp,
  getHistoryTrace,
  getLibraryCollections,
  getLibraryComponents,
  getMyChannels,
  getPages,
  getPlaylistResolver,
  getSelectedPage,
  getSupport,
  getUser,
  mapLinkPages,
  parseLinkPages,
  sendEventUpdate,
  showErrorMessage,
} from './sagasUtils';
import { MiniPlaylists } from '../redux/miniPlaylists/types';
import {
  actionGetMiniPlaylist,
  actionGetMiniPlaylistForce,
  actionSaveMiniPlaylist,
} from '../redux/miniPlaylists/action';
import {
  actionCleanBlinkId,
  actionJumpTo,
  actionSetBlinkId,
  actionShowMessage,
  actionToggleRequestSpinner,
} from '../redux/support/action';
import SupportAction from '../redux/support/types';
import { Tags } from '../redux/tags/types';
import { _actionRefreshUserTags } from '../redux/tags/action';
import { actionCloseModal, actionLazyLoading } from '../redux/user/action';
import {
  actionTogglePlaylistPublishInChannel,
  actionUpdateChannelR,
} from '../redux/channels/action';
import { Channels } from '../redux/channels/types';
import {
  calculateIndex,
  sanitizeToLoad,
  sanitizeToSave,
} from '../utils/helpers';
import { Category } from '../utils/dataConst';
import {
  actionAddLibraryComponent,
  actionToggleComponentFavorite,
  actionUpdateLibraryComponentInRedux,
} from '../redux/library/actions';
import { actionCreator } from '../shared/redux/actionHelper';
import DashboardColumns from '../redux/dashboardColumns/types';
import { HelpActions } from '../redux/help/types';
import downloadStatus, { parseUseInChannel } from '../utils/dataUtil';
import { ViewsActions } from '../redux/views/types';
import { sendMessage } from './SocketCluster/action';
import { ContentActionType } from '../redux/content/contentTypes';
import { playerDuplicateSharedComponent } from './librarySagas';
import Contacts from '../redux/contacts/types';
import { UnifiedHistory } from '../redux/history/types';
import { placementEnum } from '../utils/query/queryEnum';
import { queryCreateContact } from '../utils/query/helper';
import { DateRangeMark4SharedPlaylist } from '../components/DatePicker/helpers/enum';
import { isRoleInPlaylist, RolesForPlaylist } from '../utils/permissions';
import draftDataConvertToLoad from '../utils/draftDataConvert';
import { OutboxAction } from '../redux/outbox/types';
import { axiosAbortarium } from '../utils/axiosAbortarium';
import { ActionRequestProceedRemove } from '../redux/requestProceed/actions';
import { getLayersForPage } from '../shared/page/utils';
import { setServiceWorkerSharedJwt } from '../utils/registerServiceWorker';
import i18n from '../i18n';

function* createPlaylist(action) {
  const {
    idPlaylist,
    goToPlaylists,
    addToChannel,
    addToUser,
    tags = [],
    goBack,
  } = action.payload;

  try {
    yield put({ type: 'PAGE_IS_DOWNLOAD', payload: { isDownload: true } });
    goToPlaylists();

    const user = yield select(getUser);
    const [defaultCategory] = Object.values(Category);
    const playlistUUIDWrap = uuidv4();

    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const createDate = Math.floor(Date.now() / 1000);
    yield put(
      actionCreator(SAVE_CURRENT_PLAYLIST, {
        id: idPlaylist,
        title: '',
        description: '',
        img: '',
        itemType: 'playlist',
        lastModifiedDate,
        createDate,
        tags,
        isPublish: !!(addToChannel || addToUser),
        wrapperId: playlistUUIDWrap,
        linkPages: [],
        owner: user,
        currentRole: RolesForPlaylist.owner,
        inputs: { category: defaultCategory },
      }),
    );
    yield put({ type: 'IS_DOWNLOAD_USER', payload: false });


    const playlistUUID = idPlaylist || uuidv4();
    yield call(requestCreatePlaylistManager, {
      id: playlistUUIDWrap,
      playlist: {
        id: playlistUUID,
        title: '',
        category: defaultCategory,
        addToChannel,
        addToUser,
      },
      userId: user.id,
    });
    yield put({ type: 'PAGE_IS_DOWNLOAD', payload: { isDownload: false } });

    yield put(actionCreator(ContentActionType.updateCounterS, {}));

    if (addToUser) {
      yield put(actionCreator(ContentActionType.updateCounterContacts,
        { contactsIds: [addToUser], smartFilesCount: 1 }));
    }
    if (addToChannel) {
      yield put(
        actionCreator(ContentActionType.updateCounterSFChannel, {
          channelId: addToChannel,
          counter: 1,
        }),
      );
    }
  } catch (e) {
    yield put(actionShowMessage({
      type: MessageType.ReachedSmartFilesLimitForPlan,
    }));
    goBack();
  }
}

function* editTitlePlaylist(action) {
  try {
    const {
      payload: { id, title, isSocket },
    } = action;
    yield put(actionUpdatePlaylistTitle({ title, id }));
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    if (!isSocket) {
      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: action.type,
            payload: sanitizeToSave(JSON.stringify({ id, title })),
            playlistId: id,
          },
          pushToChannel: id,
        }),
      );
      yield call(requestUpdatePlaylist('name'), {
        id,
        fieldsUpdateObg: {
          title: `"${sanitizeToSave(title)}"`,
        },
      });
      yield call(sendEventUpdate, ({}));
    }
    yield put(
      actionUpdatePlaylistLastModified({
        id,
        lastModifiedDate,
      }),
    );
    const page = yield select(getCurrentPage);
    if (id === page.id) {
      yield put(actionCurrentEditTitle(title));
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* editDescriptionPlaylist(action) {
  try {
    const {
      payload: { id, description, isSocket },
    } = action;
    const lastModifiedDate = Math.floor(Date.now() / 1000);

    yield put(actionEditDescriptionPlaylistR(id, description));

    if (!isSocket) {
      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: action.type,
            payload: sanitizeToSave(
              JSON.stringify({ id, description, isSocket }),
            ),
            playlistId: id,
          },
          pushToChannel: id,
        }),
      );
      const fieldsUpdateObg = {
        description: `"${sanitizeToSave(description)}"`,
      };
      yield call(requestUpdatePlaylist('description'), {
        id,
        fieldsUpdateObg,
      });
      yield call(sendEventUpdate, ({}));
    }
    yield put(
      actionUpdatePlaylistLastModified({
        id,
        lastModifiedDate,
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* toggleIsShowDescriptionPlaylist(action) {
  try {
    const {
      payload: { id, isShowDescription, isSocket },
    } = action;
    if (isSocket) return;
    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: action.type,
          payload: sanitizeToSave(JSON.stringify({ id, isShowDescription })),
          playlistId: id,
        },
        pushToChannel: id,
      }),
    );
    const fieldsUpdateObg = { isShowDescription };
    yield call(requestUpdatePlaylist(), {
      id,
      fieldsUpdateObg,
    });
    yield call(sendEventUpdate, ({}));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* ChangeTextElementBlockRedux(action) {
  try {
    const {
      payload: { isSocket, ...payload },
    } = action;
    if (isSocket) return;
    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: action.type,
          payload: sanitizeToSave(
            JSON.stringify({
              ...payload,
              newState: payload.newState.getCurrentContent
                ? convertToRaw(payload.newState.getCurrentContent())
                : payload.newState,
            }),
          ),
          playlistId: payload.playlistId,
        },
        pushToChannel: payload.playlistId,
      }),
    );
    yield call(sendEventUpdate, ({}));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* editStatePlaylist(action) {
  try {
    const {
      payload: { id, state, field, place, inCollectionId },
    } = action;
    const myChannels = yield select(getMyChannels);
    const miniPlaylists = yield select(getContentData);
    const currentPageState = yield select(currentPage);
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const updateState = {};
    updateState[field] = state;
    yield put(actionUpdateShareState(updateState));
    if (place === 'channels') {
      const tmpField = field === 'title' ? 'name' : field;

      const fieldsUpdateObg = {};
      if (typeof state === 'string') fieldsUpdateObg[field] = `"${sanitizeToSave(state)}"`;
      else fieldsUpdateObg[field] = state;
      yield call(requestUpdateChannels, {
        id: id || currentPageState.id,
        fieldsUpdateObg,
      });
      yield put(
        actionUpdateChannelR({
          ...myChannels[id || currentPageState.id],
          [tmpField]: state,
        }),
      );
      if (!state && tmpField === 'isPrivate') {
        yield put(
          actionShowMessage({
            type: MessageType.ChannelSetPublic,
            title: myChannels[currentPageState.id].name,
          }),
        );
      } else if (state && tmpField === 'isPrivate') {
        yield put(
          actionShowMessage({
            type: MessageType.ChannelSetPrivate,
            title: myChannels[currentPageState.id].name,
          }),
        );
      }
      // todo remove
      // if (!state && tmpField === 'isPrivate') yield fork(requestNotifyChannelPublic, { id: currentPageState.id });
    } else if (place === 'page') {
      const tmpField = field === 'title' ? 'name' : field;
      const components = yield select(getLibraryComponents);
      const collections = yield select(getLibraryCollections);
      const reduxComponent = inCollectionId
        ? collections[inCollectionId].nestedPage[id]
        : components[id];
      const fieldsUpdateObg = {};
      if (typeof state === 'string') fieldsUpdateObg[field] = `"${sanitizeToSave(state)}"`;
      else fieldsUpdateObg[field] = state;
      yield call(requestUpdateLibraryComponent(), {
        place: 'queryUpdateStateLibraryPage',
        id: id || currentPageState.id,
        fieldsUpdateObg,
      });
      yield put(
        actionUpdateLibraryComponentInRedux({
          ...reduxComponent,
          [tmpField]: state,
        }),
      );
      if (!state && tmpField === 'isPrivate') {
        yield put(
          actionShowMessage({
            type: MessageType.ChannelSetPublic,
            title: myChannels[currentPageState.id].name,
          }),
        );
      } else if (state && tmpField === 'isPrivate') {
        yield put(
          actionShowMessage({
            type: MessageType.ChannelSetPrivate,
            title: myChannels[currentPageState.id].name,
          }),
        );
      }
      yield put(
        actionUpdatePlaylistLastModified({
          id: id || currentPageState.id,
          lastModifiedDate,
          isPage: place === 'page',
        }),
      );
    } else {
      const fieldsUpdateObg = {};
      if (typeof state === 'string') fieldsUpdateObg[field] = `"${sanitizeToSave(state)}"`;
      else fieldsUpdateObg[field] = state;
      yield call(requestUpdatePlaylist(), {
        id: id || currentPageState.id,
        fieldsUpdateObg,
      });
      // toggle isPublish for playlist in channel (with extra data)
      if (field === 'isPublish') {
        const channelsToChange = Object.values(
          currentPageState.usedInChannels || {},
        );
        yield all(
          channelsToChange.map((channel) => put(
            actionTogglePlaylistPublishInChannel(
              { id: channel.id },
              { ...miniPlaylists[currentPageState.id], field: state },
            ),
          ),
          ),
        );
      }

      if (!state && field === 'isPublish') yield put(actionShowMessage({ type: MessageType.PlaylistUnpublished }));
      else if (state && field === 'isPublish') yield put(actionShowMessage({ type: MessageType.PlaylistPublished }));
      yield put(
        actionUpdatePlaylistLastModified({
          id: id || currentPageState.id,
          lastModifiedDate,
          isPage: place === 'page',
        }),
      );
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* editImagePlaylist(action) {
  try {
    const {
      payload: { id, imgUrl },
    } = action;
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    yield call(requestUpdatePlaylist(), {
      id,
      fieldsUpdateObg: {
        img: `"${imgUrl}"`,
      },
    });
    yield put(
      actionUpdatePlaylistLastModified({
        id,
        lastModifiedDate,
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* getPlaylist(action) {
  try {
    const user = yield select(getUser);
    const {
      id: playlistId,
      isViewMode,
      channelID,
      isViewAsCoEdit,
    } = action.payload;
    let title = '';
    yield put({ type: 'PAGE_IS_DOWNLOAD', payload: { isDownload: true } });
    const { data: supportDate } = yield requestGetSomePlaylistContent([
      'support',
      playlistId,
    ]);

    const type = channelID !== 'preview' ? 'publish' : 'edit';

    const response = yield getPlaylistResolver({
      id: playlistId,
      type,
      channelID,
      isViewAsCoEdit,
    });

    if (!response) return;
    const { playlist } = response;

    const linkPage = {};
    let shareState = {};
    let inputs = {};
    const additionalInformation = {};
    const complementaryPlaylistsId = {};
    let playlistCategory;
    let author;
    let modifiedDate;
    const versionManager = playlist?.manager
      || playlist?.editManager
      || {};

    const tmpPlaylist = (supportDate.Playlist[0]
        && (supportDate.Playlist[0]?.editManager))
      || {};

    const supportDatePublishPlaylist = (supportDate.Playlist[0] && supportDate.Playlist[0].editManager) || {};
    const isFavorite = tmpPlaylist.favorites?.length;

    const {
      sharedAvailableFrom,
      usersToSharing,
      usersInviteToSharing,
      sharedAvailableTo,
      sharedIsAvailableRange,
      isShareToWeb,
      isNeedAccess,
      isCoEdit,
      accessCode,
    } = tmpPlaylist;// TODO we need supportDatePublishPlaylist?.readByUsers?.length??
    const isFinished = !!supportDatePublishPlaylist?.readByUsers?.length || response.isFinished;

    const users = (versionManager?.users && versionManager?.users[0]) || {};
    const usedInChannel = versionManager?.usedInChannel;
    const playMode = versionManager?.playMode;

    const isPlaylistSeen = !!supportDate?.Playlist[0]?.editManager?.seenByUsers?.length;
    if (playlist) {
      const {
        isAnyCanComment,
        isAnyCanEdit,
        isMakeCollaborate,
        isReused,
        duration,
        isShowDescription,
        category,
        audience,
        availableFrom,
        availableTo,
        isAvailableRange,
        reference,
        lastModifiedDate,
        inChannel,
      } = playlist;
      const isPublished = !!usedInChannel;


      // TODO CHECK
      const hasModification = true;

      shareState = {
        isAnyCanComment,
        isAnyCanEdit,
        isMakeCollaborate,
        isReused,
        link: versionManager.id,
        isShareToWeb,
        isNeedAccess,
        accessCode,
        isCoEdit,
        isAvailableRange,
        sharedAvailableFrom,
        sharedAvailableTo,
        usersToSharing: [
          ...usersToSharing.edges.map((i) => ({
            ...i.node,
            send: i.send ? downloadStatus.success : '',
          })),
          ...usersInviteToSharing.edges.map((i) => ({
            ...i.node,
            send: i.send ? downloadStatus.success : '',
          })),
        ],
        sharedIsAvailableRange,
      };
      inputs = {
        duration,
        category,
        isAnyCanComment,
        isAnyCanEdit,
        audience,
        reference,
        availableFrom,
        availableTo,
        isAvailableRange,
      };
      if (category) playlistCategory = category;

      modifiedDate = lastModifiedDate;

      const downloadLinkPages = playlist.linkPages
        .filter((i) => !(isViewMode && i.type === 'upload'))
        .map((item) => ({
          ...item,
          isRemixLocked: item.isRemixLocked === true,
          duration: !item.duration ? DEFAULT_DURATION : item.duration,
          isApprove: item.mainUserInterface?.userAnswer?.length,
        }));

      title = sanitizeToLoad(playlist.title);
      if (downloadLinkPages.length) {
        linkPage[playlistId] = parseLinkPages(
          downloadLinkPages,
          isViewMode,
          users,
        );
      }
      if (isViewMode) {
        // TODO
      }

      if (users.length) {
        author = `${users[0]?.first_name} ${users[0]?.last_name}`;
      } else {
        author = `${user?.first_name} ${user?.last_name}`;
      }
      const componentsId = downloadLinkPages.reduce((acc, item) => {
        if (item?.libraryComponent[0]?.id) {
          acc[item?.libraryComponent[0]?.id] = true;
        } else {
          acc[item?.libraryComponent?.id] = true;
        }
        return acc;
      }, {});


      yield put(
        actionSaveCurrentPlaylist({
          ...playlist,
          description: sanitizeToLoad(playlist.description),
          isFinished,
          // hashtags,
          isShowDescription,
          hasModification,
          hasBlockedLinkPages: playlist.hasBlockedLinkPages,
          cards: versionManager.card,
          usedLibraryComponents: componentsId,
          isPublish: isPublished,
          wrapperId: versionManager.id,
          isPlaylistSeen,
          tags: [],
          usedInChannels: parseUseInChannel(inChannel),
          isOwnContent: user.id === users?.id,
          viewedCounter: supportDate.viewedCounter || 0,
          playMode,
          owner: {
            ...users,
          },
        }),
      );

      if (isPublished && !isPlaylistSeen) {
        // set publish playlist viewed by user (not new anymore)
        yield put(
          actionCreator(ViewsActions.ViewPlaylistManager, {
            playlistWrapperId: versionManager.id,
          }),
        );
      }
    }

    const blocks = (playlist?.id && Object.values(linkPage[playlist.id] || {})) || [];
    const layers = getLayersForPage(blocks);

    const switchPageObject = {
      id: playlistId,
      playlistId,
      playlistTitle: playlist?.title,
      title,
      parentTitle: title,
      type: TYPE.PLAYLIST,
      additionalInformation,
      shareState,
      lastModifiedDate: modifiedDate,
      isFavorite,
      inputs,
      isFinished,
      layers,
      category: playlistCategory,
      playlistAuthor: author,
      complementaryPlaylists: complementaryPlaylistsId,
      wrapperId: versionManager.id,
      playMode,
      isPlaylistSeen,
      hasBlockedLinkPages: playlist?.hasBlockedLinkPages,
      isOwnContent: user.id === users?.id,
      owner: { ...users },
      linkPages: mapLinkPages(playlist?.linkPages || []),
      // linkPages: parseLinkPages(playlist.linkPages, true, {}) || [],
    };
    yield put(actionSwitchPage(switchPageObject));
    yield put({ type: 'PAGE_IS_DOWNLOAD', payload: { isDownload: false } });
    // yield calculatePlaylistDurationTime({ ...switchPageObject,
    //   description: playlist?.description,
    //   linkPage });
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* getPublishPlaylist(action) {
  try {
    yield showErrorMessage('deprecated', action);
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* getMiniPlaylist(action) {
  try {
    const {
      payload: { id },
    } = action;
    yield put(actionGetMiniPlaylist(id));
    const { data } = yield requestGetSomePlaylistContent(['mini', id]);
    const [playlist] = data.Playlist;

    if (data.Playlist && data.Playlist[0]) {
      const versionManager = playlist?.editManager || {};
      const favorites = versionManager.favorites;
      const usedInChannel = versionManager?.usedInChannel;
      const isPublished = !!usedInChannel;
      const {
        isShowDescription,
        description,
        img,
        createDate,
        lastModifiedDate,
        duration,
        title,
        price,
        category,
        isAnyCanEdit,
      } = data.Playlist[0];

      // TODO CHECK
      const hasModification = true;

      yield put(
        actionSaveMiniPlaylist({
          id,
          isShowDescription,
          description: sanitizeToLoad(description),
          createDate,
          lastModifiedDate,
          duration,
          wrapperId: versionManager.id,
          isPlaylistSeen: !!versionManager.seenByUsers?.length,

          usedInChannel,
          hasModification,
          isPublish: isPublished,
          isFavorite: !!favorites?.length,
          title,
          price,
          position: img?.edges && img?.edges[0]?.position,
          img: img?.edges && img?.edges[0]?.node?.urlFile,
          category,
          isAnyCanEdit,
          // usedInChannels,
        }),
      );
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* downloadMiniPlaylists(action) {
  try {
    const {
      payload: { playlistsArray },
    } = action;
    const miniPlaylists = yield select(getContentData);
    const notDownloadPages = Object.values(playlistsArray || {}).filter(
      (i) => !miniPlaylists[i.id] && !i.movedToTrash,
    );
    if (notDownloadPages.length) {
      yield all(
        notDownloadPages
          .slice(0, 20)
          .map((item) => getMiniPlaylist({ payload: { id: item.id } })),
      );
      yield put(actionLazyLoading(false));
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

export function* deletePlaylist(action) {
  try {
    const idPlaylistToDelete = action.payload.id;
    const isPublish = action.payload.isPublish;
    const { data } = yield requestGetSomePlaylistContent([
      'manager',
      idPlaylistToDelete,
    ]);
    const linkPages = data.Playlist[0].linkPages;
    const linkPagesId = linkPages.map((linkPage) => linkPage.id);
    const wrapperId = (data.Playlist[0].editManager && data.Playlist[0].editManager?.id);
    yield call(
      requestDeletePlaylistPlacement(placementEnum.queryDeletePlaylist),
      {
        playlistId: idPlaylistToDelete,
        linkPageId: linkPagesId,
        wrapperId,
        isPublish,
      },
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* updateMoveToTrashPlaylist(action) {
  try {
    const { id, state, wrapperId, moveCallback } = action.payload;

    const playlists = yield select(getContentData);
    const calcWrapperId = wrapperId || playlists[id]?.wrapperId;
    if (state) {
      yield call(requestPlaylistMoveToTrash(calcWrapperId));
    } else {
      yield call(
        requestUpdatePlaylistManagerPlacement(
          placementEnum.queryTrashStateUpdatePlaylist,
        ),
        {
          id: calcWrapperId,
          state,
        },
      );
    }
    if (state) {
      yield put(
        sendMessage({
          closePlaylist: id,
        }),
      );
    }
    if (moveCallback) moveCallback();
    if (!state && !playlists[id]) {
      yield put(actionGetMiniPlaylistForce(id));
    }
    yield put(actionCreator(ContentActionType.updateCounterS, {}));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* toggleFavorite(action) {
  try {
    const {
      payload: { id, isFavorite, wrapperId },
    } = action;

    const user = yield select(getUser);
    let parentId;
    if (!wrapperId) {
      const { data } = yield requestGetSomePlaylistContent(['parent', id]);
      parentId = data?.Playlist.length
        && (data.Playlist[0]?.editManager?.id);
    } else {
      parentId = wrapperId;
    }

    if (isFavorite) {
      yield call(requestUpdatePlaylistManager, {
        id: parentId,
        connect: {
          favorites: [user.id],
        },
      });
    } else {
      yield call(requestUpdatePlaylistManager, {
        id: parentId,
        disconnect: {
          favorites: [user.id],
        },
      });
    }
    yield put(actionCreator(ContentActionType.updateCounterS, {}));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* addImage(action) {
  try {
    const {
      payload: { position, componentId, img, isSocket, crop },
    } = action;
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const currentPlaylist = yield select(currentPage);
    if (crop) {
      try {
        yield put(actionSwitchPage({ cropUrlIsLoad: true }));
        const { data } = yield call(
          requestCropPlaylistCover(currentPlaylist.id),
          { imageUrl: img, crop, componentId },
        );
        yield put(
          actionSwitchPage({
            cropUrl: data.cropUrl,
            cropUrlIsLoad: false,
          }),
        );

        yield delay(200);
      } catch (err) {
        yield put(actionSwitchPage({ cropUrlIsLoad: false }));

        // eslint-disable-next-line no-console
        console.error(err);
      }
      return;
    }

    if (!isSocket) {
      if (!currentPlaylist.content.oldComponentId) {
        yield call(requestUpdatePlaylist(), {
          id: currentPlaylist.id,
          connect: {
            img: [
              {
                id: currentPlaylist.content.componentId,
                edge: { position: position || 0 },
              },
            ],
          },
        });
      } else if (currentPlaylist.content.oldComponentId !== componentId) {
        yield call(requestUpdatePlaylist(), {
          id: currentPlaylist.id,
          disconnect: { img: [currentPlaylist.content.oldComponentId] },
        });
        yield call(requestUpdatePlaylist(), {
          id: currentPlaylist.id,
          connect: {
            img: [{ id: componentId, edge: { position: position || 0 } }],
          },
        });
      } else if (position !== currentPlaylist.content.oldPosition) {
        yield call(requestUpdatePlaylist(), {
          id: currentPlaylist.id,
          updateEdge: {
            img: [
              {
                id: componentId,
                edge: { position: position || 0 },
              },
            ],
          },
        });
      }

      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: action.type,
            payload: sanitizeToSave(
              JSON.stringify({ position, componentId, img }),
            ),
            playlistId: currentPlaylist.id,
          },
          pushToChannel: currentPlaylist.id,
        }),
      );
      yield call(sendEventUpdate, ({}));
    }
    const newContent = { ...currentPlaylist.content };
    newContent.oldComponentId = componentId;
    newContent.img = img;
    yield put(actionSwitchPage({ content: newContent }));
    yield put(
      actionUpdatePlaylistLastModified({
        id: currentPlaylist.id,
        lastModifiedDate,
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}
function* changeDefaultColor(action) {
  try {
    const {
      payload: { defaultPreviewColor, componentId },
    } = action;
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const currentPlaylist = yield select(currentPage);
    const newContent = { ...currentPlaylist.content };
    newContent.oldComponentId = componentId;
    yield put(actionSwitchPage({ content: newContent }));
    yield call(requestUpdatePlaylist(), {
      id: componentId,
      fieldsUpdateObg: {
        defaultPreviewColor,
      },
    });
    newContent.defaultPreviewColor = defaultPreviewColor;
    yield put(
      actionUpdatePlaylistLastModified({
        id: currentPlaylist.id,
        lastModifiedDate,
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}
function* deleteCoverImage(action) {
  try {
    const { payload } = action;

    const currentPlaylist = yield select(currentPage);
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    if (!payload?.isSocket) {
      yield call(requestUpdatePlaylist(), {
        id: currentPlaylist.id,
        disconnect: { img: [currentPlaylist.content.componentId] },
      });
      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: action.type,
            payload: sanitizeToSave(JSON.stringify({})),
            playlistId: currentPlaylist.id,
          },
          pushToChannel: currentPlaylist.id,
        }),
      );
      yield call(sendEventUpdate, ({}));
    }
    yield put(
      actionUpdatePlaylistLastModified({
        id: currentPlaylist.id,
        lastModifiedDate,
      }),
    );
    yield put(actionRemoveImageRedux());
  } catch (e) {
    yield showErrorMessage(e, { type: 'deleteCoverImage' });
  }
}

function* deleteMakerCoverImage(action) {
  try {
    const { payload } = action;

    const currentPlaylist = yield select(currentPage);
    // const lastModifiedDate = Math.floor(Date.now() / 1000);
    yield put(actionRemoveImageRedux());

    if (!payload?.isSocket) {
      yield call(request(REMOVE_CROP_URL(currentPlaylist.id), METHOD.GET));
    }
  } catch (e) {
    yield showErrorMessage(e, { type: 'deleteCoverImage' });
  }
}

function* duplicatePlaylist(action) {
  try {
    const {
      payload: { playlistId },
    } = action;
    yield put(
      actionShowMessage({
        type: MessageType.DuplicatePlaylist,
        itemName: 'playlist',
        mode: Group.processingWithoutCancellation,
        id: playlistId,
      }),
    );

    axiosAbortarium.generateNew(playlistId);
    yield call(request(DUPLICATE_PLAYLIST(playlistId), METHOD.GET));
    yield put(ActionRequestProceedRemove(playlistId));
    yield put(actionCreator(ContentActionType.updateCounterS, {}));
    const { selectorType } = yield select((state) => state.content);
    if (selectorType === 'drafts') {
      const { activeNavSlider, selectorType, sortType } = yield select(
        (state) => state.content,
      );
      yield put(
        actionCreator(ContentActionType.startUpload, {
          activeNavSlider,
          selectorType,
          sortType,
          isNeedUpdate: true,
        }),
      );
    }
    yield put(
      actionShowMessage({
        type: MessageType.Regular,
        text: i18n.t('duplicateCreatedInDraftsT'),
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* updateTime(action) {
  try {
    const {
      payload: { durationTime },
    } = action;
    const currentPlaylist = yield select(currentPage);
    const user = yield select(getUser);
    if (
      durationTime !== currentPlaylist.inputs.duration
      && user.id === (currentPlaylist.users && currentPlaylist.users[0]?.id)
    ) {
      yield call(requestUpdatePlaylist(), {
        id: currentPlaylist.id,
        fieldsUpdateObg: { duration: `"${durationTime}"` },
      });
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* jumpToPlaylist() {
  try {
    yield delay(100);
    yield put(actionTryPublishPlaylist());
    yield put(actionJumpTo('playlistTitleInput'));
    yield delay(100);
    yield put(actionJumpTo(''));
  } catch (err) {
    yield showErrorMessage(err, { type: 'jumpToPlaylist' });
  }
}

function* refreshUserTags() {
  try {
    const user = yield select((state) => state.user);
    if (!user.id) return;
    const { data } = yield requestGetSomeUserContent(['tag']);
    if (!data?.User[0]) {
      return;
    }
    yield put(
      _actionRefreshUserTags(
        data.User[0].contentTags.map((item) => ({ ...item, type: 'tags' })),
      ),
    );
  } catch (err) {
    yield showErrorMessage(err, { type: 'refreshUserTags' });
  }
}

// playlist management. decide whether to add or remove to/from channel
function* playlistChannelPublishManagement(action) {
  try {
    const { channels, playlist } = action.payload;
    const addPlaylistToChannelArr = [];
    const removePlaylistFromChannelArr = [];

    const { data } = yield requestGetSomePlaylistContent([
      'channel',
      playlist.id,
    ]);

    const arrayFromServer = data.Playlist[0].inChannel.reduce((acc, item) => {
      acc[item.id] = { ...item };
      return acc;
    }, {});

    const arrOfUniq = new Set(
      Object.values({ ...arrayFromServer, ...channels } || {}),
    );

    arrOfUniq.forEach((item) => {
      if (arrayFromServer[item.id] && !channels[item.id]) removePlaylistFromChannelArr.push(item);
      if (!arrayFromServer[item.id] && channels[item.id]) addPlaylistToChannelArr.push(item);
    });

    if (
      !!addPlaylistToChannelArr.length
      || !!removePlaylistFromChannelArr.length
    ) {
      yield call(requestUpdatePlaylist(), {
        id: playlist.id,
        connect: { inChannel: addPlaylistToChannelArr.map((i) => i.id) },
        disconnect: {
          inChannel: removePlaylistFromChannelArr.map((i) => i.id),
        },
      });
      yield all(
        addPlaylistToChannelArr.map((channel) => {
          return put(
            actionAddPlaylistToChannelR(
              channel,
              { ...data.Playlist[0] },
              channels,
            ),
          );
        }),
      );

      yield all(
        removePlaylistFromChannelArr.map((channel) => put(
          actionRemovePlaylistFromChannelR(channel, { ...data.Playlist[0] }),
        ),
        ),
      );
      const lastModifiedDate = Math.floor(Date.now() / 1000);

      yield put(
        actionUpdatePlaylistLastModified({
          id: playlist.id,
          lastModifiedDate,
        }),
      );
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* publishPlaylistIntoHelpChannel(action) {
  try {
    const { playlist } = action.payload;
    const { inBoxColumn } = yield select(getDashboardColumns);

    yield put(
      actionPublishPlaylistS(
        [{ id: 'help' }],
        { ...inBoxColumn, name: 'IN' },
        playlist,
      ),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* changeSubscriberStatus(action) {
  try {
    const { userId, newStatus, channelId } = action.payload;
    if (newStatus === 'Deleted') {
      yield call(requestDeleteChannels, {
        id: channelId,
        disconnect: {
          channelSubscription: [userId],
          channelSubscriptionInvite: [userId],
        },
      });
    } else {
      let response;
      try {
        response = yield call(requestChannelRoleChange, {
          channelId,
          data: { userId, status: newStatus },
        });
      } catch (err) {
        yield put(
          actionShowMessage({
            type: MessageType.RoleNotChanged,
          }),
        );
        return;
      }
      if (response.status === 200) {
        const user = { id: userId, status: newStatus };
        yield put(actionCreator(Channels.UpdateChannelChangeUserR, { user }));
      }
    }
    yield put(actionCreator(ContentActionType.updateCounterS, {}));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* createPageAndAddToBuilder(action) {
  try {
    const { playlistId, history, replaceElement, isFavorite, playlistTitle } = action.payload;
    const user = yield select(getUser);
    const pages = yield select(getPages);
    const linkPages = yield select(getCurrentPageLinkPages);
    yield call(sendEventUpdate, ({}));
    const [lastPage] = Object.values(pages || {}).sort(
      (a, b) => a.position - b.position,
    );
    const [lastLinkPages] = linkPages.sort((a, b) => a.position - b.position);

    const newPageId = v4();
    const newLinkPageId = replaceElement?.id || v4();

    const newPagePosition = calculateIndex(null, lastPage?.position);
    const newLinkPagePosition = replaceElement
      ? replaceElement.position
      : calculateIndex(null, lastLinkPages?.position);

    const createDate = Math.floor(Date.now() / 1000);

    const page = {
      id: newPageId,
      position: newPagePosition,
      type: 'page',
      itemType: 'component',
      lastModifiedDate: createDate,
      createDate,
      isFavorite,
    };
    const linkPage = {
      id: newLinkPageId,
      position: newLinkPagePosition,
    };
    const playlist = {
      id: playlistId,
    };
    if (replaceElement) {
      yield call(requestUpdateLinkPage(), {
        id: linkPage.id,
        playlistId,
        fieldsUpdateObg: { type: '"elementComponent"' },
        create: {
          libraryComponent: [
            {
              id: page.id,
              position: 100000,
              createDate: page.createDate,
              addOwner: user.id,
            },
          ],
        },
      });
    } else {
      const fields = { page, linkPage, playlist, userId: user.id };
      yield call(
        call(request(ADD_PAGE_AND_ADD_TO_BUILDER, METHOD.POST), { fields }),
      );
    }
    yield put(actionAddLibraryComponent(page));
    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: CurrentPage.ReplaceLinkPagePlaylistPageR,
          payload: sanitizeToSave(
            JSON.stringify({
              linkPageId: linkPage.id,
              playlistId,
              linkPageData: {
                ...linkPage,
                contentType: 'page',
                type: 'elementComponent',
                libraryComponent: page,
              },
            }),
          ),
          playlistId,
        },
        pushToChannel: playlistId,
      }),
    );
    yield put(actionCreator(ContentActionType.updateCounterS, {}));

    if (isFavorite) {
      yield put(
        actionToggleComponentFavorite(newPageId, null, isFavorite, false),
      );
    }

    const newStep = { step: {} };
    newStep.step.from = history?.location?.pathname;
    newStep.step.playlistTitle = playlistTitle;
    newStep.departureUrl = history?.location?.pathname;
    yield put(actionCreator(UnifiedHistory.AddPayload, newStep));

    history.push(`/edit_page/${newPageId}`);
    yield put(actionAddToBeScrollTo(newPageId));
    yield put(
      actionCreator(SupportAction.AddBacklightItem, {
        backlightItemId: newLinkPageId,
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}
function* Publish(action) {
  try {
    const {
      payload: {
        addedNew,
        selectedColumn,
        dateState = {},
        playlist: { id: playlistId, inputs, title },
        isSharedOnDrop,
      },
    } = action;

    const validateChannel = Object.values(addedNew).filter(i => i.permissions.canPostContent);

    if (!validateChannel.length) {
      yield put(actionShowMessage({
        type: MessageType.NeutralRegular,
        text: i18n.t('notEnoughRightsToShareT'),
      }));
      yield put(actionToggleRequestSpinner(false));

      return;
    }

    const name = Object.values(addedNew)[0].name;
    const newDateState = {};
    const { requestSpinner } = yield select(getSupport);
    if (Object.values(dateState).length) {
      if (dateState.dateRangeMark === DateRangeMark4SharedPlaylist.OPEN_TO_READ) {
        newDateState.sharedAvailableFrom = 0;
        newDateState.sharedAvailableTo = 0;
      } else {
        const {
          availableFrom,
          availableTo,
        } = dateState;

        newDateState.availableFrom = availableFrom ? Math.floor(
          availableFrom?.getTime() / 1000,
        ) : 0;
        newDateState.availableTo = availableFrom ? Math.floor(
          availableTo?.getTime() / 1000,
        ) : 0;
      }
    }
    yield call(requestPlaylistPublish, { playlistId, addedNew, dateState: newDateState });

    const { id, usedInChannels } = yield select(getCurrentPage);

    const newContent = {
      isPublish: true,
      hasModification: false,
      usedInChannels: { ...usedInChannels, ...addedNew },
    };

    const messageData = {};

    if (id === requestSpinner) {
      yield put(actionSwitchPage(newContent));
    } else {
      messageData.itemName = title;
    }
    yield put(actionEditPublishStateR(playlistId, true, inputs));
    if (isSharedOnDrop) {
      yield put(
        actionShowMessage({
          type: MessageType.sharedToContact,
          sfCount: 1,
          sharedTo: name,
        }),
      );
    } else {
      yield put(
        actionShowMessage({
          type: MessageType.PlaylistPublished,
          ...messageData,
        }),
      );
    }
    yield put(actionCreator(ContentActionType.updateCounterS, {}));

    yield put(actionCreator(ContentActionType.MovePlaylistToShare, { playlistsIds: [playlistId] }));
    yield put(
      actionCreator(DashboardColumns.card.updateCardsForPlaylist, {
        selectedColumn,
        playlistId,
        usedInChannels,
      }),
    );

    yield put(actionToggleRequestSpinner());
    yield put(actionCreator(OutboxAction.UpdateCounterS));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}
function* multiplePublish(action) {
  try {
    const {
      payload: {
        addedNewChannel,
        dateState = {},
        playlistsIds,
        dragToContactParams,
      },
    } = action;
    const passedSmartfiles = dragToContactParams?.notSharedSmartfiles?.length > 0
      ? dragToContactParams.notSharedSmartfiles : playlistsIds;
    const newDateState = {};
    if (Object.values(dateState).length) {
      if (dateState.dateRangeMark === DateRangeMark4SharedPlaylist.OPEN_TO_READ) {
        newDateState.sharedAvailableFrom = 0;
        newDateState.sharedAvailableTo = 0;
      } else {
        const {
          availableFrom,
          availableTo,
        } = dateState;

        newDateState.availableFrom = availableFrom ? Math.floor(
          availableFrom?.getTime() / 1000,
        ) : 0;
        newDateState.availableTo = availableFrom ? Math.floor(
          availableTo?.getTime() / 1000,
        ) : 0;
      }
    }
    yield call(requestManyPlaylistPublish,
      { playlistsIds: passedSmartfiles, addedNewChannel, dateState: newDateState });


    const messageData = { title: 'Many' };


    // yield put(actionEditPublishStateR(playlistId, true, inputs));
    if (dragToContactParams) {
      yield put(
        actionShowMessage({
          type: MessageType.sharedToContact,
          sfCount: playlistsIds.length,
          sharedTo: dragToContactParams.channelName,
        }),
      );
    } else {
      yield put(
        actionShowMessage({
          type: MessageType.PlaylistPublished,
          ...messageData,
        }),
      );
    }
    yield put(actionCreator(ContentActionType.updateCounterS, {}));
    yield put(actionCreator(ContentActionType.MovePlaylistToShare, { playlistsIds: passedSmartfiles }));

    yield put(actionToggleRequestSpinner());
    yield put(actionCreator(OutboxAction.UpdateCounterS));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

// TODO DELETE
function* Discard(action) {
  try {
    const {
      payload: { playlistId, history },
    } = action;
    yield put({ type: 'PAGE_IS_DOWNLOAD', payload: { isDownload: true } });

    const { publishPlaylistId } = yield select(currentPage);
    const {
      data: { newPlaylistId },
    } = yield call(requestPlaylistDiscard, { playlistId, publishPlaylistId });

    yield put(
      sendMessage({
        closePlaylist: playlistId,
      }),
    );

    history.push(`/maker/${newPlaylistId}/edit`);
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* UnPublish(action) {
  try {
    const {
      payload: { playlistId, wrapperId },
    } = action;
    yield call(requestUnPublishPlaylist(wrapperId));
    const { shareState } = yield select(getCurrentPage);

    const contentData = yield select(getLibraryComponent);

    const touchedUsersFromOutside = contentData && contentData[playlistId]
      && Object.keys(contentData[playlistId].sharedToUsers || {}) || [];

    const touchedUsersFromInside = shareState.usersToSharing.map(elem => elem.id) || [];
    const touchedUsers = [...new Set([...touchedUsersFromOutside, ...touchedUsersFromInside])];

    if (touchedUsers?.length) {
      yield put(actionCreator(ContentActionType.updateCounterContacts,
        { contactsIds: touchedUsers, smartFilesCount: -1 }));
    }

    const newContent = {
      isPublish: false,
      usedInChannels: {},
      singleUserShareState: {},
      shareState: { ...shareState, usersToSharing: [], isShareToWeb: false },
    };
    const { id } = yield select(getCurrentPage);
    if (id === playlistId) {
      yield put(actionSwitchPage(newContent));
    }
    yield put(actionEditPublishStateR(playlistId, false));
    yield put(actionToggleRequestSpinner());
    yield put(actionCreator(OutboxAction.UpdateCounterS));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* duplicateAndSwitchPlaylist(action) {
  try {
    const { newId, history, playlistId, itemsSelectedId } = action.payload;
    yield put(actionDuplicatePlaylist(playlistId, newId, itemsSelectedId));
    yield put(actionPageWillDownload());
    yield delay(500);
    history.push(`/maker/${newId}/edit`);
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* addPageToPlaylist(action) {
  try {
    const {
      playlistId,
      currentPageId,
      linkPageId,
      history,
      isNoNotifications,
      isFromCreate,
      dataFromMaker = {},
    } = action.payload;
    const { requestSpinner } = yield select(getSupport);
    const owner = yield select(getUser);
    const { id: userId } = owner;
    let libraryItemId = currentPageId;
    if (requestSpinner !== SPINNER_USAGE.PageCreateNewPlaylist) {
      yield put(actionToggleRequestSpinner(SPINNER_USAGE.AddPageToPlaylist));
    }
    let isNew;
    const {
      data: { Playlist, LinkPage },
    } = yield requestGetSomePlaylistContent([
      'link_page',
      playlistId,
      linkPageId,
    ]);
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const linkPage = LinkPage && LinkPage[0];

    if (!Playlist[0]) {
      isNew = true;
    }
    const position = dataFromMaker?.customPosition ?? calculateIndex(Playlist[0]?.linkPages[0]?.position);
    const isNotOwner = linkPage?.libraryComponent[0]?.users[0].id !== userId;
    const needToDuplicate = linkPage?.libraryComponent[0]?.id && isNotOwner;

    if (needToDuplicate) {
      libraryItemId = v4();
      yield playerDuplicateSharedComponent({
        payload: {
          componentId: linkPage?.libraryComponent[0]?.id,
          type: linkPage?.libraryComponent[0]?.type,
          isNoNotifications: true,
          newId: libraryItemId,
          linkPageId: linkPage.id,
        },
      });
    }

    const newLinkPage = {
      id: v4(),
      type: linkPage?.type || PLAYLIST_ELEMENTS.ElementComponent,
      position,
      duration: DEFAULT_DURATION,
    };
    if (
      !linkPage?.type
      || linkPage?.type === PLAYLIST_ELEMENTS.ElementComponent
    ) {
      newLinkPage.libraryComponent = { id: libraryItemId };
    } else if (linkPage?.type === PLAYLIST_ELEMENTS.ElementText) {
      newLinkPage.textComponent = linkPage?.textComponent[0];
    }
    if (isNew) {
      yield call(requestCreatePlaylistManager, {
        id: uuidv4(),
        playlist: {
          id: playlistId,
          title: '',
          category: 'Art & Design',
          createNested: {
            linkPages: [
              {
                id: newLinkPage.id,
                type: newLinkPage.type,
                position: newLinkPage.position,
                createDate: lastModifiedDate,
                addOwner: userId,
                libraryComponentId: newLinkPage.libraryComponent?.id,
                textComponent: newLinkPage.textComponent,
              },
            ],
          },
        },
        userId,
      });
    } else {
      yield call(requestUpdatePlaylist(''), {
        id: playlistId,
        create: {
          linkPages: [
            {
              id: newLinkPage.id,
              type: newLinkPage.type,
              position: newLinkPage.position,
              createDate: lastModifiedDate,
              addOwner: userId,
              libraryComponentId: newLinkPage.libraryComponent?.id,
              textComponent: newLinkPage.textComponent,
            },
          ],
        },
      });
    }
    if (requestSpinner !== SPINNER_USAGE.PageCreateNewPlaylist) {
      yield put(actionToggleRequestSpinner(null));
    }

    if (dataFromMaker.isNew) {
      const date = Math.round(Date.now() / 1000);
      newLinkPage.owner = owner;
      newLinkPage.createDate = date;
      newLinkPage.libraryComponent = {
        id: newLinkPage.id,
        type: 'embed_component',
        title: 'new_embed_upload',
        isUpload: true,
        linkUrl: dataFromMaker.linkUrl,
        createDate: date,
      };
      yield put(actionCreateLinkPageR(playlistId, newLinkPage));
    }

    if (!isNoNotifications) {
      let stopRedirect = false;
      if (!isFromCreate) {
        yield put(
          actionCreator(SupportAction.ShowContentLoader, { state: true }),
        );
      }

      yield put(
        actionShowMessage({
          type: MessageType.PageAddedToPlaylist,
          callback: () => {
            stopRedirect = true;
          },
          playlistTitle:
            sanitizeToLoad(Playlist[0]?.title) || i18n.t(DEFAULT_TITLE.Playlist),
        }),
      );

      yield delay(NOTIFICATION_DURATIONS.Info);

      if (!isFromCreate) {
        yield put(
          actionCreator(SupportAction.ShowContentLoader, { state: false }),
        );
      }
      const stackStep = { step: {} };
      stackStep.step.id = currentPageId;
      stackStep.step.from = '/library';
      stackStep.departureUrl = history.location.pathname;

      if (!stopRedirect) {
        // ??
        yield put(actionCreator(UnifiedHistory.AddPayload, stackStep));
        history.push(`/maker/${playlistId}/edit`);
      }
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* createPlaylistAndAddPageToIt(action) {
  try {
    const { pageId, history } = action.payload;
    yield put(actionToggleRequestSpinner(SPINNER_USAGE.PageCreateNewPlaylist));
    const { id: userId, isAdmin } = yield select(getUser);
    const { somePayload: payload } = yield select(getHistoryTrace);
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const [defaultCategory] = Object.values(Category);
    const playlistId = v4();
    const channelId = payload?.channelId;
    const newLinkPage = {
      id: v4(),
      type: PLAYLIST_ELEMENTS.ElementComponent,
      libraryComponent: { id: pageId },
      position: DEFAULT_POSITION_STEP,
      duration: DEFAULT_DURATION,
    };
    const { data } = yield call(
      requestCreatePlaylist(placementEnum.queryCreatePlaylistWithPage),
      {
        title: '',
        userId,
        idPlaylist: playlistId,
        lastModifiedDate,
        defaultCategory,
        addToChannel: isAdmin ? 'help' : null,
        pageData: newLinkPage,
      },
    );

    if (data.errors) {
      yield put(actionShowMessage({ type: MessageType.PlaylistCreationError }));
    } else {
      yield put(actionToggleRequestSpinner(null));
      yield put(
        actionCreator(SupportAction.ShowContentLoader, { state: true }),
      );
      let stopRedirect = false;
      yield put(
        actionShowMessage({
          type: MessageType.PageAddedToPlaylist,
          callback: () => {
            stopRedirect = true;
          },
          playlistTitle: i18n.t(DEFAULT_TITLE.Playlist),
        }),
      );

      yield delay(NOTIFICATION_DURATIONS.Info);
      yield put(
        actionCreator(SupportAction.ShowContentLoader, { state: false }),
      );

      if (!stopRedirect) {
        history.push(`/maker/${playlistId}/edit`, {
          ...history.location.state,
          // traceStack: localStack,
          id: channelId || pageId,
        });
      }
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* markViewedS(action) {
  try {
    const { value, playlistId, channelId, wrapperId } = action.payload;
    const { id: userId } = yield select(getUser);
    const connectDisconnectProperty = value ? ['disconnect'] : ['connect'];
    yield call(requestUpdatePlaylistManager, {
      id: wrapperId,
      [connectDisconnectProperty]: { readByUsers: [userId] },
    });
    yield put(
      actionCreator(EditPlaylist.MarkViewedR, {
        isFinished: value,
        playlistId,
        channelId,
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* GetLinkPagesForPlaylist(action) {
  try {
    const { playlistId } = action.payload;
    const linkPage = {};
    yield put(actionToggleRequestSpinner(playlistId));

    const { data } = yield requestGetSomePlaylistContent(['view', playlistId]);

    const [playlist] = data.Playlist;

    if (playlist) {
      const downloadLinkPages = playlist.linkPages;

      if (downloadLinkPages.length) {
        linkPage[playlistId] = parseLinkPages(downloadLinkPages, true, {});
      }
    }
    yield put(actionToggleRequestSpinner(false));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* JumpToWithConfirmation(action) {
  try {
    const { anchorId } = action.payload;
    yield put(actionJumpTo(anchorId));
    yield take(SupportAction.ReadyToScroll);
    yield put(
      actionCreator(SupportAction.SetComponentIsReady, { value: true }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* ClearReadyConfirmation() {
  try {
    yield put(
      actionCreator(SupportAction.SetComponentIsReady, { value: false }),
    );
    yield put(actionJumpTo());
  } catch (err) {
    yield showErrorMessage(err, { type: 'ClearReadyConfirmation' });
  }
}

function* toggleHide(action) {
  try {
    const {
      payload: { id, isHide },
    } = action;
    const user = yield select(getUser);

    if (isHide) {
      yield call(requestUpdatePlaylistManager, {
        id,
        connect: { hideByUsers: [user.id] },
      });
    } else {
      yield call(requestUpdatePlaylistManager, {
        id,
        disconnect: { hideByUsers: [user.id] },
      });
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* rearrangeHelpPlaylist(action) {
  try {
    const { leftItem, rightItem } = action.payload;
    const { idDragPage } = yield select(currentPage);
    const { playlists } = yield select(getHelp);

    const playlistPosition = calculateIndex(
      playlists[leftItem]?.playlistPosition,
      playlists[rightItem]?.playlistPosition,
    );

    yield put(
      actionCreator(HelpActions.RearrangeHelpPlaylistR, {
        id: idDragPage,
        newPlaylistPosition: playlistPosition,
      }),
    );

    const updatedPlaylistManager = {
      id: playlists[idDragPage]?.playlistManagerId,
      position: playlistPosition,
    };

    yield call(requestUpdatePlaylist(), {
      id: updatedPlaylistManager.id,
      fieldsUpdateObg: { position: updatedPlaylistManager.position },
    });
    yield put(actionDragPage(null, null));

    yield put(actionSetBlinkId([idDragPage]));
    yield delay(3000);
    yield put(actionCleanBlinkId());
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* editShareStatePlaylist(action) {
  try {
    const {
      payload: {
        state,
        field,
        availableFrom,
        availableTo,
        dateRangeMark,
      },
    } = action;
    const { id } = yield select(getCurrentPage);
    const sharedAvailableFrom = availableFrom ? Math.floor(
      availableFrom?.getTime() / 1000,
    ) : 0;
    const sharedAvailableTo = availableFrom ? Math.floor(
      availableTo?.getTime() / 1000,
    ) : 0;
    const newShareState = {
      sharedAvailableFrom,
      sharedAvailableTo,
      dateRangeMark,
    };

    if (field === 'isCoEdit' && !state) {
      yield put(
        sendMessage({
          closePlaylist: id,
        }),
      );
    }
    if (
      field === 'isCoEdit'
      || field === 'isShareToWeb'
      || field === 'isNeedAccess'
      || field === 'accessCode'
    ) {
      newShareState[field] = field === 'accessCode' ? `"${state}"` : state;
    }
    yield put(actionUpdateShareState(newShareState));

    yield delay(200);
    yield put(actionSetGlobalShareStateToSingleUserState(newShareState));
    yield put(actionCreator(OutboxAction.UpdateCounterS));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* updateShareToWebStatePlaylist(action) {
  try {
    const {
      payload: {
        updateTime,
        isShareToWeb,
        accessCode,
        isNeedAccess,
      },
    } = action;
    const { wrapperId } = yield select(getCurrentPage);
    let newShareState = { accessCode: `"${accessCode}"`, isNeedAccess };

    if (updateTime) {
      if (updateTime.dateRangeMark === DateRangeMark4SharedPlaylist.OPEN_TO_READ) {
        newShareState.sharedAvailableFrom = 0;
        newShareState.sharedAvailableTo = 0;
        newShareState.dateRangeMark = DateRangeMark4SharedPlaylist.OPEN_TO_READ;
        newShareState.isShareToWeb = true;
      } else {
        const {
          availableFrom,
          availableTo,
        } = updateTime;

        newShareState.sharedAvailableFrom = availableFrom ? Math.floor(
          availableFrom?.getTime() / 1000,
        ) : 0;
        newShareState.sharedAvailableTo = availableFrom ? Math.floor(
          availableTo?.getTime() / 1000,
        ) : 0;
        newShareState.isShareToWeb = true;
      }
    } else {
      newShareState = { isShareToWeb };
    }

    yield put(actionUpdateShareState(newShareState));

    yield call(requestUpdatePlaylistManager, {
      id: wrapperId,
      fieldsUpdateObg: newShareState,
    });
    yield put(actionToggleRequestSpinner());
    yield delay(200);
    yield put(actionSetGlobalShareStateToSingleUserState(newShareState));
    yield put(actionCreator(OutboxAction.UpdateCounterS));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* updatePublishStatePlaylistSR(action) {
  try {
    const {
      payload: {
        updateTime,
        channelId,
      },
    } = action;
    const { wrapperId } = yield select(getCurrentPage);
    const newState = { };

    if (updateTime.dateRangeMark === DateRangeMark4SharedPlaylist.OPEN_TO_READ) {
      newState.availableFrom = 0;
      newState.availableTo = 0;
    } else {
      const {
        availableFrom,
        availableTo,
      } = updateTime;

      newState.availableFrom = availableFrom ? Math.floor(
        availableFrom?.getTime() / 1000,
      ) : 0;
      newState.availableTo = availableTo ? Math.floor(
        availableTo?.getTime() / 1000,
      ) : 0;
    }

    yield call(requestUpdatePlaylistManagerInChannel, {
      id: wrapperId,
      updateEdge: {
        inChannel: [
          {
            id: channelId,
            edge: { ...newState },
          },
        ],
      },

    });
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* updateManyShareToWebStatePlaylist(action) {
  try {
    const {
      payload: {
        updateTime,
        isShareToWeb,
        playlistManagersIds,
      },
    } = action;

    const newShareState = { };
    if (updateTime) {
      if (updateTime.dateRangeMark === DateRangeMark4SharedPlaylist.OPEN_TO_READ) {
        newShareState.sharedAvailableFrom = 0;
        newShareState.sharedAvailableTo = 0;
        newShareState.dateRangeMark = DateRangeMark4SharedPlaylist.OPEN_TO_READ;
      } else {
        const {
          availableFrom,
          availableTo,
        } = updateTime;

        newShareState.sharedAvailableFrom = availableFrom ? Math.floor(
          availableFrom?.getTime() / 1000,
        ) : 0;
        newShareState.sharedAvailableTo = availableFrom ? Math.floor(
          availableTo?.getTime() / 1000,
        ) : 0;
      }
    } else {
      newShareState.newShareState = isShareToWeb;
    }

    yield put(actionUpdateShareState(newShareState));

    const response = yield call(requestShareToWebPlaylistMany, {
      playlistManagersIds,
      update: newShareState,
    });
    // yield delay(200);
    // yield put(actionSetGlobalShareStateToSingleUserState(newShareState));
    yield put(actionCreator(OutboxAction.UpdateCounterS));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* updateShareToUserPlaylist(action) {
  try {
    const {
      payload: {
        users,
        update,
        wrapperIdFromOptions,
        idFromOptions,
        isSharedOnDrop,
      },
    } = action;
    const { wrapperId } = yield select(getCurrentPage);
    const contacts = yield select(getSidebarContacts);
    const usersEmail = users.map(i => i.email).filter(i => i);
    const newShareState = {};
    if (update.dateRangeMark === DateRangeMark4SharedPlaylist.OPEN_TO_READ) {
      newShareState.sharedAvailableFrom = 0;
      newShareState.sharedAvailableTo = 0;
    } else {
      const {
        availableFrom,
        availableTo,
      } = update;

      newShareState.sharedAvailableFrom = availableFrom ? Math.floor(
        availableFrom?.getTime() / 1000,
      ) : 0;
      newShareState.sharedAvailableTo = availableFrom ? Math.floor(
        availableTo?.getTime() / 1000,
      ) : 0;
    }
    newShareState.isCoEdit = update.isCoEdit;
    newShareState.dateRangeMark = update.dateRangeMark;
    yield call(requestSendEmailToSharingPlaylist, {
      playlistManagerId: wrapperId || wrapperIdFromOptions,
      emailsToUpdate: usersEmail,
      update: newShareState,
    });
    if (usersEmail.length) {
      if (isSharedOnDrop) {
        yield put(
          actionShowMessage({
            type: MessageType.sharedToContact,
            sfCount: 1,
            sharedTo: users[0].username ?? users[0].first_name + users[0].last_name,
          }),
        );
      } else {
        yield put(
          actionShowMessage({
            type: MessageType.sharedEmailSent,
          }),
        );
      }
      yield put(actionCreator(CurrentPage.setManyUserShareState, { users }));
    }

    if (update.isCoEdit) {
      yield put(actionSetAllUserShareState(
        users.reduce((acc, value) => {
          value.isCoEdit = true;
          acc[value.email.toLowerCase()] = value;
          return acc;
        }, {}),
      ),
      );
    }
    yield put(actionToggleRequestSpinner());

    yield delay(200);
    yield put(actionCreator(ContentActionType.updateCounterS, {
      activeNavSlider: 'smartfiles/shared',
      affectedCounters: 'smartfiles/drafts',
    }));
    if (idFromOptions) {
      yield put(actionCreator(ContentActionType.MovePlaylistToShare, { playlistsIds: [idFromOptions] }));
      yield put(actionCreator(ContentActionType.updateCounterContacts,
        { contactsIds: users.map(i => i.id), smartFilesCount: 1 }));
    } else {
      const emailObj = users.reduce((acc, cur) => {
        return { ...acc, [cur.email]: true };
      }, {});
      const existingContacts = Object.keys(contacts).filter(contact => emailObj[contacts[contact].email]);

      if (existingContacts.length) {
        yield put(actionCreator(ContentActionType.updateCounterContacts,
          { contactsIds: existingContacts, smartFilesCount: 1 }));
      }
    }
    yield put(actionCreator(OutboxAction.UpdateCounterS));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* editShareStatePlaylistSingleUser(action) {
  try {
    const {
      payload: {
        state,
        field,
        availableFrom,
        availableTo,
        dateRangeMark,
        email,
        coEditorIndex,
      },
    } = action;
    const { wrapperId } = yield select(getCurrentPage);
    let newShareState = {};
    if (field) {
      if (
        field === 'isCoEdit'
        || field === 'isShareToWeb'
        || field === 'isNeedAccess'
        || field === 'accessCode'
      ) {
        newShareState[field] = field === 'accessCode' ? `"${state}"` : state;
        if (field === 'isCoEdit') {
          newShareState.coEditorIndex = newShareState.isCoEdit ? coEditorIndex : null;
        }
      }
    } else {
      const sharedAvailableFrom = availableFrom ? Math.floor(
        availableFrom?.getTime() / 1000,
      ) : 0;
      const sharedAvailableTo = availableFrom ? Math.floor(
        availableTo?.getTime() / 1000,
      ) : 0;
      newShareState = {
        sharedAvailableFrom,
        sharedAvailableTo,
        dateRangeMark,
      };
    }

    yield put(actionSetSingleUserShareState({ ...newShareState, email }));
    // TODO SOCKET CLOSE PLAYLIST FOR CERTAIN USER
    // if (field === 'isCoEdit' && !state) {
    //   yield put(
    //     sendMessage({
    //       closePlaylist: id,
    //     }),
    //   );
    // }
    yield call(requestUpdateSingleUserShareOptions(wrapperId, email), newShareState);
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* getUsersToSharingPl(action) {
  try {
    const { wrapperId, id } = yield select(getCurrentPage);
    const { payload: { type } } = action;

    const itemId = type === 'page' ? id : wrapperId;
    if (!itemId) return;
    const { data } = yield call(requestGetUserShareOptions(itemId, type));
    yield put(actionSetAllUserShareState(data));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* getChannelsToPublishPl(action) {
  try {
    const { wrapperId } = yield select(getCurrentPage);

    const { data } = yield call(requestGetPublishOptions(wrapperId));
    const inChannels = data.playlistManagers[0]?.inChannelConnection;
    yield put(actionCreator(EditPlaylist.UpdateChannel, { usedInChannels: parseUseInChannel(inChannels) }));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* AddUserToSharing(action) {
  try {
    const {
      payload: { user },
    } = action;
    const owner = yield select(getUser);

    const { shareState } = yield select(getCurrentPage);
    const updateAvailableTo = shareState.sharedAvailableTo || shareState.availableTo;
    const updateAvailableFrom = shareState.sharedAvailableFrom || shareState.availableFrom;

    const {
      data: { UserExist, UserInvite, ContactUser },
    } = yield requestGetSomeUserContent(['email', user.email]);
    const newUuid = uuidv4();

    const isExistInContact = !!ContactUser?.length || user.email === owner.email;
    if (UserExist[0]) {
      // const fieldsUpdateObg = { isShareToWeb: false };

      // if (updateAvailableTo) fieldsUpdateObg.sharedAvailableTo = updateAvailableTo;
      // if (updateAvailableFrom) fieldsUpdateObg.sharedAvailableFrom = updateAvailableFrom;
      // yield call(requestUpdatePlaylistManager, {
      //   id: wrapperId,
      //   connect: { usersToSharing: [UserExist[0].id] },
      //   fieldsUpdateObg,
      // });
    } else if (UserInvite[0]) {
      // const fieldsUpdateObg = { isShareToWeb: false };
      //
      // if (updateAvailableTo) fieldsUpdateObg.sharedAvailableTo = updateAvailableTo;
      // if (updateAvailableFrom) fieldsUpdateObg.sharedAvailableFrom = updateAvailableFrom;
      // yield call(requestUpdatePlaylistManager, {
      //   id: wrapperId,
      //   connect: { usersInviteToSharing: [UserInvite[0].id] },
      //   fieldsUpdateObg,
      // });
    } else {
      // const newUuid = uuidv4();
      // const fieldsUpdateObg = { isShareToWeb: false };
      //
      // if (updateAvailableTo) fieldsUpdateObg.sharedAvailableTo = updateAvailableTo;
      // if (updateAvailableFrom) fieldsUpdateObg.sharedAvailableFrom = updateAvailableFrom;
      // yield call(requestUpdatePlaylistManager, {
      //   id: wrapperId,
      //   create: { usersInviteToSharing: [{ id: newUuid, email: user.email }] },
      //   fieldsUpdateObg,
      // });
    }
    if (!isExistInContact) {
      const creatingContactData = {
        id: newUuid,
        email: user.email,
        createDate: Math.floor(Date.now() / 1000),
        userId: owner.id,
        userForContact: {
          id: UserExist[0]?.id || newUuid,
          userType: UserExist[0] ? '' : 'InviteUser',
        },
      };
      yield requestCreateContactContact(queryCreateContact(creatingContactData));
      yield put(
        actionCreator(Contacts.AddSingleToContactMap, { creatingContactData }),
      );
    }
    // if (updateAvailableTo) {
    //   const newShareState = { ...shareState };
    //   newShareState.usersToSharing = newShareState.usersToSharing.map(
    //     (userToSharing) => {
    //       if (
    //         !!UserExist.length
    //         && userToSharing.email === UserExist[0].email
    //       ) {
    //         userToSharing = { ...UserExist[0] };
    //       }
    //       if (
    //         !UserExist.length
    //         && !!ContactUser.length
    //         && userToSharing.email === ContactUser[0].email
    //       ) {
    //         userToSharing = { ...ContactUser[0], id: userToSharing.id };
    //       }
    //       return userToSharing;
    //     },
    //   );
    //   yield put(
    //     actionUpdateShareState({
    //       ...newShareState,
    //       sharedAvailableTo: updateAvailableTo,
    //     }),
    //   );
    // }

    // TODO here must be handling of case UserToBeInvited
    const userForSingleState = {
      email: user.email,
      id: UserExist[0]?.id,
      sharedAvailableTo: updateAvailableTo,
      sharedAvailableFrom: updateAvailableFrom,
      dateRangeMark: shareState.dateRangeMark,
      globalStateApplied: true,
    };

    yield put(actionSetSingleUserShareState(userForSingleState));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* RemoveUserToSharing(action) {
  try {
    const {
      payload: { user },
    } = action;
    const {
      data: { UserExist, UserInvite },
    } = yield requestGetSomeUserContent(['email', user.email]);
    const { wrapperId, id } = yield select(getCurrentPage);

    const disconnect = {};

    if (UserInvite[0]) disconnect.usersInviteToSharing = [UserInvite[0].id];
    if (UserExist[0]) disconnect.usersToSharing = [UserExist[0].id];
    yield call(requestUpdatePlaylistManager, {
      id: wrapperId,
      disconnect,
    });
    // TODO socket
    yield put(
      sendMessage({
        closePlaylist: id,
        closeForUser: UserExist[0]?.id,
      }),
    );
    yield put(actionCreator(OutboxAction.UpdateCounterS));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* RemoveChannel(action) {
  try {
    const {
      payload: { channelId },
    } = action;
    const { wrapperId } = yield select(getCurrentPage);
    const disconnect = {};
    disconnect.inChannel = [channelId];
    yield call(requestUpdatePlaylistManagerInChannel, { id: wrapperId, disconnect });
    yield put(actionCreator(OutboxAction.UpdateCounterS));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* SendEmailToSharing(action) {
  try {
    const {
      payload: { emailsAndUsersToInvite },
    } = action;
    const { wrapperId, id } = yield select(getCurrentPage);

    const emailsToInvite = emailsAndUsersToInvite
      .filter(
        (i) => i.send !== downloadStatus.pending
          && i.send !== downloadStatus.success,
      )
      .map((i) => i.email);

    if (!emailsToInvite.length) return;
    yield put(
      actionCreator(EditPlaylist.SendingOnEmail, {
        emailsToInvite,
        playlistId: id,
        sendStatus: downloadStatus.pending,
      }),
    );

    try {
      yield call(requestSendEmailToSharingPlaylist, {
        playlistManagerId: wrapperId,
        emailsToInvite,
      });
      yield put(
        actionCreator(EditPlaylist.SendingOnEmail, {
          emailsToInvite,
          playlistId: id,
          sendStatus: downloadStatus.success,
        }),
      );
      yield put(
        actionCreator(ContentActionType.incrementCounters, {
          'smartfiles/sharedByMe': 1,
        }),
      );
      yield put(
        actionShowMessage({
          type: MessageType.InviteUserToSharedPage,
        }),
      );
      yield put(actionCreator(OutboxAction.UpdateCounterS));
    } catch (err) {
      yield showErrorMessage(err, action);
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* GetSharedPlaylist(action) {
  try {
    yield put({ type: 'PAGE_IS_DOWNLOAD', payload: { isDownload: true } });
    const user = yield select(getUser);
    const {
      payload: { id, accessCode, history, isViewAsCoEditViewMode, isShowByWeb },
    } = action;
    const isViewMode = true;
    const token = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
    let data;
    let isFinished = false;
    let loadSharedAvailableTo;
    let sharedAvailableTo;
    let sharedAvailableFrom;
    try {
      const response = yield call(axios, {
        method: 'get',
        url: `${APIGetPlaylistSharing}/${id}`,
        headers: {
          accessCode: accessCode ? `${accessCode}` : '',
          token: token ? `${token}` : '',
          isbyweb: isShowByWeb ? `${isShowByWeb}` : '',
        },
      });
      if (isShowByWeb && response.data.role) {
        if (isRoleInPlaylist.isOwner[response.data.role]) {
          history.push(`/maker/${response.data.playlistId}/edit`,
          );
          return;
        }
        if (isRoleInPlaylist.isCoEdit[response.data.role]) {
          history.push(`/maker/${id}/shared`,
          );
          return;
        }
      }
      if (
        response.data.isCoEdit
        && !response.data.isShareToWeb
        && !isViewAsCoEditViewMode
      ) {
        const statePayload = { ...history.location.state };
        history.replace(
          `/shared_playlist_co_edit/${response.data.playlistId}`,
          statePayload,
        );
        return;
      }
      sharedAvailableTo = response?.data?.sharedAvailableTo;
      sharedAvailableFrom = response?.data?.sharedAvailableFrom;
      data = response.data.data;
      isFinished = response.data.isFinished || false;
      yield setServiceWorkerSharedJwt(response.data.sharedJwt);
      loadSharedAvailableTo = response.data.sharedAvailableTo;
    } catch (e) {
      if (e.response?.status === 403) {
        if (e.response?.data?.message === 'need accessCode') {
          yield put(actionSwitchPage({ shareState: { needAccessCode: true } }));
          yield put({
            type: 'PAGE_IS_DOWNLOAD',
            payload: { isDownload: false },
          });
          return;
        }
        if (e.response?.data?.message === 'wrong accessCode') {
          yield put(
            actionSwitchPage({
              shareState: { needAccessCode: true, wrongAccessCode: true },
            }),
          );
          yield put({
            type: 'PAGE_IS_DOWNLOAD',
            payload: { isDownload: false },
          });
          return;
        }
        if (e.response?.data?.message === 'need login') {
          yield put(actionSwitchPage({ shareState: { needLogin: true } }));
          yield put({
            type: 'PAGE_IS_DOWNLOAD',
            payload: { isDownload: false },
          });
          return;
        }
        if (e.response?.data?.message === 'wrong user') {
          yield put(actionSwitchPage({ shareState: { wrongUser: true } }));
          yield put({
            type: 'PAGE_IS_DOWNLOAD',
            payload: { isDownload: false },
          });
          return;
        }

        if (e.response?.data?.message === 'wrong time') {
          const {
            sharedIsAvailableRange,
            readId,
          } = e.response?.data;
          const shareState = {
            availableTo: sharedAvailableTo,
            availableFrom: sharedAvailableFrom,
            isAvailableRange: sharedIsAvailableRange,
          };
          yield put(
            actionSwitchPage({
              id,
              shareState,
              notAvailable: true,
            }),
          );
          yield put({
            type: 'PAGE_IS_DOWNLOAD',
            payload: { isDownload: false },
          });
          yield put(
            actionCreator(SupportAction.ReadOneUnseenPlaylist, { id: readId }),
          );

          return;
        }
      }
      return;
    }
    const [playlist] = data.Playlist;
    const linkPage = {};
    let title = '';
    let shareState = {};
    let inputs = {};
    const additionalInformation = {};
    const complementaryPlaylistsId = {};
    let playlistCategory;
    let author;
    let modifiedDate;
    const singleUserShareState = {};
    const versionManager = playlist?.editManager || {};

    const users = (versionManager?.users && versionManager?.users[0]) || {};
    const usedInChannel = versionManager?.usedInChannel;

    if (playlist) {
      const {
        isAnyCanComment,
        isAnyCanEdit,
        isMakeCollaborate,
        isReused,
        duration,
        isShowDescription,
        category,
        audience,
        availableFrom,
        availableTo,
        isAvailableRange,
        reference,
        lastModifiedDate,
        inChannel,
      } = playlist;
      const isPublished = !!usedInChannel;
      // TODO check
      const hasModification = true;

      shareState = {
        isAnyCanComment,
        isAnyCanEdit,
        isMakeCollaborate,
        isReused,
        accessCode,
        isAvailableRange,
        sharedAvailableTo: loadSharedAvailableTo,
      };

      inputs = {
        duration,
        category,
        isAnyCanComment,
        isAnyCanEdit,
        audience,
        reference,
        availableFrom,
        availableTo,
        isAvailableRange,
      };
      if (category) playlistCategory = category;

      const usersInviteToSharingNode = versionManager.usersInviteToSharing.edges?.map(i => ({
        ...i.node, isCoEdit: i.isCoEdit,
      })) || versionManager.usersInviteToSharing;

      const usersToSharingNode = versionManager.usersToSharing.edges?.map(i => ({
        ...i.node, isCoEdit: i.isCoEdit,
      })) || versionManager.usersToSharing;

      usersInviteToSharingNode?.forEach(i => singleUserShareState[i.email] = i);
      usersToSharingNode?.forEach(i => singleUserShareState[i.email] = i);

      modifiedDate = lastModifiedDate;
      const downloadLinkPages = playlist.linkPages;

      title = sanitizeToLoad(playlist.title);
      if (downloadLinkPages.length) {
        linkPage[playlist.id] = parseLinkPages(
          downloadLinkPages,
          isViewMode,
          users,
        );
      }

      if (users.length) {
        author = `${users[0]?.first_name} ${users[0]?.last_name}`;
      } else {
        author = `${user?.first_name} ${user?.last_name}`;
      }
      const hashtags = [];
      // const tags = playlist.contentTags.map((item) => ({
      //   ...item,
      //   type: 'tags',
      // }));
      const componentsId = downloadLinkPages.reduce((acc, item) => {
        if (item?.libraryComponent[0]?.id) {
          acc[item?.libraryComponent[0]?.id] = true;
        } else {
          acc[item?.libraryComponent?.id] = true;
        }
        return acc;
      }, {});
      yield put(
        actionSaveCurrentPlaylist({
          ...playlist,
          isFinished,
          hashtags,
          isShowDescription,
          hasModification,
          cards: versionManager.card,
          usedLibraryComponents: componentsId,
          isPublish: isPublished,
          usedInChannels: parseUseInChannel(inChannel),
          owner: { ...users },
          sharedAvailableTo,
          sharedAvailableFrom,
        }),
      );
    }

    const switchPageObject = {
      id: playlist.id,
      playlistId: '',
      socketId: playlist.id,
      playlistTitle: playlist?.title,
      title,
      parentTitle: title,
      type: TYPE.PLAYLIST,
      additionalInformation,
      shareState,
      lastModifiedDate: modifiedDate,
      isFavorite: false,
      inputs,
      isFinished,
      category: playlistCategory,
      playlistAuthor: author,
      cropUrl: playlist.cropUrl,
      linkPages: mapLinkPages(playlist.linkPages).sort((a, b) => a.position - b.position),
      currentRole: 'viewer',
      complementaryPlaylists: complementaryPlaylistsId,
      wrapperId: versionManager.id,
      owner: { ...users },
      sharedAvailableTo,
      sharedAvailableFrom,
      singleUserShareState,
    };
    yield put(actionSwitchPage(switchPageObject));
    yield put({ type: 'PAGE_IS_DOWNLOAD', payload: { isDownload: false } });
    yield put(
      actionCreator(SupportAction.ReadOneUnseenPlaylist, { id: playlist.id }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* GetSharedManyPlaylist(action) {
  try {
    yield put(actionToggleRequestSpinner(true));
    const token = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');

    const { data } = yield call(axios, {
      method: 'get',
      url: APIGetPlaylistSharing,
      headers: {
        token: token ? `${token}` : '',
      },
    });
    const sharedStatePlaylist = {};
    sharedStatePlaylist.sharedPlaylists = {};
    data.forEach((i) => {
      sharedStatePlaylist.sharedPlaylists[i.id] = {
        ...i.editPlaylist,
        ...i,
        position: i.editPlaylist.img?.edges[0]?.position,
        img: i.editPlaylist.img?.edges[0]?.node?.urlFile,
        availableFrom: i.sharedAvailableFrom,
        availableTo: i.sharedAvailableTo,
        isShared: true,
        owner: {
          ...i.users[0],
          name: `${i.users[0].first_name} ${i.users[0].last_name}`,
        },
      };
    });
    sharedStatePlaylist.counter = Object.keys(
      sharedStatePlaylist.sharedPlaylists,
    ).length;
    yield put(
      actionCreator(EditPlaylist.UpdateStateSharedPlaylistsR, {
        sharedStatePlaylist,
      }),
    );
    yield put(actionToggleRequestSpinner(false));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* ShareMultiplePlaylists(action) {
  try {
    const {
      payload: {
        users,
        update,
        dragToContactParams,
      },
    } = action;

    const newShareState = {};
    if (update.dateRangeMark === DateRangeMark4SharedPlaylist.OPEN_TO_READ) {
      newShareState.sharedAvailableFrom = 0;
      newShareState.sharedAvailableTo = 0;
    } else {
      const {
        availableFrom,
        availableTo,
      } = update;

      newShareState.sharedAvailableFrom = availableFrom ? Math.floor(
        availableFrom?.getTime() / 1000,
      ) : 0;
      newShareState.sharedAvailableTo = availableFrom ? Math.floor(
        availableTo?.getTime() / 1000,
      ) : 0;
    }
    newShareState.isCoEdit = update.isCoEdit;
    newShareState.dateRangeMark = update.dateRangeMark;

    const usersEmail = users.map(i => i.email).filter(i => i);

    const contentData = yield select(getContentData);
    const playlistManagersIds = [];
    const selectedPages = yield select(getSelectedPage);
    const passedSmartfiles = dragToContactParams?.notSharedSmartfiles?.length > 0
      ? dragToContactParams.notSharedSmartfiles : Object.keys(selectedPages);
    passedSmartfiles.forEach((key) => {
      Object.values(contentData).forEach((value) => {
        if (value.id === key) {
          playlistManagersIds.push(value.wrapperId);
        }
      });
    });
    try {
      const response = yield call(requestSendEmailToSharingPlaylistMany, {
        playlistManagersIds,
        emailsToInvite: usersEmail,
        update: newShareState,
      });

      const { message } = response.data;
      if (dragToContactParams) {
        yield put(
          actionShowMessage({
            type: MessageType.sharedToContact,
            sfCount: Object.keys(selectedPages).length,
            sharedTo: users[0].username ?? users[0].first_name + users[0].last_name,
          }),
        );
      } else {
        yield put(
          actionShowMessage({
            type: MessageType.MultipleShareSuccess,
            itemType: 'SmartFiles',
            message,
            isSingle: Object.keys(selectedPages).length === 1,
          }),
        );
      }
      yield put(
        actionCreator(ContentActionType.ShareMultipleR, {
          ids: passedSmartfiles,
          shareState: {
            availableTo: newShareState.sharedAvailableTo,
            availableFrom: newShareState.sharedAvailableFrom,
          },
        }),
      );
      yield put(actionCreator(ContentActionType.updateCounterS, {
        activeNavSlider: 'smartfiles/shared',
        affectedCounters: 'smartfiles/drafts',
      }));
      yield put(actionCreator(ContentActionType.MovePlaylistToShare, { playlistsIds: passedSmartfiles }));
      yield put(actionCreator(OutboxAction.UpdateCounterS));
      yield put(actionCreator(ContentActionType.updateCounterContacts,
        { contactsIds: [users[0].id], smartFilesCount: passedSmartfiles.length }));
    } catch (err) {
      console.log(err);
      const status = err.response?.status;
      const { message } = err.response.data;

      if (status === 500) {
        yield put(
          actionShowMessage({
            type: MessageType.MultipleShareError,
            itemType: 'SmartFiles',
            message,
          }),
        );
      } else {
        yield put(
          actionShowMessage({
            type: MessageType.MultipleShareWarning,
            itemType: 'SmartFiles',
            message,
          }),
        );
      }
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* UpdatePlaylistField(action) {
  try {
    const { id, value, field } = action.payload;
    const update = {};
    if (field === 'urlFile' || field === 'type') update[field] = `"${value}"`;
    else if (
      field === 'title'
      || field === 'credits'
      || field === 'description'
    ) {
      update[field] = `"${sanitizeToSave(value)}"`;
    } else update[field] = value;

    yield call(requestUpdatePlaylist(), {
      id,
      fieldsUpdateObg: update,
    });
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* CreatePlaylistWithSelectedLibItems(action) {
  const { history, libComponentsIds, playlistId } = action.payload;
  yield put(actionCloseModal());
  const { requestSpinner } = yield select(getSupport);

  try {
    if (requestSpinner !== SPINNER_USAGE.PageCreateNewPlaylist) {
      yield put(actionToggleRequestSpinner(SPINNER_USAGE.AddPageToPlaylist));
    }

    const { data } = yield call(requestCreatePlaylistWithSelectedLibItems(), {
      libComponentsIds,
      playlistId,
    });
    const newPlaylistID = data?.id;
    const title = sanitizeToLoad(data?.title);
    if (requestSpinner !== SPINNER_USAGE.PageCreateNewPlaylist) {
      yield put(actionToggleRequestSpinner(null));
    }

    let stopRedirect = false;
    yield put(actionCreator(SupportAction.ShowContentLoader, { state: true }));
    yield put(
      actionShowMessage({
        type: MessageType.PageAddedToPlaylist,
        callback: () => {
          stopRedirect = true;
        },
        playlistTitle: title || i18n.t(DEFAULT_TITLE.Playlist),
      }),
    );
    //
    yield delay(NOTIFICATION_DURATIONS.Info);
    yield put(actionCreator(SupportAction.ShowContentLoader, { state: false }));
    if (!stopRedirect) {
      history.push(`/maker/${newPlaylistID}/edit`);
    }
    // TBD
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}


function* getSmartFileInfo(action) {
  try {
    const { payload: { playlistId, itemId } } = action;

    const { data } = yield call(requestGetLinkPagesInfo(playlistId));
    const prepareDate = { ...data,

      isPrepareToDownload: true,
      linkPages: data.linkPages.map(lp => {
        if (!lp.textComponent.length) return { ...lp, libraryComponent: lp.libraryComponent[0] };
        const textState = draftDataConvertToLoad(lp.textComponent[0].content);
        return { ...lp, textComponent: { ...lp.textComponent[0], state: textState } };
      },
      ) };
    yield put(actionCreator(ContentActionType.updateSpecificElementR, { content: { [itemId]: prepareDate },
      id: itemId }));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}
function* addOpenPlaylistManager(action) {
  try {
    const { wrapperId } = action.payload;
    const { id: userId } = yield select(getUser);
    if (!userId) {
      return;
    }
    yield call(requestUpdatePlaylistManagerOpen, { wrapperId });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
export default function* playlistSaga() {
  yield takeEvery(EditPlaylist.GetPublish, getPublishPlaylist);
  yield takeLatest(MiniPlaylists.getMiniPlaylistsClear, downloadMiniPlaylists);
  yield takeLatest(MiniPlaylists.getMiniPlaylists, downloadMiniPlaylists);
  yield takeLatest(EditPlaylist.Create, createPlaylist);
  yield takeEvery(MiniPlaylists.getMiniPlaylistForce, getMiniPlaylist);
  yield takeEvery(EditPlaylist.EditShareState, editStatePlaylist);
  yield takeEvery(MiniPlaylists.togglePublishMiniPlaylist, editStatePlaylist);
  yield takeEvery(EditPlaylist.Delete, deletePlaylist);
  yield takeEvery(EditPlaylist.updateMoveToTrash, updateMoveToTrashPlaylist);
  yield takeEvery(EditPlaylist.ToggleFavorite, toggleFavorite);
  yield takeLatest(EditPlaylist.EditTitle, editTitlePlaylist);
  yield takeLatest(EditPlaylist.EditDescriptionS, editDescriptionPlaylist);
  yield takeLatest(
    EditPlaylist.ToggleIsShowDescriptionPlaylist,
    toggleIsShowDescriptionPlaylist,
  );
  yield takeLatest(EditPlaylist.EditImage, editImagePlaylist);
  yield takeLatest(GET_CURRENT_PLAYLIST, getPlaylist);
  yield takeLatest(CurrentPage.saveInputPlaylist, editStatePlaylist);
  yield takeLatest(
    CurrentPage.toggleHideAdditionalInformation,
    editStatePlaylist,
  );
  // yield takeLatest(EditPlaylist.EditComplementaryPlaylists, editComplementaryPlaylists);
  yield takeLatest(EditPlaylist.AddImage, addImage);
  yield takeLatest(EditPlaylist.ChangeDefaultColor, changeDefaultColor);
  yield takeLatest(EditPlaylist.DeleteImage, deleteCoverImage);
  yield takeLatest(EditPlaylist.MakerDeleteImage, deleteMakerCoverImage);
  yield takeLatest(EditPlaylist.DuplicatePlaylist, duplicatePlaylist);
  yield takeLatest(EditPlaylist.UpdateTime, updateTime);
  yield takeLatest(SupportAction.JumpToPlaylist, jumpToPlaylist);
  // yield takeLatest(SupportAction.FindHashTags, findHashTags);
  yield takeLatest(Tags.RefreshUserTags, refreshUserTags);
  yield takeLatest(
    EditPlaylist.PlaylistChannelPublishManagement,
    playlistChannelPublishManagement,
  );
  yield takeEvery(
    EditPlaylist.HelpAdminPublish,
    publishPlaylistIntoHelpChannel,
  );
  yield takeEvery(Channels.ChangeChannelUserStatus, changeSubscriberStatus);
  yield takeEvery(
    EditPlaylist.CreatePageAndAddToBuilder,
    createPageAndAddToBuilder,
  );
  // yield takeEvery(CurrentPage.UpdatePlaylistLastModifiedDate, updatePlaylistLastModified);
  yield takeEvery(EditPlaylist.Publish, Publish);
  yield takeEvery(EditPlaylist.multiplePublish, multiplePublish);
  yield takeEvery(EditPlaylist.DiscardS, Discard);
  yield takeEvery(EditPlaylist.updateShareToWebStatePlaylistSR, updateShareToWebStatePlaylist);
  yield takeEvery(EditPlaylist.updatePublishStatePlaylistSR, updatePublishStatePlaylistSR);
  yield takeEvery(EditPlaylist.updateShareToWebStateManyPlaylistSR, updateManyShareToWebStatePlaylist);
  yield takeEvery(EditPlaylist.updateShareToUserPlaylistSR, updateShareToUserPlaylist);
  yield takeEvery(EditPlaylist.UnPublishS, UnPublish);
  yield takeEvery(
    EditPlaylist.DuplicateAndSwitchPlaylist,
    duplicateAndSwitchPlaylist,
  );
  yield takeEvery(EditPlaylist.AddPageToPlaylist, addPageToPlaylist);
  yield takeEvery(
    EditPlaylist.CreatePlaylistAndAddPageToIt,
    createPlaylistAndAddPageToIt,
  );
  yield takeEvery(EditPlaylist.MarkViewedS, markViewedS);
  yield takeEvery(
    MiniPlaylists.GetLinkPagesForPlaylistS,
    GetLinkPagesForPlaylist,
  );
  yield takeEvery(SupportAction.JumpToWithConfirmation, JumpToWithConfirmation);
  yield takeEvery(SupportAction.ClearReadyConfirmation, ClearReadyConfirmation);
  yield takeEvery(MiniPlaylists.ToggleHide, toggleHide);
  yield takeEvery(HelpActions.RearrangeHelpPlaylistS, rearrangeHelpPlaylist);
  yield takeEvery(
    EditPlaylist.updateShareStatePlaylistS,
    editShareStatePlaylist,
  );
  yield takeEvery(EditPlaylist.AddUserToSharing, AddUserToSharing);
  yield takeEvery(EditPlaylist.RemoveUserToSharing, RemoveUserToSharing);
  yield takeEvery(EditPlaylist.RemoveChannel, RemoveChannel);
  yield takeLatest(EditPlaylist.SendEmailToSharingS, SendEmailToSharing);
  yield takeLatest(EditPlaylist.GetSharedPlaylist, GetSharedPlaylist);
  yield takeLatest(EditPlaylist.GetSharedManyPlaylist, GetSharedManyPlaylist);
  yield takeLatest(
    EditPlaylist.ChangeTextElementBlockRedux,
    ChangeTextElementBlockRedux,
  );
  yield takeEvery(EditPlaylist.ShareMultiplePlaylistsS, ShareMultiplePlaylists);
  yield takeEvery(EditPlaylist.UpdatePlaylist, UpdatePlaylistField);
  yield takeLatest(
    EditPlaylist.CreatePlaylistFromLib,
    CreatePlaylistWithSelectedLibItems,
  );
  yield takeEvery(EditPlaylist.getUsersToSharingPl, getUsersToSharingPl);
  yield takeEvery(EditPlaylist.getChannelsToPublishPl, getChannelsToPublishPl);
  yield takeEvery(EditPlaylist.editShareStatePlaylistSingleUser, editShareStatePlaylistSingleUser);
  yield takeEvery(EditPlaylist.UpdateInfoLinkPage, getSmartFileInfo);
  yield takeEvery(EditPlaylist.OpenSmartFile, addOpenPlaylistManager);
  // yield takeLatest(GET_CURRENT_PLAYLIST, getPlaylist);
}
