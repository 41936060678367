import React from 'react';
import createImagePlugin from 'draft-js-image-plugin';
import { v4 as uuidv4, v4 as uuid } from 'uuid';
import DOMPurify from 'dompurify';
import {
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from 'draft-js';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import {
  ALLOWED_HEX_SYMBOLS,
  BlockTypes,
  ChannelsSieveTypes,
  DEFAULT_POSITION_STEP,
  DEFAULT_TITLE,
  LibCompSieveTypes,
  LibCompSieveTypesSideBar,
  LibCompSieveTypesTest,
  LibraryComponentTypes,
  MessageType,
  PLAYLIST_ELEMENTS,
  PLAYLIST_TYPES,
  PlaylistSideBarLibrarySieveTypes,
  REGEXP_ALL_LANGUAGES,
  UiComponentTypes,
  aiProcessingStatusEnum,
} from './constants';
import { ReactComponent as MsTypeSvg } from '../images/icons/libComponentTypeWord.svg';
import { ReactComponent as PdfTypeSvg } from '../images/icons/libComponentTypePdf.svg';
import { ReactComponent as PresentationTypeSvg } from '../images/icons/libComponentTypePresentation.svg';
import { ReactComponent as LinkTypeSvg } from '../images/icons/libComponentTypeLink.svg';
import { ReactComponent as GoogleTypeSvg } from '../images/icons/google_drive_16.svg';
import { ReactComponent as DropboxTypeSvg } from '../images/icons/dropbox_16.svg';
import { ReactComponent as EmbedTypeSvg } from '../images/icons/embed option_16.svg';
import { ReactComponent as ComponentTypeSvg } from '../images/icons/libComponentTypeComponent.svg';
import { ReactComponent as PageElement24Svg } from '../images/icons/page_element_24_30.svg';
import { ReactComponent as WebElement28Svg } from '../images/icons/elements_link_icon_30-30.svg';
import { ReactComponent as CompElement28Svg } from '../images/icons/elements_component_icon_28-30.svg';
import { ReactComponent as PdfElement28Svg } from '../images/icons/elements_pdf_icon_28-34.svg';
import { ReactComponent as ImgElement28Svg } from '../images/icons/elements_image_28-28.svg';
import rbStyles from '../pages/Maker/MakerCardView/elements.module.scss';

import draftDataConvertToLoad from './draftDataConvert';
import { ReactComponent as PagePink24Svg } from '../images/icons/page_icon_26.svg';
import { DefaultDraftBlockRenderMap1 } from './draftConstants';
import { actionShowMessage } from '../redux/support/action';
import { convertDate, createTimeRange } from './dateConvert';
import EditPlaylist from '../redux/playlists/types';
import { actionPageWillDownload } from '../redux/currentPage/action';
import { actionAddPageIntoLibrary } from '../redux/library/actions';
import { getNameFromState } from './draftJsHelpers';
import { actionAddPayloadUnifyHistory } from '../redux/history/actions';
import { convertLexicalToText } from '../shared/lexical/convert';
import { smartFileItemTypeCheck } from '../shared/smartFile/constant';
import i18n from '../i18n';

export const getDataPlace = (e) => e.target?.dataset.place || e.target?.parentElement?.dataset.place;

export function readableSizeConverter(size) {
  if (!size) return '-';
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** i).toFixed(2) * 1} ${
    ['B', 'KB', 'MB', 'GB', 'TB'][i]
  }`;
}

export const getFileName = (name) => name.trim().split('.').slice(0, -1).join('.') || 'unnamed';

export const getFileExt = (name) => name.trim().split('.').slice(-1).join('.');

const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;

// eslint-disable-next-line max-len
const DATA_URL_PATTERN = /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+\/]+=*$/i;

function _sanitizeUrl(url) {
  url = String(url);
  if (url === 'null' || url.length === 0 || url === 'about:blank') return 'about:blank';
  if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN)) return url;

  return `unsafe:${url}`;
}

export const sanitizeUrl = (url = 'about:blank') => _sanitizeUrl(String(url).trim());

export const sanitizeHtml = (dirty) => DOMPurify.sanitize(dirty, {
  ADD_TAGS: ['iframe'],
  ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
});

// eslint-disable-next-line max-len
export const innerHtmlImageTemplate = (url) => `<div class='customEditor'><div class='DraftEditor-root'><div class='DraftEditor-editorContainer'><div class='public-DraftEditor-content' contenteditable='false' spellcheck='false' style='outline: none; user-select: text; white-space: pre-wrap; overflow-wrap: break-word;'><div data-contents='true'><div class='media' data-block='true' data-editor='275qd' data-offset-key='b4u93-0-0'><div data-offset-key='b4u93-0-0' class='public-DraftStyleDefault-block public-DraftStyleDefault-ltr'><span data-offset-key='b4u93-0-0'><br data-text='true'></span></div></div><figure class='' data-block='true' data-editor='275qd' data-offset-key='880ru-0-0' contenteditable='false'><img alt='draftImage' src='${url}' role='presentation' class='draftJsEmojiPlugin__image__192TI'></figure><div class='' data-block='true' data-editor='275qd' data-offset-key='77g01-0-0'><div data-offset-key='77g01-0-0' class='public-DraftStyleDefault-block public-DraftStyleDefault-ltr'><span data-offset-key='77g01-0-0'><br data-text='true'></span></div></div></div></div></div></div></div>`;
export const innerHtmlImageTemplateShort = (url, width = 700) => `<div style="  display: flex; justify-content: center; "> <div><figure><img  src='${url}'  style='width:${width}px' alt="editor_image"/></figure></div></div>`;

// eslint-disable-next-line max-len
export const contentImageTemplate = (url) => `{'blocks':[{'key':'b4u93','text':'','type':'image','depth':0,'inlineStyleRanges':[],'entityRanges':[],'data':{}},{'key':'880ru','text':' ','type':'atomic','depth':0,'inlineStyleRanges':[],'entityRanges':[{'offset':0,'length':1,'key':0}],'data':{}},{'key':'77g01','text':'','type':'unstyled','depth':0,'inlineStyleRanges':[],'entityRanges':[],'data':{}}],'entityMap':{'0':{'type':'IMAGE','mutability':'IMMUTABLE','data':{'src':'${url}'}}}}`;

// eslint-disable-next-line max-len
export const innerHtmlWebSiteTemplate = ({
  images,
  title,
  description,
  url,
}) => {
  let imageUrl;
  if (Array.isArray(images)) {
    imageUrl = images[0];
  } else {
    imageUrl = images;
  }

  // eslint-disable-next-line max-len
  return `<div class='embed_website'><div class='preview_image_wrapper'><img class='preview_image' src='${imageUrl}' alt='preview'></div><div class='embed_website_text'><div class='embed_website_h1'>${title}</div><div class='embed_website_description'>${description}</div><div class='embed_website_url'>${url}</div></div></div>`;
};
// eslint-disable-next-line max-len
export const innerHtmlGoogleEmbedTemplate = ({
  images,
  title,
  url,
  foreignLastModifiedDate,
  foreignLastModifiedUserName,
}) => {
  let imageUrl;
  if (Array.isArray(images)) {
    imageUrl = images[0];
  } else {
    imageUrl = images;
  }

  // eslint-disable-next-line max-len
  return `<div class="google_embed_website"><div class="google_preview_image_wrapper"><img class="google_preview_image" src="${imageUrl}" alt="google_preview"></div><div class="google_embed_website_text"><div class="google_embed_website_h1">${title}</div><div class="google_embed_website_url">${url}</div><div class="google_embed_website_modified">Last updated: ${convertDate(
    foreignLastModifiedDate,
  )}</div><div class="google_embed_website_modified">By: ${foreignLastModifiedUserName}</div></div></div>`;
}; // eslint-disable-next-line max-len
export const innerHtmlDropboxEmbedTemplate = ({ images, title, url }) => {
  let imageUrl;
  if (Array.isArray(images)) {
    imageUrl = images[0];
  } else {
    imageUrl = images;
  }

  // eslint-disable-next-line max-len
  return `<div class="google_embed_website"><div class="google_preview_image_wrapper"><img class="google_preview_image" src="${imageUrl}" alt="google_preview"></div><div class="google_embed_website_text"><div class="google_embed_website_h1">${title}</div><div class="google_embed_website_url">${url}</div></div></div>`;
};
// eslint-disable-next-line max-len
export const innerHtmlWebTextTemplate = (text) => `<div class="customEditor"><div class="DraftEditor-root"><div class="DraftEditor-editorContainer"><div class="notranslate public-DraftEditor-content" contenteditable="true" role="textbox" spellcheck="false" style="outline: none; user-select: text; white-space: pre-wrap; overflow-wrap: break-word;"><div data-contents="true"><div class="text" data-block="true" data-editor="2jkhb" data-offset-key="1ftsl-0-0"><div data-offset-key="1ftsl-0-0" class="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"><span data-offset-key="1ftsl-0-0"><span data-text="true">${text}</span></span></div></div></div></div></div></div></div>`;

// eslint-disable-next-line max-len
export const innerHtmlLineSeparatorTemplate = "<div class='CustomBlocks_line__kQ8pl CustomBlocks_single__23Q1l' data-reactroot=''></div>";

// eslint-disable-next-line max-len
export const innerHtmlPdfTemplate = (title, size) => `<div class='pdfBlock_container__1TQbR'><div class='pdfBlock_icon__3tOsJ'><svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M16.9186 5.90165C19.4541 3.36612 23.564 3.36612 26.0983 5.90165C28.6339 8.43719 28.6339 12.5471 26.0983 15.0814L15.0814 26.0983C12.5459 28.6339 8.43599 28.6339 5.90165 26.0983C3.36612 23.5628 3.36612 19.4529 5.90165 16.9186L11.8691 10.9511C13.1363 9.68396 15.1918 9.68396 16.459 10.9511C17.7262 12.2183 17.7262 14.2738 16.459 15.541L10.4927 21.5085' stroke='#596080' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'></path></svg></div><div class='pdfBlock_text__3dQaM'>${title}</div><div class='pdfBlock_size_text__IFFr9'>${readableSizeConverter(
  size,
)}</div></div>`;

// eslint-disable-next-line
export const innerHtmlApproveButtonTemplate =
  "<div class='CustomBlocks_question_wrapper__1j0ZA'><div class='CustomBlocks_question__1185I'><div class='CustomBlocks_question_text__xFZ6i'>Question Name</div></div><div class='CustomBlocks_approve_button__VHjHf CustomBlocks_green__30FxN'>Approve</div></div>"; // eslint-disable-next-line
// eslint-disable-next-line
export const innerHtmlPageLink = (title) => `<div class='MomentBlock_hiddenWrapper__om--X'><div class='MomentBlock_wrapper__1tqsO'><div class='CustomBlocks_page_link_wrapper__EkNy2'><div class='CustomBlocks_page_link_container__1vKFq'><svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' class='CustomBlocks_icon__2QAw2'><path d='M24.726 29.3327H7.75627C6.41688 29.3327 5.33203 28.2478 5.33203 26.9084V5.09026C5.33203 3.75086 6.41688 2.66602 7.75627 2.66602H17.4532L27.1502 12.363V26.9084C27.1502 28.2478 26.0642 29.3327 24.726 29.3327Z' fill='#E4E7F7' stroke='#596080' stroke-width='1.06667' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'></path><path d='M27.1501 12.363H17.4531V2.66602' stroke='#596080' stroke-width='1.06667' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'></path></svg><div class='CustomBlocks_page_name__3o_Oh'>${title}</div><div class='CustomBlocks_hint__2Fj5L'>Link to Page</div></div></div></div></div>`;

export const innerHtmlEmbed = (textHtml) => `<div class='CustomBlocks_embedPreview__nsi8O'>${textHtml}</div>`;

export const previewAsPdf = (type) => UiComponentTypes.ms[type]
  || UiComponentTypes.xls[type]
  || UiComponentTypes.pdf[type];
export const hasPreviewPdf = (type) => UiComponentTypes.ms[type] || UiComponentTypes.xls[type];

export const stringifyStateToContent = (state) => {
  try {
    return JSON.stringify(convertToRaw(state.getCurrentContent()))
      .trim()
      .replaceAll("'", '$@quote')
      .replaceAll('"', "'")
      .replaceAll("\\'", '\\"')
      .replaceAll('\n', '')
      .replaceAll('\\n', '');
  } catch (err) {
    // eslint-disable-next-line no-console
    // console.error(err.message);
    return '';

    // return new Error(err);
  }
};

export const stringifyInnerHtml = (html) => {
  try {
    const innerSpanRegex = /data-text=('|")true\1>(.*?)<\/span>/;
    const match = html?.match(innerSpanRegex);
    let exHtml = html;
    if (match && match[2]) {
      const innerSpanText = match[2];

      // Sanitize the inner span text using sanitizeToSave function
      const sanitizedInnerSpanText = sanitizeToSave(innerSpanText);

      // Replace the original inner span text with the sanitized text in the HTML string
      exHtml = html.replace(
        innerSpanRegex,
        `data-text=$1true$1>${sanitizedInnerSpanText}</span>`,
      );
    }
    exHtml = exHtml
      ?.trim()
      .replaceAll("'", '$@quote')
      .replaceAll('"', "'")
      .replaceAll('\\\\', '\\') // Corrected replacement for backslashes
      .replaceAll("\\'", '\\"')
      .replaceAll(/(\r\n|\n|\r)/gm, '')
      .replaceAll('\\n', '');
    return `"${exHtml}"`;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err.message);
    return '';
    // return new Error(err);
  }
};

export const sanitizeToLoad = (state) => {
  try {
    return state
      .replaceAll('^$_@qslash', '\\')
      .replaceAll('^$_@quote3', '\\"')
      .replaceAll('^$_@quote2', '"')
      .replaceAll(/(\r\n|\n|\r)/gm, '\\n')
      .replaceAll('^$_@quote1', "'")
      .replaceAll('$@quote', "'")
      .replaceAll('^$_@Newline', '\n')
      .replaceAll('^$_@TabSymbol', '\t');
  } catch (e) {
    return '';
  }
};

export const parseMarkdown = (state) => {
  try {
    return state
      .replace(/(.*)<$/, '$1')
      .replace(/^##### (.*?)\s*#*$/gm, '<h5>$1</h5>')
      .replace(/^#### (.*?)\s*#*$/gm, '<h4 id="$1">$1</h4>')
      .replace(/^### (.*?)\s*#*$/gm, '<h3 id="$1">$1</h3>')
      .replace(/^## (.*?)\s*#*$/gm, '<h2 id="$1">$1</h2>')
      .replace(/^# (.*?)\s*#*$/gm, '<h1 id="$1">$1</h1>')
      .replace(/^-{3,}|^\_{3,}|^\*{3,}/gm, '<hr/>')
      .replace(/``(.*?)``/gm, '<code>$1</code>')
      .replace(/`(.*?)`/gm, '<code>$1</code>')
      .replace(/^\>> (.*$)/gm, '<blockquote><blockquote>$1</blockquote></blockquote>')
      .replace(/^\> (.*$)/gm, '<blockquote>$1</blockquote>')
      .replace(/<\/blockquote\>\n<blockquote\>/g, '\n<br>')
      .replace(/<\/blockquote\>\n<br\><blockquote\>/g, '\n<br>')
      .replace(/!\[(.*?)\]\((.*?) "(.*?)"\)/gm, '<img alt="$1" src="$2" $3 />')
      .replace(/!\[(.*?)\]\((.*?)\)/gm, '<img alt="$1" src="$2" />')
      .replace(/\[(.*?)\]\((.*?) "(.*?)"\)/gm, '<a href="$2" title="$3">$1</a>')
      .replace(/<http(.*?)\>/gm, '<a href="http$1">http$1</a>')
      .replace(/\[(.*?)\]\(\)/gm, '<a href="$1">$1</a>')
      .replace(/\[(.*?)\]\((.*?)\)/gm, '<a href="$2">$1</a>')
      .replace(/^[\*|+|-][ |.](.*)/gm, '<ul><li>$1</li></ul>')
      .replace(/<\/ul\>\n<ul\>/g, '\n')
      .replace(/^\d[ |.](.*)/gm, '<ol><li>$1</li></ol>')
      .replace(/<\/ol\>\n<ol\>/g, '\n')
      .replace(/\*\*\*(.*)\*\*\*/gm, '<b><em>$1</em></b>')
      .replace(/\*\*(.*)\*\*/gm, '<b>$1</b>')
      .replace(/\*([\w \d]*)\*/gm, '<em>$1</em>')
      .replace(/___(.*)___/gm, '<b><em>$1</em></b>')
      .replace(/__(.*)__/gm, '<u>$1</u>')
      .replace(/_([\w \d]*)_/gm, '<em>$1</em>')
      .replace(/~~(.*)~~/gm, '<del>$1</del>')
      .replace(/\^\^(.*)\^\^/gm, '<ins>$1</ins>')
      .replace(/ +\n/g, '\n<br/>')
      .replace(/\n\s*\n/g, '\n<p>\n')
      .replace(/^ {4,10}(.*)/gm, '<pre><code>$1</code></pre>')
      .replace(/^\t(.*)/gm, '<pre><code>$1</code></pre>')
      .replace(/<\/code\><\/pre\>\n<pre\><code\>/g, '\n')
      .replace(/\\([`_\\\*\+\-\.\(\)\[\]\{\}])/gm, '$1')
      .split('\\n')
      .join('<br />')
      .replaceAll('*', '');
  } catch (e) {
    return '';
  }
};

export const sanitizeToSave = (state) => {
  try {
    return state
      .replaceAll('\\', '^$_@qslash')
      .replaceAll("'", '^$_@quote1')
      .replaceAll('"', '^$_@quote2')
      .replaceAll("\\'", '^$_@quote3')
      .replaceAll('\n', '^$_@Newline')
      .replaceAll('\t', '^$_@TabSymbol');
  } catch (e) {
    return '';
  }
};

export const replaceDoubleQuotes = (str) => {
  let result = str.replace(/"/g, "'");
  result = result.replace(/'$/g, '');
  result = result.replaceAll('\\', '\\\\');
  result = `"${result.substring(0, result.length - 1)}}"`;
  return result;
};

export const replaceDoubleQuotesFix = (str) => {
  let result = str.replace(/"/g, "'");
  result = result.replace(/'$/g, '');
  result = result.replaceAll('\\', '\\\\');
  result = `${result.substring(0, result.length - 1)}}`;
  return result;
};

export const removeStringifiedSymbols = (string) => {
  if (!string || typeof string !== 'string') return '';
  return string
    .replaceAll(/(\u000a)/gm, '') // New Line
    .replaceAll(/(\u000b)/gm, '') // Tab Line
    .replaceAll(/(\u000c)/gm, '') // Page break
    .replaceAll(/(\u000d)/gm, ''); // Carriage return
};

export const calculateItemsStylesMap = (itemsArray) => {
  let firstNotTextIndex = 0;
  for (; firstNotTextIndex <= itemsArray.length; firstNotTextIndex++) {
    if (firstNotTextIndex === itemsArray.length) {
      return itemsArray.reduce((acc, item) => {
        acc[item.id] = {
          style: 'no_line',
        };
        return acc;
      }, {});
    }
    if (itemsArray[firstNotTextIndex].type !== 'elementText') break;
  }

  let lastNotTextIndex = itemsArray.length - 1;
  for (; lastNotTextIndex > 0; lastNotTextIndex--) {
    if (itemsArray[lastNotTextIndex].type !== 'elementText') {
      break;
    }
  }

  let fakeIndex = 0;

  return itemsArray.reduce((acc, item, index) => {
    if (item.type !== 'elementText') fakeIndex++;
    acc[item.id] = {};
    if (index < firstNotTextIndex) acc[item.id].style = 'no_line';
    if (index === firstNotTextIndex) acc[item.id].style = 'first';
    if (index > firstNotTextIndex) acc[item.id].style = 'regular';
    if (index === lastNotTextIndex) acc[item.id].style = 'last';
    if (index > lastNotTextIndex) acc[item.id].style = 'no_line';
    if (index === firstNotTextIndex && index === lastNotTextIndex) acc[item.id].style = 'no_line';
    if (item.type !== 'elementText') acc[item.id].number = fakeIndex;
    else acc[item.id].number = null;
    if (item.isRead) acc[item.id].isRead = true;
    return acc;
  }, {});
};

export const calculateItemsStylesMapMaker = (itemsArray) => {
  const firstNotTextIndex = 0;
  const lastNotTextIndex = itemsArray.length - 1;
  let fakeIndex = 0;
  let hasTitle = false;

  return itemsArray.reduce((acc, item, index) => {
    acc[item.id] = {};
    if (!smartFileItemTypeCheck.isText[item.type] && !smartFileItemTypeCheck.isTitle[item.type]) {
      fakeIndex++;
      acc[item.id].customIndex = fakeIndex;
    }

    if (smartFileItemTypeCheck.isTitle[item.type]) {
      hasTitle = true;
      acc[item.id].isTitle = true;
    } else if (hasTitle) {
      acc[item.id].isAfterTitle = true;
    }

    if (index < firstNotTextIndex) acc[item.id].style = 'no_line';
    if (index === firstNotTextIndex) acc[item.id].style = 'first';
    if (index > firstNotTextIndex) acc[item.id].style = 'regular';
    if (index === lastNotTextIndex) acc[item.id].style = 'last';
    if (index > lastNotTextIndex) acc[item.id].style = 'no_line';
    if (index === firstNotTextIndex && index === lastNotTextIndex) acc[item.id].style = 'no_line';
    acc[item.id].number = index;
    if (item.isRead) acc[item.id].isRead = true;
    return acc;
  }, {});
};

export const getItemName = (element) => {
  try {
    if (element?.type === 'elementText') {
      return getNameFromState(element) || i18n.t('emptyTextBlockPlaceholderT');
    }
    if (smartFileItemTypeCheck.isLexicalText[element?.type]) {
      const parseText = Object.values(element?.textComponent?.state).length
        && convertLexicalToText(element?.textComponent?.state);

      if (!parseText) {
        if (smartFileItemTypeCheck.isTitle[element?.type]) return i18n.t('emptyTitleT');
        if (smartFileItemTypeCheck.isRichText[element?.type]) return i18n.t('emptyTextT');
      }

      return parseText;
    }
    return (
      element?.title
      || element?.libraryComponent?.title
      || i18n.t(DEFAULT_TITLE.PlaylistElement)
    );
  } catch (e) {
    return i18n.t(DEFAULT_TITLE.PlaylistElement);
  }
};

export const builderDragItemTitle = (item) => {
  if (!item?.type) return i18n.t('placeholderT');
  if (item?.type === 'elementText' || item?.type === 'elementTitle') {
    const text = getItemName(item);
    return text || i18n.t('textElementT');
  }
  if (item?.type === 'upload') return i18n.t('uploadElementT');
  if (
    item.libraryComponent
    && !UiComponentTypes.page[item.libraryComponent.type]
  ) {
    return item.libraryComponent.title || i18n.t('untitledComponentT');
  }
  if (
    item.libraryComponent
    && UiComponentTypes.page[item.libraryComponent.type]
  ) {
    return item.libraryComponent.title || i18n.t(DEFAULT_TITLE.Page);
  }
  return i18n.t('unknownElementT');
};

export const calculateIndex = (previous, next) => {
  if (!previous && next) {
    return next / 2;
  }
  if (previous && !next) {
    return previous + DEFAULT_POSITION_STEP;
  }
  if (previous && next) {
    return (previous + next) / 2;
  }
  return DEFAULT_POSITION_STEP;
};

export const derivePositionFromName = (string) => string.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);

const trimTitle = (title, number) => {
  const strToDelete = `(${number})`;
  const length1 = title.length;
  const length2 = strToDelete.length;
  const res = title.substring(0, length1 - length2);
  return res.trim();
};

export const getNewTitle = (oldTitle, isFile) => {
  let ext = '';
  if (isFile) {
    ext = `.${getFileExt(oldTitle)}`;
    oldTitle = getFileName(oldTitle);
  }
  const allNumbersInBracketsArray = oldTitle
    .split(' ')
    .filter((i) => i[0] === '(' && i[i.length - 1] === ')');
  const numbersArray = allNumbersInBracketsArray.map((i) => i.substring(1, i.length - 1),
  );
  const lastVersion = numbersArray[numbersArray.length - 1];
  if (oldTitle[oldTitle.length - 1] === ')' && lastVersion) {
    return `${trimTitle(oldTitle, lastVersion)} (${+lastVersion + 1})${ext}`;
  }
  return `${oldTitle} (1)${ext}`;
};

export const TypeIcon = ({ type }) => {
  const iconPicker = () => {
    if (type === LibraryComponentTypes.embed_component) {
      return <EmbedTypeSvg />;
    }
    if (UiComponentTypes.ms[type]) {
      return <MsTypeSvg />;
    }
    if (UiComponentTypes.xls[type]) {
      return '';
    }
    if (UiComponentTypes.pdf[type]) {
      return <PdfTypeSvg />;
    }
    if (UiComponentTypes.presentation[type]) {
      return <PresentationTypeSvg />;
    }
    if (UiComponentTypes.google_embed_component[type]) {
      return <GoogleTypeSvg style={{ width: 24, height: 24 }} />;
    }
    if (UiComponentTypes.dropbox_embed_component[type]) {
      return <DropboxTypeSvg style={{ width: 24, height: 24 }} />;
    }
    if (UiComponentTypes.link[type]) {
      return <LinkTypeSvg />;
    }
    if (UiComponentTypes.component[type]) {
      return <ComponentTypeSvg />;
    }
    if (UiComponentTypes.page[type]) {
      return <PagePink24Svg />;
    }
    if (
      UiComponentTypes.image[type]
      || UiComponentTypes.page[type]
      || UiComponentTypes.media[type]
    ) {
      return '';
    }
    return type;
  };
  return <>{iconPicker()}</>;
};

export const ElementType = ({ type, lcType }) => {
  const iconPicker = () => {
    if (
      type === PLAYLIST_ELEMENTS.ElementComponent
      && UiComponentTypes.archive[lcType]
    ) {
      return <div className={rbStyles.noIconPlaceholder} />;
    }
    if (
      type === PLAYLIST_ELEMENTS.ElementComponent
      && UiComponentTypes.page[lcType]
    ) {
      return <PageElement24Svg />;
    }
    if (
      type === PLAYLIST_ELEMENTS.ElementComponent
      && UiComponentTypes.google_embed_component[lcType]
    ) {
      return <GoogleTypeSvg style={{ width: 32, height: 32 }} />;
    }
    if (
      type === PLAYLIST_ELEMENTS.ElementComponent
      && UiComponentTypes.dropbox_embed_component[lcType]
    ) {
      return <DropboxTypeSvg style={{ width: 32, height: 32 }} />;
    }
    if (
      type === PLAYLIST_ELEMENTS.ElementComponent
      && UiComponentTypes.link[lcType]
    ) {
      return <WebElement28Svg />;
    }
    if (
      type === PLAYLIST_ELEMENTS.ElementComponent
      && UiComponentTypes.component[lcType]
    ) {
      return <CompElement28Svg />;
    }
    if (
      type === PLAYLIST_ELEMENTS.ElementComponent
      && UiComponentTypes.presentation[lcType]
    ) {
      return (
        <div className={rbStyles.slidesIcon}>
          <PresentationTypeSvg />
        </div>
      );
    }
    if (
      type === PLAYLIST_ELEMENTS.ElementComponent
      && UiComponentTypes.pdf[lcType]
    ) {
      return <PdfElement28Svg />;
    }
    if (
      type === PLAYLIST_ELEMENTS.ElementComponent
      && UiComponentTypes.image[lcType]
    ) {
      return <ImgElement28Svg />;
    }
    if (
      type === PLAYLIST_ELEMENTS.ElementComponent
      && UiComponentTypes.xls[lcType]
    ) {
      return <div className={rbStyles.noIconPlaceholder} />;
    }
    if (
      type === PLAYLIST_ELEMENTS.ElementComponent
      && UiComponentTypes.ms[lcType]
    ) {
      return <div className={rbStyles.noIconPlaceholder} />;
    }
    if (type === 'pages') {
      return <PageElement24Svg />;
    }
    return <div className={rbStyles.noIconPlaceholder} />;
  };
  return <>{iconPicker()}</>;
};

export function validateURL(str) {
  // eslint-disable-next-line no-useless-escape
  const pattern = new RegExp(
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,
  );
  return !!pattern.test(str);
}

export const createNodeFromHtml = (html) => {
  if (!html) return false;
  const template = document.createElement('template');
  template.innerHTML = html.trim(); // Never return a text node of whitespace as the result
  return template;
};

export const intersect = (boxA, boxB) => {
  return (
    boxA.left <= boxB.left + boxB.width
    && boxA.left + boxA.width >= boxB.left
    && boxA.top <= boxB.top + boxB.height
    && boxA.top + boxA.height >= boxB.top
  );
};

export const parseContentForUsage = (content) => {
  let contentParser1;
  let contentParser2;
  let contentParser3;
  try {
    contentParser1 = JSON.parse(
      content
        .replaceAll('"', '\\"')
        .replaceAll("'", '"')
        .replaceAll(/(\r\n|\n|\r)/gm, '\\n')
        .replaceAll('$@quote', "'"),
    );
  } catch (err) {
    contentParser1 = null;
  }
  try {
    contentParser3 = JSON.parse(
      sanitizeToLoad(content).slice(1, -1).replaceAll("'", '"'),
    );
  } catch (err) {
    contentParser3 = null;
  }
  try {
    contentParser2 = JSON.parse(sanitizeToLoad(content));
  } catch (err) {
    contentParser2 = null;
  }
  return contentParser1 || contentParser3 || contentParser2;
};

export const checkString = (str) => {
  return str.match(REGEXP_ALL_LANGUAGES);
};

// export const sanitizeString = (str) => {
//   const clearStr = str.replace(/[^a-z0-9áéíóúñü_\.,-]/gim, '').replaceAll('\x20', '_');
//   return clearStr.trim();
// };

export const checkTagExists = (tags, tagName) => {
  return !!Object.values(tags || {}).filter((item) => item.title === tagName)
    .length;
};

export const createNewName = (tags, tagNewName) => {
  if (checkTagExists(tags, tagNewName)) {
    let counter = 2;
    while (checkTagExists(tags, `${tagNewName} ${counter}`)) counter++;
    return `${tagNewName} ${counter}`;
  }
  return tagNewName;
};

export const createNewTagName = (tags, tagNewName = 'untitled_tag') => {
  if (checkTagExists(tags, tagNewName)) {
    let counter = 2;
    while (checkTagExists(tags, `${tagNewName}_${counter}`)) counter++;
    return `Untitled tag ${counter}`;
  }
  return 'Untitled tag';
};

export const pipeAddIsHasTextMeta = (innerItem) => {
  if (!innerItem?.state) return innerItem;

  if (
    innerItem.state.getCurrentContent
    && innerItem.state.getCurrentContent().getPlainText()
  ) {
    return { ...innerItem, isHasText: true };
  }

  const rawDraftState = parseContentForUsage(innerItem.state);
  if (!rawDraftState?.blocks) return innerItem;
  const localState = EditorState.createWithContent(
    convertFromRaw(rawDraftState),
  );
  const plainText = localState.getCurrentContent().getPlainText();
  return { ...innerItem, isHasText: !!plainText };
};

export function parseXml(xml, arrayTags) {
  let dom = null;
  if (window.DOMParser) dom = new DOMParser().parseFromString(xml, 'text/xml');
  else if (window.ActiveXObject) {
    dom = new ActiveXObject('Microsoft.XMLDOM');
    dom.async = false;
    if (!dom.loadXML(xml)) throw `${dom.parseError.reason} ${dom.parseError.srcText}`;
  } else throw new Error('cannot parse xml string!');

  function parseNode(xmlNode, result) {
    if (xmlNode.nodeName === '#text') {
      const v = xmlNode.nodeValue;
      if (v.trim()) result['#text'] = v;
      return;
    }

    const jsonNode = {};
    const existing = result[xmlNode.nodeName];
    if (existing) {
      if (!Array.isArray(existing)) result[xmlNode.nodeName] = [existing, jsonNode];
      else result[xmlNode.nodeName].push(jsonNode);
    } else if (arrayTags && arrayTags.indexOf(xmlNode.nodeName) !== -1) result[xmlNode.nodeName] = [jsonNode];
    else result[xmlNode.nodeName] = jsonNode;

    if (xmlNode.attributes) {
      for (const attribute of xmlNode.attributes) {
        jsonNode[attribute.nodeName] = attribute.nodeValue;
      }
    }

    for (const node of xmlNode.childNodes) parseNode(node, jsonNode);
  }

  const result = {};
  for (const node of dom.childNodes) parseNode(node, result);

  return result;
}

export const getLinkPagePosition = (
  destinationIndex,
  oldPages,
  targetIndex,
) => {
  if (destinationIndex + 1 < oldPages.length) {
    const prevPos = destinationIndex > 0 && oldPages[destinationIndex]?.position;
    const nextPos = targetIndex < destinationIndex
      ? oldPages[destinationIndex + 1]?.position
      : oldPages[destinationIndex]?.position;
    return calculateIndex(prevPos, nextPos);
  }
  if (!oldPages[targetIndex]) {
    return calculateIndex(oldPages[destinationIndex]?.position, null);
  }
  return oldPages[destinationIndex]?.position + DEFAULT_POSITION_STEP;
};

export const getNewLinkPagePositionByIndex = (newIndex, oldPages) => {
  const nextPos = newIndex !== oldPages.length
    ? oldPages[newIndex]?.position
    : oldPages[newIndex - 1]?.position + DEFAULT_POSITION_STEP;
  const prevPos = newIndex > 0 && oldPages[newIndex - 1].position;
  return calculateIndex(prevPos, nextPos);
};

export const getManyNewLinkPagePositionByIndexForDuplicate = (
  oldPages,
  selectedIndex,
) => {
  const lastIndex = selectedIndex[selectedIndex.length - 1];
  const prevPos = oldPages[lastIndex].position;
  const nextPos = oldPages[lastIndex + 1]?.position
    ?? prevPos + DEFAULT_POSITION_STEP * selectedIndex.length;
  const step = (nextPos - prevPos) / (selectedIndex.length + 1);
  let stepCount = 0;
  return Array.from(
    { length: selectedIndex.length },
    () => prevPos + step * ++stepCount,
  );
};
export const getManyNewLinkPagePositionByIndex = (
  newIndex,
  oldPages,
  selectedIndex,
) => {
  const lastIndex = selectedIndex[selectedIndex.length - 1];
  const order = lastIndex > newIndex ? -1 : -1;
  const prevPos = oldPages[newIndex + order]?.position ?? 0;
  const nextPos = oldPages[newIndex + 1 + order]?.position
    ?? prevPos + DEFAULT_POSITION_STEP * selectedIndex.length;
  const step = (nextPos - prevPos) / (selectedIndex.length + 1);
  let stepCount = 0;
  return Array.from(
    { length: selectedIndex.length },
    () => prevPos + step * ++stepCount,
  );
};

export const mutateImageBlockState = (content = {}, item) => {
  try {
    let rawDraftState = parseContentForUsage(item.content);
    if (typeof rawDraftState === 'string') rawDraftState = JSON.parse(rawDraftState);

    content.state = rawDraftState
      ? EditorState.createWithContent(convertFromRaw(rawDraftState))
      : EditorState.createWithContent(
        convertFromRaw(
          JSON.parse(
            "{'blocks':[{'key':'daj6c','text':'','type':'text','depth':0,'inlineStyleRanges':[],'entityRanges':[],'data':{}}],'entityMap':{}}"
              .replaceAll("'", '"')
              .replace(/(\r\n|\n|\r)/gm, '\\n'),
          ),
        ),
      );
    content.content = item?.content
      ? JSON.stringify(parseContentForUsage(item.content))
      : JSON.parse(
        "{'blocks':[{'key':'daj6c','text':'','type':'text','depth':0,'inlineStyleRanges':[],'entityRanges':[],'data':{}}],'entityMap':{}}"
          .replaceAll("'", '"')
          .replace(/(\r\n|\n|\r)/gm, '\\n'),
      );
    content.innerHtml = sanitizeToLoad(item.innerHtml) || '';
  } catch (err) {
    console.error(err);
  }
};

export const convertToSmartNumbers = (number) => {
  // Requirements according design as follows:
  // 999
  // 1.3к
  // 12.8к
  // 99к
  // 100к
  // 100k+

  if (number === 0) return '0';
  if (!number || typeof number !== 'number' || number < 0) return '';
  let digitPart = number;
  let extras = '';

  if (number < 100000 && number >= 1000) {
    if (number % 1000 === 0) {
      digitPart = number / 1000;
    } else {
      digitPart = (number / 1000).toFixed(1);
    }
    extras = 'k';
  } else if (number < 1000000 && number > 100000) {
    digitPart = Math.floor(number / 1000);
    extras = 'k';
  } else if (number >= 1000000) {
    digitPart = 999;
    extras = 'k+';
  }
  return `${digitPart}${extras}`;
};

export const combineComponentsAndLibraryComponents = (uploadedContent) => {
  let blocks = [];
  let libraryCompBlocks = [];
  if (
    uploadedContent.libraryComponents
    && !!uploadedContent.libraryComponents?.length
    && !!uploadedContent.libraryComponents[0]
  ) {
    libraryCompBlocks = uploadedContent.libraryComponents.map((item) => {
      const libraryComponent = item;
      if (!libraryComponent?.libraryComponents?.length) return;
      let componentsFromLibComponent;
      const [firstElement] = libraryComponent.libraryComponents;
      if (hasPreviewPdf(firstElement?.type)) {
        componentsFromLibComponent = [
          {
            type: BlockTypes.pdf,
            state: {
              data: {
                parentId: firstElement.id,
                width: libraryComponent.width,
                representationState: item.content,
                title: firstElement.title,
                size: firstElement.size,
                urlFile: firstElement.urlFile,
              },
            },
          },
        ];
      } else {
        const [firstComponent] = libraryComponent.libraryComponents;
        componentsFromLibComponent = firstComponent?.components
          ? firstComponent.components.map((component) => {
            const content = {};
            if (component.type === BlockTypes.page) {
              const [libraryPage] = component.libraryComponents;
              content.title = (libraryPage && sanitizeToLoad(libraryPage.title))
                  || 'The functional logic has been updated.';
              content.nestedItemId = libraryPage && libraryPage.id;
            } else if (
              component.type === BlockTypes.approveButton
                || component.type === BlockTypes.actionButton
                || component.type === BlockTypes.shortText
            ) {
              content.state = JSON.parse(sanitizeToLoad(component.content));
              // } else if (item.type === 'webSite') {
            } else if (
              component.type === BlockTypes.webSite
                || component.type === BlockTypes.lineSeparator
            ) {
              const tmpContent = parseContentForUsage(component.content);
              content.state = tmpContent || '';
            } else if (component.type === BlockTypes.pdf) {
              content.state = {
                data: {
                  parentId: component.libraryComponents[0].id,
                  width: component.width,
                  representationState: component.content,
                  title: component.libraryComponents[0].title,
                  size: component.libraryComponents[0].size,
                  urlFile: component.libraryComponents[0].urlFile,
                },
              };
            } else if ((component.type === BlockTypes.image) && component.libraryComponents[0]) {
              content.urlFile = component.libraryComponents[0]?.urlFile;
              content.size = component.libraryComponents[0]?.size;
            } else if (component.type === BlockTypes.embed) {
              content.innerHtml = sanitizeToLoad(component.innerHtml);
              try {
                content.state = JSON.parse(sanitizeToLoad(component?.content));
              } catch {
                content.state = { data: { text: '', isPreview: true } };
              }
            } else {
              content.state = draftDataConvertToLoad(component.content);
            }

            return {
              id: component.id,
              parentId: firstComponent.id,
              type: component.type,
              innerHtml: component.innerHtml,
              position: component.position,
              isHidden: component.isHidden,
              width: component.width,
              ...content,
            };
          })
          : [];
      }

      return {
        position: item.position,
        id: item.id,
        type: 'component',
        contentType: item.libraryComponents[0].type,
        isHidden: item.isHidden,
        nestedItemId: libraryComponent.libraryComponents[0].id,
        components: componentsFromLibComponent,
      };
    });
  }

  if (uploadedContent.components && !!uploadedContent.components.length) {
    blocks = uploadedContent.components
      .map((item) => {
        const content = {};
        if (item.libraryComponents[0]?.movedToTrash) return;
        const [libComponent] = item?.libraryComponents;

        if (item.type === BlockTypes.embed) {
          content.innerHtml = sanitizeToLoad(item.innerHtml);
          if (libComponent?.id) {
            content.nestedLibComponent = libComponent?.id;
            content.state = {
              data: {
                text: sanitizeToLoad(libComponent?.linkUrl),
                isPreview: true,
              },
            };
          } else {
            try {
              content.state = JSON.parse(sanitizeToLoad(item?.content));
            } catch {
              content.state = { data: { text: '', isPreview: true } };
            }
          }
        } else if (item.type === 'page') {
          content.title = sanitizeToLoad(libComponent?.title) || 'default title';
          content.innerHtml = sanitizeToLoad(item.innerHtml);
          content.nestedItemId = libComponent?.id;
        } else if (
          item.type === BlockTypes.approveButton
          || item.type === BlockTypes.actionButton
          || item.type === BlockTypes.shortText
        ) {
          try {
            content.state = JSON.parse(sanitizeToLoad(item.content));
          } catch (err) {
            content.state = '';
          }
        } else if (item.type === BlockTypes.component) {
          if (!item.libraryComponents[0]) return;
          if (!item.libraryComponents[0].components) return;

          content.nestedItemId = item.libraryComponents[0]?.id;
          content.components = item.libraryComponents[0].components.map(
            (component) => {
              const contentObj = {};
              if (component.type === BlockTypes.page) {
                const [libraryPage] = component.libraryComponents;
                contentObj.title = sanitizeToLoad(libraryPage?.title) || 'default title';
                contentObj.innerHtml = innerHtmlPageLink(contentObj.title);
              } else if (
                component.type === BlockTypes.approveButton
                || component.type === BlockTypes.actionButton
                || component.type === BlockTypes.shortText
              ) {
                contentObj.state = JSON.parse(
                  sanitizeToLoad(component.content),
                );
              } else if (
                component.type === BlockTypes.webSite
                || component.type === BlockTypes.lineSeparator
              ) {
                const tmpContent = parseContentForUsage(component.content);
                contentObj.state = tmpContent || '';
              } else if (
                item.type === BlockTypes.googleEmbed
                || item.type === BlockTypes.dropboxEmbed
              ) {
                contentObj.foreignLastModifiedDate = component.foreignLastModifiedDate;
                contentObj.foreignLastModifiedUserName = component.foreignLastModifiedUserName;
              } else if (component.type === BlockTypes.pdf) {
                contentObj.state = {
                  data: {
                    nestedItemId: component.id,
                    width: component.width || 700,
                    representationState: component.content,
                    title: component.title,
                    size: component.size || 700,
                    urlFile: component.urlFile,
                  },
                };
              } else if (
                component.type === BlockTypes.image
                && component.libraryComponents
                && component.libraryComponents[0]?.urlFile
              ) {
                contentObj.state = draftDataConvertToLoad(
                  contentImageTemplate(
                    component.libraryComponents[0].urlSmallImage
                      || component.libraryComponents[0]?.urlFile,
                  ),
                );
                contentObj.urlFile = component.libraryComponents[0].urlFile;
                contentObj.urlSmallImage = component.libraryComponents[0].urlSmallImage;
                contentObj.urlVerySmallImage = component.libraryComponents[0].urlVerySmallImage;
              } else if (component.type === BlockTypes.embed) {
                contentObj.innerHtml = sanitizeToLoad(component.innerHtml);
                try {
                  contentObj.state = JSON.parse(sanitizeToLoad(component?.content));
                } catch {
                  contentObj.state = { data: { text: '', isPreview: true } };
                }
              } else {
                contentObj.state = draftDataConvertToLoad(component.content);
              }
              return {
                id: component.id,
                type: component.type,
                innerHtml: sanitizeToLoad(component.innerHtml),
                position: component.position,
                isHidden: component.isHidden,
                width: component.width,
                ...contentObj,
              };
            },
          );
        } else if (item.type === BlockTypes.lineSeparator) {
          content.state = parseContentForUsage(item.content);
        } else if (item.type === BlockTypes.webSite) {
          const [innerLibraryComponent] = item.libraryComponents;
          content.state = innerLibraryComponent
            ? {
              data: {
                url: innerLibraryComponent?.linkUrl,
                images: innerLibraryComponent?.urlFile,
                title: innerLibraryComponent?.title,
                description: innerLibraryComponent?.description,
              },
            }
            : parseContentForUsage(item.content);
          content.innerHtml = content.state?.data
            && innerHtmlWebSiteTemplate(content.state?.data);
        } else if (
          item.type === BlockTypes.googleEmbed
          || item.type === BlockTypes.dropboxEmbed
        ) {
          const [innerLibraryComponent] = item.libraryComponents;
          content.nestedItemId = innerLibraryComponent?.id;
          content.state = innerLibraryComponent
            ? {
              data: {
                url: innerLibraryComponent?.linkUrl,
                images: innerLibraryComponent?.urlFile,
                title: innerLibraryComponent?.title,
                description: innerLibraryComponent?.description,
                linkedAccountId: innerLibraryComponent.linkedAccountId,
                foreignLastModifiedDate:
                    innerLibraryComponent.foreignLastModifiedDate,
                foreignLastModifiedUserName:
                    innerLibraryComponent.foreignLastModifiedUserName,
              },
            }
            : parseContentForUsage(item.content);
          if (item.type === BlockTypes.googleEmbed) {
            content.innerHtml = content.state?.data
              && innerHtmlGoogleEmbedTemplate(content.state?.data);
          } else {
            content.innerHtml = content.state?.data
              && innerHtmlDropboxEmbedTemplate(content.state?.data);
          }
        } else if (item.type === BlockTypes.pdf) {
          const sourceComponent = item.libraryComponents[0];
          if (!sourceComponent) return;
          content.type = BlockTypes.pdf;
          content.nestedItemId = sourceComponent.id;
          content.contentType = BlockTypes.pdf;
          content.state = {
            urlSmallImage: sourceComponent.urlSmallImage,
            data: {
              nestedItemId: sourceComponent.id,
              width: item.width || 700,
              representationState: item.content,
              title: sourceComponent.title,
              size: sourceComponent.size || 700,
              urlFile: sourceComponent.urlFile,
            },
          };
        } else if (hasPreviewPdf(item?.type)) {
          const sourceComponent = item.libraryComponents[0];
          if (!sourceComponent) return;
          content.type = BlockTypes.pdf;
          content.nestedItemId = sourceComponent.id;
          content.contentType = BlockTypes.pdf;
          content.state = {
            data: {
              nestedItemId: sourceComponent.id,
              width: sourceComponent.width || 700,
              representationState: item.content,
              pdfPreviewUrl: sourceComponent.pdfPreviewUrl,
              title: sourceComponent.title,
              size: sourceComponent.size || 700,
              urlFile: sourceComponent.urlFile,
            },
          };
        } else if (item.type === BlockTypes.image) {
          const libraryComponent = item.libraryComponents[0];
          if (libraryComponent) {
            content.urlFile = libraryComponent.urlFile;
            content.urlSmallImage = libraryComponent.urlSmallImage;
            content.urlVerySmallImage = libraryComponent.urlVerySmallImage;
          }

          mutateImageBlockState(content, item);
        } else {
          content.state = draftDataConvertToLoad(item.content);
        }
        return {
          id: item.id,
          type: item.type,
          innerHtml: sanitizeToLoad(item.innerHtml),
          position: item.position,
          isHidden: item.isHidden,
          width: item.width,
          ...content,
        };
      })
      .filter((i) => i);
  }

  const libraryCompBlocksF = libraryCompBlocks.filter((i) => i);
  return [...blocks, ...libraryCompBlocksF].sort(
    (first, last) => first.position - last.position,
  );
};

export const placeSelector = (place) => {
  switch (place) {
    case 'text': {
      return LibCompSieveTypes.Components;
    }
    case 'pages': {
      return LibCompSieveTypes.Pages;
    }
    case 'library': {
      return LibCompSieveTypesTest.Pages;
    }
    default:
      return LibCompSieveTypes.All;
  }
};

const TYPES_TO_OPTIONS_MAP = {
  playlists: '[LibComponent]Playlists',
  smartfiles: '[LibComponent]Playlists',
  pages: '[LibComponent]Pages',
  components: '[LibComponent]Components',
  media: '[LibComponent]Media',
  links: '[LibComponent]Links',
  pdf: '[LibComponent]PDF',
  files: '[LibComponent]Files',
};
export const getActiveOptionSliderPosition = (
  activeOption,
  availableOptions,
  sieveOptionsWidths,
  isHasVerticalLine,
) => {
  let leftOffset = 0;
  let currentItemIndex;
  const ITEMS_GAP = 6;
  const FIRST_ITEM_GAP = 16;
  const DEFAULT_ITEM_WIDTH = 6;

  Object.values(availableOptions || {}).find((option, index) => {
    if (
      option === activeOption
      || TYPES_TO_OPTIONS_MAP[option] === activeOption
    ) {
      currentItemIndex = index;
      return true;
    }
    // accumulate left offset
    if (isHasVerticalLine && index === 0) {
      leftOffset = sieveOptionsWidths[index] + FIRST_ITEM_GAP + leftOffset;
    } else {
      leftOffset = sieveOptionsWidths[index] + ITEMS_GAP + leftOffset;
    }
    return false;
  });

  return {
    left: leftOffset,
    width: sieveOptionsWidths[currentItemIndex] || DEFAULT_ITEM_WIDTH,
  };
};

export const rectangleCreator = (color) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00033 17.3327C13.6027 17.3327 17.3337 13.6017 17.3337 8.99935C17.3337 4.39698 13.6027 0.666016 9.00033 0.666016C4.39795 0.666016 0.666992 4.39698 0.666992 8.99935C0.666992 13.6017 4.39795 17.3327 9.00033 17.3327Z"
      fill={`#${color}`}
      stroke="#A2A7B2"
      strokeOpacity="0.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const updateLibraryStateWithComponents = (
  state,
  id,
  inCollectionId,
  changedComponents,
  lastModifiedDate,
) => {
  if (inCollectionId) {
    return {
      ...state,
      collections: {
        ...state.collections,
        [inCollectionId]: {
          ...state.collections[inCollectionId],
          nestedPage: {
            ...state.collections[inCollectionId].nestedPage,
            [id]: {
              ...state.collections[inCollectionId].nestedPage[id],
              lastModifiedDate,
              components: changedComponents,
            },
          },
        },
      },
    };
  }
  return {
    ...state,
    components: {
      ...state.components,
      [id]: {
        ...state.components[id],
        lastModifiedDate,
        components: changedComponents,
      },
    },
  };
};

export const resolveActiveSieveOption = (path) => {
  switch (path.split('/')[1]) {
    case 'playlist': {
      return PlaylistSideBarLibrarySieveTypes.PlaylistPdf;
    }
    case 'edit_page': {
      return LibCompSieveTypes.All;
    }
    case 'libraryPage': {
      return LibCompSieveTypes.All;
    }
    case 'channels': {
      return ChannelsSieveTypes.ChannelsAll;
    }
    default: {
      return LibCompSieveTypes.All;
    }
  }
};

export const resolvePath = (path) => path.split('/')[1];

export const resolveSieveOptions = (path) => {
  switch (path.split('/')[1]) {
    case 'playlist': {
      return LibCompSieveTypesSideBar;
    }
    case 'maker': {
      return LibCompSieveTypesSideBar;
    }
    case 'shared_playlist_co_edit': {
      return LibCompSieveTypesSideBar;
    }
    case 'edit_page': {
      return LibCompSieveTypesSideBar;
    }
    case 'libraryPage': {
      return LibCompSieveTypesSideBar;
    }
    case 'channels': {
      return ChannelsSieveTypes;
    }
    default: {
      return LibCompSieveTypes;
    }
  }
};

// === Helper Mutation: delete all LibraryComponents with components and LibraryComponents (not tested for nested LC)
// const singleLCtoDelete = (outerItem) => {
//   const hash = outerItem.id.replaceAll('-','')
//   const lCDeletion = `DeleteLC${hash}: DeleteLibraryComponent(
//       id: "${outerItem.id}"
//     ) {id}`
//   const deleteAllComponents = outerItem?.components.reduce((acc,item, index) => {
//     acc += `
//     deleteComponent${hash}${index+1}: DeleteComponent(
//       id: "${item.id}"
//       ) {id}
//       `
//     return acc
//   },'')
//   const deleteAllLibraryComponents = outerItem?.libraryComponents.reduce((acc,item, index) => {
//     acc += `
//     DeleteLibraryComponent${hash}${index+1}: DeleteLibraryComponent(
//       id: "${item.id}"
//     ) {id}
//     `
//     acc += `
//     DeleteLibraryComponentRelation${index+1}: DeleteLibraryComponentInLibraryComponentRelation(
//       id: "${item?.hasLibraryComponent?.id}"
//     ) {id}
//     `
//     return acc
//   },'')
//   return `
//     ${deleteAllComponents}
//     ${deleteAllLibraryComponents}
//     ${lCDeletion}
//   `
// }

export const getLengthOfSelectedText = (localState) => {
  const currentSelection = localState.getSelection();
  const isCollapsed = currentSelection.isCollapsed();
  let length = 0;

  if (!isCollapsed) {
    const currentContent = localState.getCurrentContent();
    const startKey = currentSelection.getStartKey();
    const endKey = currentSelection.getEndKey();
    const startBlock = currentContent.getBlockForKey(startKey);
    const isStartAndEndBlockAreTheSame = startKey === endKey;
    const startBlockTextLength = startBlock.getLength();
    const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
    const endSelectedTextLength = currentSelection.getEndOffset();
    const keyAfterEnd = currentContent.getKeyAfter(endKey);

    if (isStartAndEndBlockAreTheSame) {
      length
        += currentSelection.getEndOffset() - currentSelection.getStartOffset();
    } else {
      let currentKey = startKey;

      while (currentKey && currentKey !== keyAfterEnd) {
        if (currentKey === startKey) {
          length += startSelectedTextLength + 1;
        } else if (currentKey === endKey) {
          length += endSelectedTextLength;
        } else {
          length += currentContent.getBlockForKey(currentKey).getLength() + 1;
        }

        currentKey = currentContent.getKeyAfter(currentKey);
      }
    }
  }
  return length;
};
export const pastedHandlerHelper = (
  localState,
  type,
  componentId,
  text,
  html,
  characterLimit,
) => {
  const imagePlugin = createImagePlugin();
  const currentContent = localState?.getCurrentContent();
  const currentContentLength = currentContent?.getPlainText('').length;
  const selectedTextLength = localState && getLengthOfSelectedText(localState);

  if (
    text
    && text.startsWith('<iframe')
    && text.endsWith('</iframe>')
    && !(
      localState?.getCurrentContent && localState?.getCurrentContent().hasText()
    )
  ) {
    const newBlock = {
      text,
      isPreview: false,
    };
    return { type: 'CREATE_AS_EMBED', payload: newBlock };
  }

  // eslint-disable-next-line no-useless-escape
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  const newBlock = [];
  const startValue = '<div name="description" content=';
  const endValue = 'data-end="endo-copy';
  if (html) {
    if (html.indexOf(startValue) + 1) {
      const endIndex = html.indexOf(endValue);

      const a = html
        .substring(
          html.indexOf(startValue) + 1 + startValue.length,
          endIndex - 2,
        )
        .replaceAll('$@quote123123', '"')
        .replaceAll('$@quote0123123', "'");
      const newRowBlock = JSON.parse(a);
      const newBlocks = newRowBlock.map((item, index) => {
        const content = {
          id: uuid(),
          innerHtml: sanitizeToLoad(item.innerHtml),
        };
        if (
          index === 0
          && localState?.getCurrentContent
          && !localState?.getCurrentContent().hasText()
        ) {
          content.id = componentId;
        }
        if (item.type === BlockTypes.component) {
          content.libraryComponentId = item.libraryComponentId;
        } else if (item.type === BlockTypes.page) {
          content.pageId = item.pageId;
          content.title = item.title;
        } else if (
          item.type === BlockTypes.approveButton
          || item.type === BlockTypes.actionButton
          || item.type === BlockTypes.shortText
        ) {
          try {
            content.state = JSON.parse(
              sanitizeToLoad(item.content || item.state),
            );
          } catch (err) {
            content.state = '';
          }
        } else if (
          item.type === BlockTypes.webSite
          || item.type === BlockTypes.lineSeparator
        ) {
          content.state = parseContentForUsage(item.content || item.state);
        } else {
          content.state = draftDataConvertToLoad(item.content || item.state);
        }

        return {
          type: item.type,
          innerHtml: item.innerHtml,
          position: item.position,
          isHidden: item.isHidden,
          width: item.width,
          ...content,
        };
      });
      return { type: 'ADD_BLOCKS', payload: newBlocks };
    }
    const blocksFromHTMLArra = convertFromHTML(html.replace('figure', 'div'));
    const state1 = ContentState.createFromBlockArray(
      blocksFromHTMLArra.contentBlocks,
      blocksFromHTMLArra.entityMap,
    );
    const raw = convertToRaw(state1);
    const { entityMap } = raw;
    const blocks1 = raw.blocks;
    const newText = {};
    blocks1.forEach((block, index1) => {
      if (block.text?.includes('📷')) {
        newText[index1] = entityMap[block.entityRanges[0].key];
      }
    });
    if (blocksFromHTMLArra.contentBlocks.length) {
      blocksFromHTMLArra.contentBlocks.forEach((sampleMarkup, index1) => {
        if (
          sampleMarkup.text
            .replace(/\u21b5/g, '')
            .replace(/↵/g, '')
            .trim()
        ) {
          const isReplace = localState?.getCurrentContent
            && !localState?.getCurrentContent().hasText()
            && index1 === 0;
          const compId = isReplace ? componentId : uuid();

          let newComId;
          if (sampleMarkup.text === '📷') {
            const newUrl = newText[index1].data.src;
            newBlock.push({
              newUrl,
              id: compId,
              type: BlockTypes.image,
              innerHtml: null,
              isNew: true,
            });
            return;
          }

          let contentState = ContentState.createFromBlockArray(
            [sampleMarkup],
            blocksFromHTMLArra.entityMap,
          );


          if (newText[index1]?.data?.src) {
            const newUrl = newText[index1].data.src;
            newBlock.push({
              state: { newUrl },
              newUrl,
              id: compId,
              type: BlockTypes.image,
              innerHtml: null,
            });

            newComId = uuid();
            let text = contentState.getPlainText();
            text = text.replace('📷', '');
            contentState = ContentState.createFromText(text);
          }

          let blockType = DefaultDraftBlockRenderMap1[sampleMarkup.type] || 'text';
          if (type.includes('ordered-list-item')) {
            blockType += 0;
          }

          newBlock.push({
            state: EditorState.createWithContent(contentState),
            id: newComId || compId,
            type: blockType,
          });
        }
      });
      // if (newBlock.length > 1 || newBlock.type !== 'image') {
      //   return { type: 'ADD_BLOCKS', payload: newBlock };
      // }

      if (newBlock.length > 1) {
        return { type: 'ADD_BLOCKS', payload: newBlock };
      }
      if (newBlock.length === 1 && newBlock.type === BlockTypes.image) {
        return { type: 'CREATE_UPLOAD', payload: newBlock };
      }
    }
  }

  if (
    text
    && text.match(urlRegex)
    && !(
      localState?.getCurrentContent && localState?.getCurrentContent().hasText()
    )
  ) {
    // update(createLinkContent(text));
    // setIsOptionVisible(true);
    return { type: 'CREATE_AS_LINK', payload: newBlock };
  }
  if (text) {
    // max ten characters
    if (
      currentContentLength + text?.length - selectedTextLength
      > characterLimit
    ) {
      return { type: 'TEXT_IS_TOO_LONG' };
    }
    return { type: 'CREATE_AS_TEXT', payload: newBlock };
  }
  // return 'not-handled';
  return false;
};


const userFilter = (type) => (item, userRoleFilter) => !(userRoleFilter && userRoleFilter !== item[type]);

const statusFilter = (item, userStatusFilter) => userFilter('status')(item, userStatusFilter);
const roleFilter = (item, userRoleFilter) => userFilter('role')(item, userRoleFilter);

export const compositeUserFilter = (
  item,
  roleFilterValue,
  statusFilterValue,
  localFilter,
) => {
  return (
    (item?.title || item?.name)
    && (item?.title || item?.name)
      ?.toLocaleLowerCase()
      ?.includes(localFilter?.toLocaleLowerCase())
    && roleFilter(item, roleFilterValue)
    && statusFilter(item, statusFilterValue, 'status')
  );
};

export const copyInBuffer = (ref, dispatch) => {
  const text = ref.current.value || ref.current.innerText;

  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(() => {
      dispatch(
        actionShowMessage({
          type: MessageType.CopyUrl,
          isUrl: !!ref?.current?.value,
        }),
      );
    });
  } else if (navigator.userAgent.match(/ipad|iphone/i)) {
    const textArea = ref.current;
    const range = document.createRange();
    range.selectNodeContents(textArea);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    textArea.setSelectionRange(0, 999999);
    document.execCommand('copy');
  } else {
    ref.current.select();
    document.execCommand('copy');
    dispatch(actionShowMessage({ type: MessageType.CopyUrl }));
  }
};

export function createMarkup(html) {
  return { __html: sanitizeHtml(html) };
}

export const filterTitles = (item, filter) => item.title.toLowerCase().includes(filter.toLowerCase());

// export const isForwardStepAvailable = (history)=>{
//   return !!history.location.state?.forwardStepTrace?.length
// }
export const traceStackPush = (
  url,
  historyObject,
  attributes = {},
  dispatch,
) => {
  // navigate forward with regular history.push api but add a CustomHistoryState
  // ---
  // history.location.state - is a mutable object - provided by useHistory hook of react-router-dom
  // CustomHistoryState flow keeps data between the regular history.push
  // ---
  // history.location.state: {
  //    traceStack: [{
  //      first: boolean, - true if the current step is first in line, false vice versa
  //      step: {}, - extra data: previous selectors, titles, ids ... etc.
  //      departureUrl: '', - exact url the current step is created on
  //    }],
  //    localSelector: '', - some cases has this outside "step object" but it meant not to be here initially
  //  }
  dispatch(actionAddPayloadUnifyHistory(attributes));
  historyObject.push(url);
};

export const createPageCommon = (
  dispatch,
  history,
  isFavorite,
  dispatchCallback,
  pageTitle = '',
  tags = [],
) => {
  const newPageId = uuidv4();
  const newPage = {};
  newPage[newPageId] = {
    title: '',
    id: newPageId,
    itemType: 'component',
    movedToTrash: false,
    isFavorite,
    position: 1000000,
    type: 'page',
    inPage: false,
    tags,
    lastModifiedDate: Math.floor(Date.now() / 1000),
    createDate: Math.floor(Date.now() / 1000),
    description: '',
    credits: '',
    components: [],
  };

  const newStep = { step: {} };
  newStep.step.from = history?.location?.pathname;
  newStep.step.title = pageTitle;
  newStep.departureUrl = history?.location?.pathname;

  dispatchCallback(newPage);
  dispatch(actionAddPayloadUnifyHistory(newStep));
  history.push(`/libraryPage/${newPageId}`, { isNewPage: true });
};

export const collectLines = (used) => {
  const result = [];
  const items = [
    ...Object.values(used.playlists),
    ...Object.values(used.pages),
    ...Object.values(used.components),
  ];
  const channelsItems = [...Object.values(used.channels)];

  if (items.length >= channelsItems.length) {
    items.forEach((item, index) => {
      result.push({ item, channel: channelsItems[index], key: item.id });
    });
  } else {
    channelsItems.forEach((item, index) => {
      result.push({ item: items[index], channel: item, key: item.id });
    });
  }

  return result;
};

const randomSymbol = () => ALLOWED_HEX_SYMBOLS[Math.round(Math.random() * 15)];

export const generateRandomHexColor = () => {
  return `#${randomSymbol()}${randomSymbol()}${randomSymbol()}${randomSymbol()}${randomSymbol()}${randomSymbol()}`;
};

export const getLastItemPosition = (items) => {
  if (!items || typeof items !== 'object') return null;
  return Object.values(items).sort((a, b) => a.position - b.position)[
    Object.values(items).length - 1
  ]?.position;
};

export const filterItems = (tag, search) => tag.title.trim().toLowerCase().includes(search.trim().toLowerCase());
export const filterDC = (tag, search) => tag.name.trim().toLowerCase().includes(search.trim().toLowerCase());
export const filterDCTitle = (tag, search) => tag.title.trim().toLowerCase().includes(search.trim().toLowerCase());
export const sortTagsByLastModified = (items) => items.sort((a, b) => b.lastModifiedDate - a.lastModifiedDate);

export const sortTagsCounterSelected = (filter, items) => {
  function compareFn(a, b) {
    if (filter) {
      if (filter[a.id] && !filter[b.id]) {
        return -1;
      }
      if (filter[b.id] && !filter[a.id]) {
        return 1;
      }
    }

    if (a.itemsCounter < b.itemsCounter) {
      return 1;
    }
    if (a.itemsCounter > b.itemsCounter) {
      return -1;
    }
    // a must be equal to b
    return 0;
  }

  return items.sort(compareFn);
};

export const REQUEST_STATUS = {
  IDLE: 'IDLE',
  IS_IDLE: (status) => status === REQUEST_STATUS.IDLE,
  SENT: 'SENT',
  IS_SENT: (status) => status === REQUEST_STATUS.SENT,
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const Portal = (props) => {
  const modalRoot = document.getElementById('modal');
  return createPortal(props.children, modalRoot);
};

export const createNewPlaylistAndJumpIntoIt = ({
  history,
  isAdmin,
  dispatch,
  isChannel = false,
  addToChannel,
  addToUser,
}) => {
  dispatch({ type: 'IS_DOWNLOAD_USER', payload: true });
  const attributes = {};
  const idPlaylist = uuidv4();
  attributes.from = history.location.pathname;

  const payload = {
    idPlaylist,
    addToChannel,
    addToUser,
    goToPlaylists: () => {
      history.push(`/maker/${idPlaylist}/edit`, {
        isNewPlaylist: true,
        isChannel,
      });
    },
    goBack: () => history.goBack(),
  };
  if (isAdmin) payload.addToChannel = 'help';

  dispatch({ type: EditPlaylist.Create, payload });
  dispatch(actionPageWillDownload());
};
export const createPage = ({ selectorType, dispatch, tag, history }) => {
  const localTags = [];
  if (selectorType === 'tag' && !!tag) {
    localTags.push({ ...tag });
  }
  createPageCommon(
    dispatch,
    history,
    selectorType === 'favorites',
    (newPage) => dispatch(
      actionAddPageIntoLibrary(newPage, selectorType === 'favorites', true),
    ),
    '',
    localTags,
  );
};


export const getPlaylistType = ({
  path,
  forMeta = false,
  channelSieveOption,
  queryFromPageType = null,
}) => {
  if (!path) throw new Error('path incorrect');
  if (path.includes('playlists/published')) {
    return PLAYLIST_TYPES.published;
  }
  if (path.includes('channel')) {
    if (channelSieveOption === ChannelsSieveTypes.ChannelsFavorite) {
      return PLAYLIST_TYPES.channelFavorite;
    }
    return PLAYLIST_TYPES.channel;
  }
  if (path.includes('playlists/sharedByMe')) {
    return PLAYLIST_TYPES.sharedByMe;
  }
  if (path.includes('playlists/shared')) {
    return PLAYLIST_TYPES.sharedToMe;
  }
  if (path.includes('playlists/favorites')) {
    return PLAYLIST_TYPES.favorite;
  }
  if (path.includes('playlists/drafts')) {
    return PLAYLIST_TYPES.draft;
  }
  if (path.includes('/help')) {
    return PLAYLIST_TYPES.help;
  }
  if (queryFromPageType) {
    return queryFromPageType;
  }
};

export const getAvailability = (playlistType, item) => {
  if (
    playlistType === PLAYLIST_TYPES.sharedToMe
    || playlistType === PLAYLIST_TYPES.sharedByMe
  ) {
    return createTimeRange(item.sharedAvailableFrom, item.sharedAvailableTo);
  }
  return createTimeRange(item.availableFrom, item.availableTo);
};

export const calcCurrentTitle = (currentPath) => {
  if (currentPath.includes('/content/playlists')) return 'playlists';

  if (currentPath.includes('/content/smartfiles/recently_viewed')) return 'recently_viewed';
  if (currentPath.includes('/content/smartfiles/favorites')) return 'favorites';
  if (currentPath.includes('/content/smartfiles/drafts')) return 'drafts';
  if (currentPath.includes('/content/smartfiles/sharedByMe')) return 'sharedByMe';
  if (currentPath.includes('/content/smartfiles/shared')) return 'sharedByMe';
  if (currentPath.includes('/content/smartfiles')) return 'smartfiles';
  if (currentPath.includes('/board/')) return 'board';
  if (currentPath.includes('/inbox')) return 'inbox';
  if (currentPath.includes('/outbox')) return 'outbox';
  if (currentPath.includes('/content/library/')) return 'library';
  if (currentPath.includes('/content/pages/')) return 'pages';
  if (currentPath.includes('/content/components/')) return 'components';
  if (currentPath.includes('/content/pdf/')) return 'pdf';
  if (currentPath.includes('/content/media/')) return 'media';
  if (currentPath.includes('/content/links/')) return 'links';
  if (currentPath.includes('/content/files/')) return 'files';
  if (currentPath.includes('/contacts')) return 'contacts';
  if (currentPath.includes('/help')) return 'help';
  return '';
};

export const universalPlaylistSize = (linkPages, isTotal) => {
  if (isTotal) {
    const size = linkPages?.reduce((acc, cur) => {
      if (cur.libraryComponent?.size) {
        acc += cur.libraryComponent?.size;
        return acc;
      }
      const text = getItemName(cur);
      const textBlob = new Blob([text]);
      acc += textBlob.size;
      return acc;
    }, 0);
    return `${((size) / (1024 * 1024)).toFixed(2)} MB`;
  }
  const text = getItemName(linkPages);
  const textBlob = new Blob([text]);
  return `${((linkPages?.libraryComponent?.size ?? textBlob?.size) / (1024 * 1024)).toFixed(2)} MB`;
};

export const calcIfWholeSmartfileDownloadable = (items) => items && !!items.filter((i) => (
  i.type
    && i.libraryComponent?.type !== 'sharedPage'
    && i.libraryComponent?.type !== 'page'
    && i.libraryComponent?.type !== 'text/html'
    && i.libraryComponent?.type !== 'embed_component'
    && !i.isRemixLocked),
).length;

export const calcSelectorForSmartfiles = (selector) => {
  if (selector.includes('shared')) return 'shared';
  if (selector.includes('drafts')) return 'drafts';
  if (selector.includes('recent')) return 'recent';
  if (selector.includes('favorites')) return 'favorites';
  return selector;
};

export const calcItemsProgress = (linkPages) => {
  return linkPages?.reduce((acc, cur) => {
    if (cur.libraryComponent?.parsedStatusByAiR === aiProcessingStatusEnum.ITEM_PENDING_IN_AI) {
      acc.pending += 1;
    }
    if (cur.libraryComponent?.parsedStatusByAiR === aiProcessingStatusEnum.ITEM_PROCESSING_IN_AI) {
      acc.processing += 1;
    }
    if (cur.libraryComponent?.parsedStatusByAiR === aiProcessingStatusEnum.ITEM_PROCESSED_IN_AI) {
      acc.completed += 1;
    }
    if (cur.libraryComponent?.parsedStatusByAiR === aiProcessingStatusEnum.ITEM_PROCESSING_ERROR) {
      acc.error += 1;
    }
    if (cur.libraryComponent?.parsedStatusByAiR) {
      acc.all += 1;
    }
    return acc;
  }, { pending: 0, processing: 0, completed: 0, error: 0, all: 0 });
};

export const calcTimeForSpeed = (numElements) => {
  const baseTime = 0.2;
  const timePerElement = 0.1;
  return (Math.sqrt(numElements) * timePerElement) + baseTime;
};


export const calcNextItemToRedirect = (allItems, activeItemId, isChannel, myChannels) => {
  const items = Object.values(allItems).filter(ch => !ch.isUnpin)
    .sort((a, b) => a.position - b.position);
  const currentIndex = items.findIndex(e => e.id === activeItemId);
  if (currentIndex >= 0 && items.length > 0) {
    const path = isChannel ? '/channel/' : '/content/users_smartfiles/';
    if (currentIndex + 1 < items.length) {
      return { path, id: items[currentIndex + 1].id };
    }
    if ((currentIndex + 1 >= items.length) && items.length > 1) {
      return { path, id: items[currentIndex - 1].id };
    }
    if (items.length === 1) {
      const visibleChannels = Object.values(myChannels).filter(ch => !ch.isUnpin)
        .sort((a, b) => a.position - b.position);
      if (!isChannel && visibleChannels.length) {
        const channelId = Object.values(visibleChannels)[0].id;
        return { path: '/channel/', id: channelId };
      }
      return { path: '/content/smartfiles/drafts' };
    }
  }
};

export const calcCoEditorColor = (singleUserShareState, itemOwnerId, sfOwnerId) => {
  const currentIndex = Object.values(singleUserShareState || {})
    .filter(elem => elem?.id === sfOwnerId)?.[0]?.coEditorIndex;
  if (itemOwnerId && itemOwnerId === sfOwnerId) return '#FFC72C';
  if (!currentIndex) return '#A1A1A1';
  let color;
  switch (currentIndex % 4) {
    case 1:
      color = '#FF7A00';
      break;
    case 2:
      color = '#1FAE09';
      break;
    case 3:
      color = '#00A5FF';
      break;
    case 0:
      color = '#CC00FF';
      break;
    default:
      color = '#A1A1A1';
      break;
  }
  return color;
};

export const calcCoEditorItemDate = (createDate) => {
  const date = new Date(createDate ? createDate * 1000 : Date.now());
  const dateForCalc = new Date(createDate ? createDate * 1000 : Date.now());
  const msSinceMidnight = Date.now() - dateForCalc.setHours(0, 0, 0, 0);
  const msDay = 24 * 60 * 60 * 1000;
  const time = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  if (msSinceMidnight < msDay) {
    return `${i18n.t('TodayT')}, ${time}`;
  }
  if (msSinceMidnight < 2 * msDay) {
    return `${i18n.t('yesterdayT')}, ${time}`;
  }
  const year = new Date(Date.now()).getFullYear() !== date.getFullYear() ? date.getFullYear() : '';
  return `${date.getDate()} ${date.toLocaleString(i18n.language, { month: 'long' })} ${year}, ${time}`;
};

export const removeFromDom = (itemID) => {
  const draggableItem = document.getElementById(itemID);
  if (draggableItem) document.body.removeChild(draggableItem);
};

function mergeOrderedLists(children) {
  const mergedChildren = [];
  let currentList = null;
  for (const child of children) {
    if (child.type === 'list' && child.listType === 'number') {
      if (currentList && currentList.type === 'list' && currentList.listType === 'number') {
        // Adding items to the current list
        const updatedChildren = child.children.map((item, index)=>{
          return{...item, value:(currentList?.children?.length || 0) + index + 1 }
        })
        currentList.children.push(...updatedChildren);
      } else {
        // Starting a new list
        const updatedChildren = child.children.map((item, index)=>{
          return{...item, value: item.value || 1 }
        })
        currentList = {
          ...child,
          children: [...updatedChildren],
          listType : "number",
          tag : "ol",
          format : "",
          start : 1,
          type : "list",
          version : 1,
          value:1
        };
        mergedChildren.push(currentList);
      }
    } else {
      // If the current item is not a list, reset the current list
      currentList = null;
      mergedChildren.push(child);
    }
  }

  return mergedChildren;
}

// function for testing the compliance of nodes
function findDifferences(obj1, obj2, path = '') {
  if (typeof obj1 !== typeof obj2) {
    console.log(`Difference at ${path}: ${obj1} vs ${obj2}`);
    return;
  }

  if (typeof obj1 === 'object' && obj1 !== null && obj2 !== null) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    const allKeys = new Set([...keys1, ...keys2]);

    for (const key of allKeys) {
      const newPath = path ? `${path}.${key}` : key;
      if (keys1.includes(key) && keys2.includes(key)) {
        findDifferences(obj1[key], obj2[key], newPath);
      } else {
        console.log(`Difference at ${newPath}: ${obj1[key]} vs ${obj2[key]}`);
      }
    }
  } else if (obj1 !== obj2) {
    console.log(`Difference at ${path}: ${obj1} vs ${obj2}`);
  }
}

function trimEmptyNode(node, index, array) {
  if (node.nodeType === Node.TEXT_NODE) {
    const text = node.textContent.trim();
    if (!text) return null; // Skip empty text nodes
  }
  if (node.nodeType === Node.ELEMENT_NODE) {
    const text = node.textContent.trim();

    // Maybe need
    // if (!text)return null;
    // if ('P' === node.tagName  || 'EM' === node.tagName || 'BR' === node.tagName) {

    if ('P' === node.tagName) {

      if (!text) return null; // Skip empty text lines
    }
    if('BR' === node.tagName){
      const prevNode = array[index-1]
      if(prevNode?.tagName !=='BR' ) return null
      //
      // const nextNode = array[index-1]
      // if(nextNode?.tagName ==='BR' ) return null
      //
    }


  }
  return node
}

function parseNode(node) {
  if (node.nodeType === Node.TEXT_NODE) {
    const text = node.textContent.trim();
    return {
      detail: 0,
      format: 0,
      mode: 'normal',
      style: '',
      text,
      type: 'text',
      version: 1,
    };
  }

  if (node.nodeType === Node.ELEMENT_NODE) {
    const children = Array.from(node.childNodes)
      .map(trimEmptyNode)
      .filter(Boolean)
      .map(parseNode)
      .filter(Boolean);

    const type = {
      P: 'paragraph',
      // UL: 'listitem',
      // OL: 'ol',
      LI: 'listitem',
    }[node.tagName] || 'paragraph';

    const isTextFormatting = ['B', 'I', 'U', 'STRONG'].includes(node.tagName);
    if (isTextFormatting) {
      const format = {
        B: 1, // Bold
        STRONG: 1, // Strong == Bold
        I: 2, // Italic
        U: 4, // Underline
      }[node.tagName];

      children.forEach((child) => {
        if (child.type === 'text') {
          child.format |= format;
        }
      });

    }
    let sub = {}
    if ('OL' === node.tagName) {
      const format = 1
      sub = {
        listType :  "number",
        start : 1,
        tag : "ol",
        type : "list",

      }
      children.forEach((child) => {
        if (child.type === 'text') {
          child.format |= format;
        }
      });

    }

    return {
      children,
      direction: null,
      format: '',
      indent: 0,
      type,
      version: 1,
      textFormat: 0,
      ...sub,
    };
  }

  return null;
}


export const rawAiTextToLexicalState = (rawText) => {
  return generateRTBStateFromHtml(sanitizeHtml(parseMarkdown(sanitizeToLoad(rawText))))
}

export const generateRTBStateFromHtml = (html) => {
  console.log(html)
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');


  const parsedChildren = Array.from(doc.body.childNodes)
    .map(trimEmptyNode)
    .filter(Boolean)
    .map(parseNode)
    .filter(Boolean);

  const wrappedChildren = parsedChildren.map((child) => {
    if (child.type === 'text') {
      return {
        children: [child],
        direction: null,
        format: '',
        indent: 0,
        type: 'paragraph',
        version: 1,
        textFormat: 0,
      };
    }
    return child;
  });
  const mergedChildren = mergeOrderedLists(wrappedChildren);

  return {
    root: {
      children: mergedChildren,
      direction: null,
      format: '',
      indent: 0,
      type: 'root',
      version: 1,
    },
  };
}

export const generateSimpleRTBState = (text) => ({
  root: {
    children: [
      {
        children: [
          {
            detail: 0,
            format: 0,
            mode: 'normal',
            style: '',
            text,
            type: 'text',
            version: 1,
          },
        ],
        direction: null,
        format: '',
        indent: 0,
        type: 'paragraph',
        version: 1,
        textFormat: 0,
      },
    ],
    direction: null,
    format: '',
    indent: 0,
    type: 'root',
    version: 1,
  },
});

export const calcAIProcessingStatus = (aiProcessingStatus) => {
  if (aiProcessingStatus === aiProcessingStatusEnum.ITEM_PROCESSING_ERROR) {
    return (
      {
        text: 'Processing error.',
        borderColor: '#F8CFC9',
        backgroundColor: '#F3B0A5',
        svgColor: '#D23119',
        isError: true,
      });
  }
  if (aiProcessingStatus === aiProcessingStatusEnum.ITEM_PROCESSING_IN_AI) {
    return (
      {
        text: 'Processing source data.',
        borderColor: '#F3D4A5',
        backgroundColor: '#F8E5C9',
        svgColor: '#D28819',
      });
  }
  if (aiProcessingStatus === aiProcessingStatusEnum.ITEM_PROCESSED_IN_AI) {
    return (
      {
        text: 'Successfully processed.',
        borderColor: '#A5F3B7',
        backgroundColor: '#C9F8CC',
        svgColor: '#23A942',
      });
  }
  return {
    text: 'Successfully processed.',
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    svgColor: 'transparent',
  };
};

async function readAllDirectoryEntries(directoryReader) {
  const entries = [];
  let readEntries = await readEntriesPromise(directoryReader);
  while (readEntries.length > 0) {
    entries.push(...readEntries);
    readEntries = await readEntriesPromise(directoryReader);
  }
  return entries;
}

async function readEntriesPromise(directoryReader) {
  try {
    return await new Promise((resolve, reject) => {
      directoryReader.readEntries(resolve, reject);
    });
  } catch (err) {
    console.log(err);
  }
}

export const getAllFileEntries = async (dataTransferItemList, folderNotification) => {
  const fileEntries = [];
  const queue = [];
  for (let i = 0; i < dataTransferItemList.length; i++) {
    queue.push(dataTransferItemList[i].webkitGetAsEntry());
  }
  while (queue.length > 0) {
    const entry = queue.shift();
    if (entry?.isFile) {
      fileEntries.push(entry);
    } else if (entry?.isDirectory) {
      queue.push(...await readAllDirectoryEntries(entry.createReader()));
      folderNotification(entry.name);
    }
  }
  return fileEntries;
};

export const getFile = async (fileEntry) => {
  try {
    return new Promise((resolve, reject) => fileEntry.file(resolve, reject));
  } catch (err) {
    console.log(err);
  }
};

export const calcHasIndention = (linkPages, index) => {
  return linkPages.reduce((acc, cur, i) => {
    if (i < index && cur.type === 'elementTitle') acc = true;
    return acc;
  }, false);
};
