import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  ExpandedCardControlsWrapper,
  ExpandedCardMainControlsWrapper,
} from './styled';
import PlaylistTitleInput from '../../MakerCommon/PlaylistTitleInput';
import { SHARED_PLAYLIST_URL, stopPropagation } from '../../../../utils/constants';
import { actionPageWillDownload } from '../../../../redux/currentPage/action';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import SupportAction from '../../../../redux/support/types';
import { actionAddPayloadUnifyHistory } from '../../../../redux/history/actions';
import OptionsLinkPage from '../../../../entities/OptionsLinkPage';
import ThreeDotsDropDownMaker from '../../ThreeDotsDropDownForMaker';

const TopPartControls = ({ isThreeDotsActive, threeDotRef, setIsThreeDotsActive, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { channelId = null, id: sharedToWebPlaylistID, type } = useParams();
  const isForSharedToWeb = history.location.pathname.startsWith(`/${SHARED_PLAYLIST_URL}/`);

  const currentPage = useSelector((state) => state.currentPage);
  const { isContentEditable, socketId } = currentPage;
  const { id: userId } = useSelector((state) => state.user);
  const { unseenInChannel, unseenPlaylistManagerMapInAll } = useSelector(
    (state) => state.support,
  );


  const isChannels = !!channelId;
  const isOwner = userId === props.linkPage?.owner?.id;
  const isUpload = props.linkPage?.libraryComponent?.isUpload;

  const newGoToPlaylist = (event, index) => {
    event.stopPropagation();
    !props.isForSharedToWeb && dispatch(actionPageWillDownload());
    // const isForSharedToWeb = currentPage.status === 'shared';
    if (!isChannels && unseenPlaylistManagerMapInAll[currentPage.wrapperId]) {
      dispatch(
        actionCreator(SupportAction.DeleteUnseenPlaylistR, {
          playlistManagerId: currentPage.wrapperId,
        }),
      );
    }
    if (
      isChannels
      && unseenInChannel[channelId]
      && unseenInChannel[channelId][currentPage.playlistManagerId]
    ) {
      dispatch(
        actionCreator(SupportAction.DeleteUnseenPlaylistInChannel, {
          channelId,
          playlistId: currentPage.id,
          playlistManagerId: currentPage.playlistManagerId,
        }),
      );
    }

    // if (specifiedAction === GO_TO_PLAYER_VIEW) {
    if (isForSharedToWeb) {
      dispatch(
        actionAddPayloadUnifyHistory({ sharedToWebID: sharedToWebPlaylistID }),
      );
      history.push(`/shared_player/shared/${sharedToWebPlaylistID}/${index}`);
      return;
    }
    const defaultChannel = channelId
      || (isChannels
        && currentPage?.usedInChannels
        && Object.values(currentPage?.usedInChannels)[0]?.id)
      || 'preview';
    const exPlaylistId = type === 'shared' ? sharedToWebPlaylistID : currentPage.id;
    const firstChannelId = type === 'publish' ? defaultChannel : type === 'shared' ? 'shared' : 'preview';
    history.push(`/player/${firstChannelId}/${exPlaylistId}/${index}`);
  };

  const toggleThreeDotMenu = (e) => {
    stopPropagation(e);
    setIsThreeDotsActive(!isThreeDotsActive);
  };
  return (
    <ExpandedCardMainControlsWrapper>
      {/* <ExpandedCardTitleWrapper/> */}
      <PlaylistTitleInput
        title={props.title}
        renameRef={props.renameRef}
        linkPageId={props.linkPageId}
        mode="textarea"
        setRenameMode={props.setRenameMode}
        renameMode={props.renameMode}
        isExpandedView
        isText={props.isText}
        isDisabled={!isOwner}
      />
    </ExpandedCardMainControlsWrapper>
  );
};

export default TopPartControls;
