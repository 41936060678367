import React, { useState, useEffect, useCallback, useRef, RefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import EditPlaylist from '../../redux/playlists/types';
import { ReactComponent as SearchSvg } from '../../images/2023/svg/ai_search.svg';
import { ReactComponent as SummarizeSvg } from '../../images/2023/svg/ai_summarize.svg';
import { ReactComponent as ExpandedSvg } from '../../images/icons/icon_18/expand.svg';
import { ReactComponent as MinimizeSvg } from '../../images/icons/icon_18/minimize.svg';
import { ReactComponent as BatchSvg } from '../../images/icons/batch_actions.svg';
import { ReactComponent as AISvg } from '../../images/icons/sparkles.svg';
import { ReactComponent as DownloadSvg } from '../../images/icons/batchActions/download_all.svg';
import { ReactComponent as ShareSvg } from '../../images/icons/batchActions/share_all.svg';
import { actionCreator } from '../../shared/redux/actionHelper';
import { CurrentPage } from '../../redux/currentPage/types';
import { IRootState } from '../../redux/reducers';
import { openModalType, empty, aiProcessingStatusEnum, MessageType, DATA_PARENTS, Group, emptyCallback } from '../../utils/constants';
import useComponentVisibleMouseDown from '../../utils/hooks/useComponentVisibleMouseDown';
import Tooltip from '../../shared/Tooltips/Tooltip';
import { actionOpenModal } from '../../redux/user/action';
import Playlist from '../../redux/playlists/types';
import { calcItemName } from '../../utils/ai/helper';
import {
  getLinkPagePosition,
  sanitizeToLoad,
  sanitizeHtml,
  universalPlaylistSize,
  parseMarkdown,
  calcIfWholeSmartfileDownloadable, rawAiTextToLexicalState,
} from '../../utils/helpers';
import {
  Result, ImageShell, CustomButton,
  PlaylistAIInput, InputAreaWrapper,
  ExpandedFunctionalityWrapper,
  AITitle, Header, SingleResultInfo,
  ExpandButton, HeaderButtonsWrapper,
  AIButton, SectionTitle, ShareButton,
  ButtonsWrapper, ButtonWrapper, Summary,
  SummaryExample, ScrollableItemWrapper,
  ResultsWrapper, LoadingWrapper,
  AISpinnerWrapper, Spinner,
  ProcessingType, EstimatedTime,
  InputWrapper, FeatureTag,
  FirstSource,
  Textarea, SharedIndicator,
  SelectedItemsCounter,
  VerticalDivider,
  SingleItem,
} from './ai_styled';
import { actionChangeLinkPageType, actionEmptyAddLinkPageToPlaylist, actionSaveSummarize } from '../../redux/currentPage/action';
import { actionChangeTextElementBlockReduxMaker, actionUnPublishSPlaylistS } from '../../redux/playlists/action';
import { smartFileItemType } from '../../shared/smartFile/constant';
import { actionShowMessage } from '../../redux/support/action';
import { PlaylistUI } from '../../utils/UIHelpers/PlaylistBuilderElements/componentPickers';
import { BatchActionsPopup } from './BatchActionsPopup';
import { axiosAbortarium } from '../../utils/axiosAbortarium';

interface ILinkPage {
  id: string,
  name: string,
  title: string,
  isTextSavedToVectorDb: boolean | null,
  owner: {
    id: string,
  };
  isRemixLocked: boolean;
  textComponent: {
    id: string,
  }
  libraryComponent?: {
    title: string,
    size: string,
    parsedStatusByAi: string | null,
    summaryAI: string | null,
    type: string,
  }
  matchText?: string[],
}

interface ICurrentProcessingItems {
  processing: number,
  pending: number,
  all: number,
  completed: number,
  error: number,
}
interface ISingleSearhResult {
  singleClickHandler: (e: React.MouseEvent, index: number) => void
  index: number,
  linkPage: ILinkPage,
}

const SingleSearhResult: React.FC<ISingleSearhResult> = ({ singleClickHandler, index, linkPage }) => {
  const { t } = useTranslation();

  const calculatedSizeMB = useMemo(() => universalPlaylistSize(linkPage, false), [linkPage]);
  return (
    <Result onClick={(e) => singleClickHandler(e, index)}>
      <ImageShell>
        {PlaylistUI.createPreviewComponent(
          linkPage.libraryComponent?.type,
          linkPage.libraryComponent || {},
          linkPage?.id,
          '',
        )}
      </ImageShell>
      <SingleResultInfo>
        <>
          {calcItemName(linkPage, t)}
          •
          {calculatedSizeMB}
        </>
        <p>
          {!!linkPage.matchText?.[0]?.length && <b>...{linkPage.matchText?.[0]}</b>}
          {linkPage.matchText?.[1]}...
        </p>
      </SingleResultInfo>
    </Result>
  );
};

const AIInput: React.FC<{
  currentProcessingItems: ICurrentProcessingItems,
  deleteHandler: (e: React.MouseEvent<Element | MouseEvent> | React.KeyboardEvent) => void,
}> = ({ currentProcessingItems, deleteHandler }) => {
  const { t } = useTranslation();
  const isProcessing = useMemo(() => (
    currentProcessingItems?.pending > 0 || currentProcessingItems?.processing > 0
  ), [currentProcessingItems]);

  const AIAgentsTitle = {
    summarize: t('summarizeT'),
    search: t('searchT'),
  };
  const itemsRef: RefObject<HTMLDivElement> | null = useRef(null);
  const inputRef: RefObject<HTMLTextAreaElement> | null = useRef(null);

  const [isExpandedForPreview, setExpandedForPreview] = useState(false);
  const [isCanManyDownload, setIsCanManyDownload] = useState(false);
  const [componentRef, isExpanded, setIsExpanded] = useComponentVisibleMouseDown(
    false, DATA_PARENTS.checkItem, () => setExpandedForPreview(false),
  );

  const [batchActionsPopupRef, isPopupOpened, setPopupOpened] = useComponentVisibleMouseDown(
    false, DATA_PARENTS.checkItem, () => setExpandedForPreview(false),
  );
  const {
    linkPages = [],
    manager,
    currentSummarize,
    selectedItems,
    aiProcessing,
    id,
    wrapperId,
    title,
    currentProcessingTasks,
    singleUserShareState,
    userID,
  } = useSelector((state: IRootState) => state.currentPage);
  const user = useSelector((state: IRootState) => state.user || empty);
  const dispatch = useDispatch();
  const isHasShare = !!Object.values(singleUserShareState).length;
  const sources: ILinkPage[] = useMemo(() => {
    return linkPages.filter((elem: ILinkPage) => elem.isTextSavedToVectorDb
      || elem.libraryComponent?.parsedStatusByAi === aiProcessingStatusEnum.ITEM_PROCESSED_IN_AI);
  }, [linkPages]);

  const [selectedAIFeatureForSend, setSelectedAIFeatureForSend] = useState<'summarize' | 'search' | null>(null);
  const [userTask, setUserTask] = useState<string>('');

  const [searchData, setSearchData] = useState<{
    ids: string[], matchesObj: { [key: string]: string[] }
  }>({ ids: [], matchesObj: {} });
  const [data, setData] = useState<ILinkPage[]>([]);

  const handleReset = () => {
    dispatch(actionSaveSummarize(''));
    setSearchData({ ids: [], matchesObj: {} });
    setData([]);
    setUserTask('');
    setSelectedAIFeatureForSend(null);
  };

  const handleSelectAgent = (agent: 'summarize' | 'search') => {
    handleReset();
    const newAgent = selectedAIFeatureForSend === agent ? null : agent;
    setSelectedAIFeatureForSend(newAgent);
    inputRef.current?.focus();
  };

  const placeholder = useMemo(() => {
    switch (selectedAIFeatureForSend) {
      case 'summarize':
        return t('expandPromptT');
      case 'search':
        return t('refinePromptT');
      default:
        return t('enterPromptT');
    }
  }, [selectedAIFeatureForSend]);

  const createLinkPage = (destinationIndex: number, targetIndex?: number) => {
    const newPos = getLinkPagePosition(destinationIndex, linkPages, targetIndex);
    const linkPageId = uuid();
    const pageData = {
      id: linkPageId,
      position: newPos,
      owner: {
        id: user.id,
        last_name: user.last_name,
        avatarUrlVerySmall: user.avatarUrlVerySmall,
        first_name: user.first_name,
      },
      mainUserInterface: {
        id: uuid(),
      },
    };
    dispatch(actionEmptyAddLinkPageToPlaylist(id, pageData));
    return pageData;
  };

  const setSmartText = () => {
    setSearchData({ ids: [], matchesObj: {} });
    const createdItem = createLinkPage(linkPages.length - 1);
    const newState = rawAiTextToLexicalState(currentSummarize);
    const updateCBaction = (blockId: string) => {
      return (
        actionChangeTextElementBlockReduxMaker(
          blockId,
          newState,
          '',
          id,
          createdItem.id,
          smartFileItemType.title,
        ));
    };
    dispatch(actionChangeLinkPageType(createdItem.id, smartFileItemType.richText, id, null, updateCBaction));
    handleReset();
    setExpandedForPreview(false);
    setIsExpanded(false);
  };

  const exampleSummarize = useMemo(() => {
    return sources.reduce((acc: string, cur: ILinkPage) => {
      if (acc) return acc;
      return cur?.libraryComponent?.summaryAI ?? '';
    }, '');
  }, [sources]);

  const singleClickHandler = useCallback((event: React.MouseEvent, index: number) => {
    const elemPosition = itemsRef?.current?.getBoundingClientRect();
    dispatch(
      actionOpenModal(openModalType.UPVPreview,
        { id, index, mode: 'UPV', elemPosition, linkPages: data }),
    );
  }, [id, dispatch, data]);

  const handleSummarize = () => {
    dispatch(
      actionCreator(CurrentPage.Summarize, {
        linkPages: Object.keys(selectedItems),
        userTask,
      }),
    );
  };

  const handleSend = () => {
    if (!sources.length && !!selectedAIFeatureForSend) {
      dispatch(
        actionShowMessage({
          type: MessageType.NoAIFiles,
        }),
      );
      return;
    }
    if (selectedAIFeatureForSend === 'search' && aiProcessing.isLoading) {
      dispatch(
        actionCreator(CurrentPage.requestAISearch, {
          isStop: true,
        }),
      );
      return;
    }
    if (aiProcessing.isLoading && currentProcessingTasks) {
      dispatch(
        actionCreator(CurrentPage.StopAIProcessingInSmartfile, {
          taskIds: currentProcessingTasks,
        }),
      );
      return;
    }
    const linkPagesForSend = (linkPages as ILinkPage[]).map(elem => elem.id);
    if (!aiProcessing.isLoading && selectedAIFeatureForSend === 'summarize') {
      handleSummarize();
      return;
    }
    if (selectedAIFeatureForSend === 'search') {
      setSearchData({ ids: [], matchesObj: {} });
      setData([]);
      dispatch(
        actionCreator(CurrentPage.requestAISearch, {
          userTask,
          playlistManagerId: manager?.id ?? wrapperId,
          selectedIds: Object.keys(selectedItems),
          setSearchData,
        }),
      );
      return;
    }
    if (!aiProcessing.isLoading) {
      dispatch(
        actionCreator(CurrentPage.executeAITask, {
          linkPages: linkPagesForSend,
          userTask,
          playlistId: id,
        }),
      );
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSend();
    }
  };

  useEffect(() => {
    if (data.length !== searchData.ids.length && searchData.ids.length > 0) {
      const linkPagesMap = linkPages?.reduce((acc: {[key:string]:ILinkPage}, cur: ILinkPage) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
      const responseLinkPages = searchData.ids.map(mapId => {
        const linkPage = linkPagesMap[mapId];
        return { ...linkPage, matchText: searchData.matchesObj[mapId] };
      });
      setData(responseLinkPages);
    }
  }, [searchData.ids, searchData.matchesObj, linkPages, data.length]);

  useEffect(() => {
    if (aiProcessing.isLoading) {
      setIsExpanded(true);
    }
  }, [aiProcessing.isLoading, setIsExpanded]);
  const adjustHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = '5px';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  };

  const exampleSummaryMemo = useMemo(() => {
    return sanitizeHtml(parseMarkdown(sanitizeToLoad(exampleSummarize)));
  }, [exampleSummarize]);

  const summaryMemo = useMemo(() => {
    return sanitizeHtml(parseMarkdown(sanitizeToLoad(currentSummarize)));
  }, [currentSummarize]);

  useEffect(() => {
    setIsCanManyDownload(calcIfWholeSmartfileDownloadable(linkPages));
  }, [linkPages]);

  const handleOpenPopup = () => {
    if (isExpanded) setIsExpanded(false);
    setPopupOpened(true);
  };

  const enableShare = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    if (isHasShare) {
      dispatch(
        actionOpenModal(openModalType.ConfirmModalUniversal, {
          title: t('unshareUpT'),
          subject: t('unshareThisSmartFileQuestionT'),
          description: t('unshareDescriptionT'),
          confirm: () => {
            dispatch(actionUnPublishSPlaylistS(id, wrapperId));
            dispatch(
              actionShowMessage({
                type: MessageType.Regular,
                text: t('smartFileUnsharedT'),
              }),
            );
          },
          cancelText: t('cancelUpT'),
          okText: t('unshareUpT'),
          type: 'unshare',
        }),
      );
      return;
    }
    dispatch(
      actionOpenModal(openModalType.Share, { itemType: 'playlist', isMakerStyles: true }),
    );
  }, [dispatch, id, isHasShare, t, wrapperId]);

  const downloadItemClickHandler = useCallback((
    e: React.MouseEvent,
    filesArray: any,
    isLinkAdded: boolean,
    smartfileLink: string) => {
    e.stopPropagation();
    if (!linkPages?.length || !isCanManyDownload) {
      return;
    }
    dispatch(
      actionShowMessage({
        type: MessageType.DownloadWholePlaylist,
        itemName: 'playlist',
        mode: Group.processing,
        id,
      }),
    );
    axiosAbortarium.generateNew(id);
    dispatch(
      actionCreator(EditPlaylist.DownloadAllDownloadablePlaylistItems, {
        playlistId: id,
        playlistTitle: title,
        filesArray,
        isLinkAdded,
        smartfileLink,
      }),
    );
  }, [linkPages?.length, isCanManyDownload, id, title]);

  const handleDownloadSelected = () => {
    const itemsForDownload = linkPages.reduce((acc: string[], cur: any) => {
      const isItemOwner = user.userId === cur?.owner?.id;
      if (selectedItems[cur.id] && (isItemOwner || !cur.isRemixLocked)) {
        acc.push(cur.id);
      }
      return acc;
    }, []);
    if (itemsForDownload.length) {
      dispatch(
        actionCreator(EditPlaylist.DownloadAllDownloadablePlaylistItems, {
          playlistId: id,
          playlistTitle: title,
          filesArray: itemsForDownload,
          isLinkAdded: false,
        }),
      );
    }
  };

  const handleDownloadAll = () => {
    if (!!linkPages?.length && isCanManyDownload) {
      dispatch(actionOpenModal(openModalType.DownloadPlaylistModal, { download: downloadItemClickHandler }));
    }
  };

  const allowedItems = useMemo(() => {
    return linkPages?.reduce((acc: { forSF: string[], forLibrary: string[] }, cur: ILinkPage) => {
      if (selectedItems[cur.id]
        && (!cur.isRemixLocked || cur.owner?.id === userID)
      ) {
        acc.forSF.push(cur.id);
        if (!cur.textComponent?.id) {
          acc.forLibrary.push(cur.id);
        }
      }
      return acc;
    }, { forLibrary: [], forSF: [] });
  }, [linkPages, selectedItems, userID]);

  const onAddItemsToLibraryClickHandler = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    if (allowedItems.forLibrary.length) {
      dispatch(actionCreator(Playlist.AddAllDownloadableItemsIntoLibrary, { items: allowedItems.forLibrary }));
    }
  };

  const isLocked = useMemo(() => {
    const lockedItems = linkPages.filter((elem: ILinkPage) => elem.isRemixLocked && selectedItems[elem.id]);
    return lockedItems.length === Object.keys(selectedItems).length;
  }, [linkPages, selectedItems]);

  const lockHandler = () => {
    dispatch(
      actionCreator(CurrentPage.UpdateRemixState, {
        linkPagesIdsMap: selectedItems,
        playlistId: id,
        value: !isLocked,
      }),
    );
  };
  return (
    <PlaylistAIInput ref={componentRef} isExpanded={isExpanded} isExpandedForPreview={isExpandedForPreview}>
      {isPopupOpened && (
        <BatchActionsPopup
          popupRef={batchActionsPopupRef}
          selectedItems={selectedItems}
          setClosed={setPopupOpened}
          downloadHandler={handleDownloadSelected}
          summarizeHandler={handleSummarize}
          duplicateHandler={emptyCallback}
          lockHandler={lockHandler}
          deleteHandler={deleteHandler}
          addToLibraryHandler={onAddItemsToLibraryClickHandler}
          isLocked={isLocked}
        />
      )}
      {isExpanded && (
        <ExpandedFunctionalityWrapper data-parent={DATA_PARENTS.checkItem}>
          <Header data-parent={DATA_PARENTS.checkItem}>
            <AITitle>
              {currentSummarize && (
                <ExpandButton onClick={() => setExpandedForPreview(!isExpandedForPreview)}>
                  {isExpandedForPreview ? <MinimizeSvg /> : <ExpandedSvg /> }
                </ExpandButton>
              )}
              <h3 data-parent={DATA_PARENTS.checkItem}>Alan AI</h3>
            </AITitle>
            <HeaderButtonsWrapper>
              {(!!data.length || currentSummarize) && (
                <AIButton
                  onClick={handleReset}
                  isReset
                  data-parent={DATA_PARENTS.checkItem}
                >
                    {t('resetNameT')}
                </AIButton>
              )}
              {currentSummarize && (
                <AIButton
                  onClick={setSmartText}
                  isSave
                  data-parent={DATA_PARENTS.checkItem}
                >
                    {t('addToSmartFileT')}
                </AIButton>
              )}
            </HeaderButtonsWrapper>
          </Header>
          {!aiProcessing.isLoading && !isProcessing && (
            <>
              {(!data.length || aiProcessing.isLoading) && !currentSummarize
                && <SectionTitle data-parent={DATA_PARENTS.checkItem}>{t('whatAICanDoT')}</SectionTitle>}
              {!!currentSummarize && <SectionTitle data-parent={DATA_PARENTS.checkItem}>{t('summaryT')}</SectionTitle>}
              {!!currentSummarize && <b data-parent={DATA_PARENTS.checkItem}>{t('overviewT')}<br /></b>}
              {(!data.length || aiProcessing.isLoading) && !currentSummarize && (
                <>
                  <ButtonsWrapper data-parent={DATA_PARENTS.checkItem}>
                    <Tooltip text={t('searchTooltipT')} direction="down" place="ai_input">
                      <AIButton
                        isSearch
                        onClick={() => handleSelectAgent('search')}
                        data-parent={DATA_PARENTS.checkItem}
                      >
                        <SearchSvg data-parent={DATA_PARENTS.checkItem} />
                        {t('searchT')}
                      </AIButton>
                    </Tooltip>
                    <Tooltip text={t('summarizeTooltipT')} direction="down" place="ai_input">
                      <AIButton
                        onClick={() => handleSelectAgent('summarize')}
                        data-parent={DATA_PARENTS.checkItem}
                      >
                        <SummarizeSvg data-parent={DATA_PARENTS.checkItem} />
                        {t('summarizeT')}
                      </AIButton>
                    </Tooltip>
                  </ButtonsWrapper>
                  {exampleSummarize && (
                    <SummaryExample data-parent={DATA_PARENTS.checkItem}>
                      <SectionTitle data-parent={DATA_PARENTS.checkItem}>{`${title} ${t('summaryT')}`}</SectionTitle>
                      <ScrollableItemWrapper>
                        <div data-parent={DATA_PARENTS.checkItem}>
                          <FirstSource><b> {t('exampleSummaryNoteT')}: </b></FirstSource>
                          <Summary dangerouslySetInnerHTML={{ __html: exampleSummaryMemo }} />
                        </div>
                      </ScrollableItemWrapper>
                    </SummaryExample>
                  )}
                </>
              )}
              <ScrollableItemWrapper>
                <Summary dangerouslySetInnerHTML={{ __html: summaryMemo }} />
              </ScrollableItemWrapper>
              {data.length > 0 && (
                <ResultsWrapper data-parent={DATA_PARENTS.checkItem}>
                  <SectionTitle data-parent={DATA_PARENTS.checkItem}>{t('topResultT')}</SectionTitle>
                  <ScrollableItemWrapper>
                    <SingleSearhResult
                      data-parent={DATA_PARENTS.checkItem}
                      singleClickHandler={singleClickHandler}
                      linkPage={data[0]}
                      index={0}
                    />
                    {data.length > 1 && <SectionTitle>{t('otherResultsT')}</SectionTitle>}
                    {data.slice(1).map((linkPage, index) => (
                      <SingleSearhResult
                        data-parent={DATA_PARENTS.checkItem}
                        singleClickHandler={singleClickHandler}
                        linkPage={linkPage}
                        index={index + 1}
                      />
                    ))}
                  </ScrollableItemWrapper>
                </ResultsWrapper>
              )}
            </>
          )}
          {(aiProcessing.isLoading || isProcessing) && (
            <LoadingWrapper data-parent={DATA_PARENTS.checkItem}>
              <AISpinnerWrapper>
                <Spinner data-parent={DATA_PARENTS.checkItem} />
              </AISpinnerWrapper>
              <ProcessingType data-parent={DATA_PARENTS.checkItem}>
                {aiProcessing.type === 'summarize' && t('summarizingT')}
                {aiProcessing.type === 'ai_search' && t('searchingT')}
                {(aiProcessing.type === 'ai_send' || isProcessing) && t('processingT')}
              </ProcessingType>
              <EstimatedTime data-parent={DATA_PARENTS.checkItem}>
                {(aiProcessing.type === 'ai_send' || isProcessing)
                  ? `${t('completedT')} ${currentProcessingItems?.completed} ${t('ofT')} ${currentProcessingItems?.all}`
                  : t('estimatedTimeT')}
              </EstimatedTime>
            </LoadingWrapper>
          )}
        </ExpandedFunctionalityWrapper>
      )}
      <InputAreaWrapper data-parent={DATA_PARENTS.checkItem}>
        <CustomButton onClick={handleOpenPopup} isActive={!!isPopupOpened} data-parent={DATA_PARENTS.checkItem}>
          {Object.keys(selectedItems).length > 0 ? (
            <SelectedItemsCounter data-parent={DATA_PARENTS.checkItem}>
              <span>{Object.keys(selectedItems).length}</span>
            </SelectedItemsCounter>
          ) : <BatchSvg data-parent={DATA_PARENTS.checkItem} />}
          {t('batchActionsT')}
        </CustomButton>
        <VerticalDivider data-parent={DATA_PARENTS.checkItem} />
        <InputWrapper
          onClick={() => setIsExpanded(true)}
          data-parent={DATA_PARENTS.checkItem}
        >
          {selectedAIFeatureForSend && (
            <FeatureTag height={inputRef.current?.clientHeight}>
              <p>{AIAgentsTitle[selectedAIFeatureForSend]}:</p>
            </FeatureTag>
          )}
          <AISvg />
          <Textarea
            onKeyDown={handleKeyDown}
            onClick={() => setPopupOpened(false)}
            ref={inputRef}
            data-parent={DATA_PARENTS.checkItem}
            value={userTask}
            onInput={adjustHeight}
            onChange={(e) => setUserTask(e.target.value)}
            placeholder={placeholder}
          />
        </InputWrapper>
        <VerticalDivider />
        <ButtonWrapper onClick={handleDownloadAll}>
          <CustomButton>
            <DownloadSvg />
            {t('downloadT')}
          </CustomButton>
        </ButtonWrapper>
        <CustomButton onClick={enableShare} isBrand>
          <ShareSvg />
          {t('shareT')}
          {isHasShare && <SharedIndicator />}
        </CustomButton>
      </InputAreaWrapper>
    </PlaylistAIInput>
  );
};

export default AIInput;
