import styled from 'styled-components/macro';
import { keyframes } from 'styled-components';

export const PlaylistAIInput = styled.div<{ isExpanded: string | boolean, isExpandedForPreview: boolean }>`
  z-index: 8;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 40px;
  bottom:0;
  flex-direction: column;
  border-radius: 16px 16px 0 0;
  background: #fff;
  display: flex;
  padding: 16px;
  justify-content: space-between;
  transition: height, 0.2s;
  box-shadow: 0 24px 48px -8px rgba(66, 66, 66, 0.16), 0 4px 12px 4px rgba(66, 66, 66, 0.12);
   ${({ isExpanded }) => (isExpanded
    ? 'height: fit-content;'
    : '')}
  ${({ isExpandedForPreview }) => (isExpandedForPreview
    ? 'width: 935px; height: 552px;'
    : 'width: 702px; height: fit-content;')}
  width: 935px; 
`;
export const ExpandButton = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #E8E9EA;
  background: #FFF;
  box-shadow: 0 24px 48px -8px rgba(66, 66, 66, 0.12), 0 2px 8px 0 rgba(66, 66, 66, 0.04);
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const AITitle = styled.div`
  display: flex; 
  align-items: center;
  gap: 10px;
  & h3{
    font-family: Roboto, sans-serif;
  }
`;

export const ProcessingType = styled.div`
  color: #2B2C2E;
  font-family: RobotoFlex, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.25px;
`;
export const EstimatedTime = styled.div`
color: #4B5563;
  font-family: RobotoFlex, sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: -0.05px;
`;

export const Summary = styled.div`
  white-space: normal;
  margin: 0;
`;
export const FirstSource = styled.div`
  padding-bottom: 10px;
`;

export const VerticalDivider = styled.div`
  background-color: #F2F2F3;
  width: 1px;
  height: 40px;
  margin: 0 12px;
`;

export const ScrollableItemWrapper = styled.div`
  overflow-y: scroll;
  max-height: 150px;
  white-space: normal;
  b{
      font-weight: 600;
      font-family: "Roboto", sans-serif;
  }
  p{
      margin: 15px 0;
      line-height: 15px;
  }
  ul{
      margin: 0;
      line-height: 15px;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 4px;
    background-color: #dbdbdb;
    -webkit-box-shadow: inset 0 0 6px #dbdbdb;
    margin-right: 2px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
    background: #dbdbdb;
  }
`;

export const SendButton = styled.button <{ disabled?: boolean }>`
  display: flex;
  width: 44px;
  min-width: 44px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  height: 44px;
  justify-content: center;
  align-items: center;
  background: ${({ disabled }) => (disabled ? '#A2A5AA' : '#000')};
  border-radius: 50%;
  border: none;
  outline: none;
  margin-left: 15px;
  cursor: pointer;

  .ai_stop {
    display: none;
  }
    
  &:hover {
    .ai_stop {
      display: flex;
    }
    
    .ai_processing {
      display: none;
    }
  }
`;

export const ExpandedFunctionalityWrapper = styled.div`
  padding: 12px;
`;

export const SummaryExample = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 22px;
  margin-bottom: 10px;
`;
export const CustomButton = styled.div<{ isActive?: boolean, isBrand?: boolean }>`
  padding: 10px 20px;
  height: 43px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid transparent;
  border-radius: 40px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #514E55;
  ${({ isActive }) => (isActive
    ? `
    background: #F3DDA5;
    border: 1px solid #E7C15F;
    ` : '')};
  &:hover:not([disabled]) {
    background: #E6AC190F;
    color: #E7C15F;

    span {
      color: #514E55;
    }
    svg path {
      fill: #E7C15F;
    }
  }

  &:focus:not([disabled]) {
    background: #E6AC190F;
    border-color: #CA971614;
    color: #E7C15F;
    svg path {
      fill: #E7C15F;
    }
  }

  &:active:not([disabled]) {
    background: #E6AC190F;
    color: #F8BA12;
    svg path {
      fill: #E7C15F;
    }
  }
  ${({ isBrand }) => (isBrand
    ? `
    background-color: #FFC72C;
    color: #fff;
    svg path {
      fill: #fff;
    }
    &:hover:not([disabled]) {
      background: #E7C15F;
      color: #fff;

      svg path {
        fill: #fff;
      }
    }

    &:focus:not([disabled]) {
      background: #E6AC190F;
      border-color: #CA971614;
      color: #fff;

      svg path {
        fill: #fff;
      }
    }

    &:active:not([disabled]) {
      background: #FFC72C;
      color: #fff;
      outline: 1.5px solid #F3DDA5;
      svg path {
        fill: #fff;
      }
    }
      ` : '')};
`;

export const InputAreaWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
`;
export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  border-radius: 32px;
  padding: 12px 4px 12px 24px;
  borer-radius: 50%;
  
  &:focus-within {
    background: #F5F5F5;
  }

  svg {
    width: 28px;
    height: 28px;
  }
`;

export const SectionTitle = styled.p`
  color: #A2A5AA;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.05px;
  text-transform: uppercase;
`;
export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 15px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    max-width: 147px;
`;
export const ShareButton = styled.div`
  display: flex;
  height: 40px;
  position: relative;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background-color: #FFC72C;
  cursor: pointer;
`;

export const FeatureTag = styled.div <{ height?: number }>`
  padding-bottom: 2px;
  ${({ height }) => (height ? `
    height: ${height}px;
    display: flex;
    align-items: flex-end;
    
    ` : '')}

  p {
    color: #A2A5AA;
    margin: 0;
    font-weight: 700;
    font-family: "RobotoFlex", sans-serif;
    font-size: 14px;
    line-height: 24px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const HeaderButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
export const AIButton = styled.button <{ isSearch?: boolean, isReset?: boolean, isSave?: boolean }>`
  border-radius: 8px;
  height: 40px;
  padding: 2px 24px;
  align-items: center;
  align-content: center;
  display: flex;
  gap: 8px;
  outline: none;
  border: none;
  color: #FAFAFA;
  cursor: pointer;
  ${({ isSearch }) => (isSearch
    ? `
      background: radial-gradient(42.37% 709.09% at 50% -404.55%, rgba(255, 255, 255, 0.12) 0%, 
        rgba(255, 255, 255, 0.12) 50%, rgba(170, 89, 223, 0.12) 100%), 
        linear-gradient(87deg, #AA59DF 0%, #7B59DF 66.81%, #AA59DF 133.63%);

      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%),
          radial-gradient(42.37% 709.09% at 50% -404.55%, rgba(255, 255, 255, 0.12) 0%,
          rgba(255, 255, 255, 0.12) 50%, rgba(170, 89, 223, 0.12) 100%),
          linear-gradient(87deg, #AA59DF 0%, #7B59DF 66.81%, #AA59DF 133.63%);
      }
      ` : `
      background: radial-gradient(42.37% 709.09% at 50% -404.55%, rgba(255, 255, 255, 0.12) 0%,
        rgba(255, 255, 255, 0.12) 50%, rgba(170, 89, 223, 0.12) 100%),
        linear-gradient(89deg, #59DFC0 0%, #356CE4 74.49%, #59DFC0 148.99%);

      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%),
         radial-gradient(42.37% 709.09% at 50% -404.55%, rgba(255, 255, 255, 0.12) 0%,
         rgba(255, 255, 255, 0.12) 50%, rgba(170, 89, 223, 0.12) 100%),
         linear-gradient(89deg, #59DFC0 0%, #356CE4 74.49%, #59DFC0 148.99%), #A2A5AA;
      }
    `)}
  ${({ isReset }) => (isReset
    ? `
      background:  transparent;
      color:#000;
      border: 1px solid #E8E9EA;
       &:hover {
            color:#484343;
            border: 0.6px solid #FFC72C;
            background: #FFEEBF;
          }
    `
    : '')}
    ${({ isSave }) => (isSave
    ? `
      background: linear-gradient(317deg, rgb(31, 174, 9) -2.91%, rgb(91, 218, 72) 138.5%);
      &:hover {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%,
           rgba(255, 255, 255, 0.15) 100%),
           linear-gradient(317deg, #1FAE09 -2.91%, #5BDA48 138.5%);
        }
    `
    : '')}
`;

export const Textarea = styled.textarea`
  width: 100%;
  margin-right: 50px;
  height: 28px;
  max-height: 76px;
  resize: none;
  border: none;
  font-family: "RobotoFlex", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  padding-left: 8px;
  font-size: 16px;
  font-weight: 500;

  &::placeholder {
  font-weight: 500;
    font-size: 16px;
    padding-top: 2px;
    color: #C5C5C5;
  }

  &:focus {
    background: #F5F5F5;
  }

   &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    position: absolute;
  }
  &::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 4px;
    background-color: #dbdbdb;
    -webkit-box-shadow: inset 0 0 6px #dbdbdb;
    margin-right: 2px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    position: absolute;
  }
  &::-webkit-scrollbar-track-piece {
    display: none;
    background: #dbdbdb;
  }
`;
export const keyFrames = keyframes`
   to {
      transform: rotate(360deg);
   }
`;

export const SharedIndicator = styled.div`
  background-color: #5FE77F;
  width: 8px;
  height: 8px;
  position: absolute;
  left: 89px;
  top: 10px;
  border-radius: 50%;
`;

export const AISpinnerWrapper = styled.div`
  width: 40px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ImageShell = styled.div`
  position: relative;
  transition: ease-out 0.25s;
  transition-property: width, height;
  overflow: hidden;
  border-radius: 6px;
  display: flex;
  width: 38px;
  height: 38px;
`;
export const Spinner = styled.div <{ isSmall?: boolean }>`
   ${({ isSmall }) => (isSmall
    ? `
      width: 4.8px;
      height: 4.8px;
      border-radius: 4.8px;
      box-shadow: 12px 0px 0 0 rgba(252,255,0,0.2), 9.7px 7.1px 0 0 rgba(252,255,0,0.4), 
        3.7199999999999998px 11.4px 0 0 rgba(252,255,0,0.6), 
        -3.7199999999999998px 11.4px 0 0 rgba(252,255,0,0.8), -9.7px 7.1px 0 0 #fcff00;
    `
    : `
      width: 11.2px;
      height: 11.2px;
      border-radius: 11.2px;
      box-shadow: 28px 0px 0 0 rgba(240,240,244,0.2), 22.7px 16.5px 0 0 rgba(240,240,244,0.4),
        8.68px 26.6px 0 0 rgba(240,240,244,0.6), -8.68px 26.6px 0 0 rgba(240,240,244,0.8), -22.7px 16.5px 0 0 #f0f0f4;
    `)}
  animation: ${keyFrames} 1s infinite linear;
`;
export const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const Result = styled.div`
  display: flex;
  padding: 6px 8px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid #E8E9EA;
  background: #FFF;
  cursor: pointer;
`;
export const SingleResultInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectedItemsCounter = styled.div`
  border-radius: 50%;
  background-color: #FFC72C;
  min-width: 24px;
  width: fit-content;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BatchActionPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 4px 0;
  background: #fff;
  box-shadow: 0px 24px 48px -8px rgba(66, 66, 66, 0.16), 0px 4px 12px 4px rgba(66, 66, 66, 0.12);
  min-width: 280px;
  width: fit-content;
  bottom: 86px;
  border-radius: 12px;
`;

export const PopupHeader = styled.div`
  display: flex;
  height: 40px;
  padding: 10px 16px;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;

export const HorizontalDivider = styled.div`
  background-color: #D9D9D933;
  width: 100%;
  margin: 0 4px 7px;
  height: 1px;
`;

export const PopupSectionTitle = styled.div`
  color: #8D8A93;
  font-size: 12px;
  font-family: RobotoFlex, sans-serif;
  margin: 0;
  padding-left: 16px;
`;

export const ItemsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const ItemName = styled.div<{ isDelete?: boolean, isAI?: boolean }>`
  display: flex;
  gap: 6px;
  align-items: center;
  white-space: nowrap;
  svg {
    width: 20px;
    height: 20px;
  }

  ${({ isDelete }) => (isDelete ? `
    svg path {
      stroke: #E7715F;
    }
    ` : '')};
`;

export const SingleItem = styled.button<{isAI?: boolean}>`
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 6px 11px;
  align-items: center;
  border: 4px solid #fff;
  border-radius: 8px;
  background: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  gap: 20px;
  & .hidePlaylistSelector {
    display: none;
  }
  &:hover:not([disabled]) {
    background: #E6AC190F;

    ${ItemName} {
      color: #E7C15F;

      svg path {
        ${({ isAI }) => (isAI ? 'fill: #E7C15F;' : 'stroke: #E7C15F;')}
      }
    }
    .hidePlaylistSelector {
      display: block;
    } 
  }

  &:focus:not([disabled]) {
    background: #E6AC190F;
    border-color: #CA971614;

    ${ItemName} {
      color: #E7C15F;

      svg path {
        ${({ isAI }) => (isAI ? 'fill: #E7C15F;' : 'stroke: #E7C15F;')}
      }
    }
  }

  &:active:not([disabled]) {
    background: #E6AC190F;

    ${ItemName} {
      color: #F8BA12;

      svg path {
        ${({ isAI }) => (isAI ? 'fill: #F8BA12;' : 'stroke: #F8BA12;')};
      }
    }
  }
`;


export const HotkeyWrapper = styled.div<{ isDelete?: boolean }>`
  font-size: 12px;
  text-transform: uppercase;
  font-family: RobotoFlex, sans-serif;
  border: 1px solid #B7B5BA;
  border-radius: 4px;
  padding: 5px 6px;
  white-space: nowrap;
  ${({ isDelete }) => (isDelete ? `
    border-color: #F3B0A5;
    color: #F3B0A5;
    ` : '')};
`;


export const SmartfilesListWrapper = styled.div<{ isDelete?: boolean }>`
  position: absolute;
  left: calc(100% - 16px);
  z-index: 1;
`;
