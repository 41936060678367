import { convertFromRaw, EditorState } from 'draft-js';
import {
  CurrentPage,
  DRAG_MANY_PAGE,
  DRAG_PAGE,
  EDIT_TITLE,
  GO_TO_VIEW_PAGE,
  HAS_DRAGGABLE,
  PAGE_IS_DOWNLOAD,
  PAGE_WILL_DOWNLOAD,
  SAVE_CURRENT_PLAYLIST,
  SAVE_CURRENT_PLAYLIST_TIME,
  SET_DRAGGABLE_BLOCKS,
  SWITCH_PAGE,
  UPDATE_BLOCK,
  UPDATE_CURRENT_PLAYLIST_TIME,
  UPDATE_LAYERS,
} from './types';
import { EditPage } from '../pages/types';
import EditPlaylist from '../playlists/types';
import Playlist from '../playlists/types';
import { Hashtag } from '../hashtags/types';
import { Tags } from '../tags/types';
import SupportAction from '../support/types';
import { LibraryComponents } from '../library/types';
import { Channels } from '../channels/types';
import { ServiceUser } from '../user/types';
import { getLayersForPage } from '../../shared/page/utils';
import draftDataConvertToLoad from '../../utils/draftDataConvert';
import { MuxContentStatus } from '../muxContentStatus/types';
import { DateRangeMark4SharedPlaylist } from '../../components/DatePicker/helpers/enum';
import { aiProcessingStatusEnum } from '../../utils/constants';

const defaultState = {
  title: '',
  id: '',
  isDownload: true,
  content: {},
  time: '',
  type: 'page',
  shareState: {
    usersToSharing: [],
  },
  singleUserShareState: {},
  draggableBlocks: [],
  hasDraggable: false,
  isCardView: true,
  libraryDragStatus: false,
  isLargeRightBar: false,
  isShowRightBar: false,
  isShowPagesWidget: false,
  isShowLibraryWidget: false,
  inputs: {},
  tags: [],
  blocks: [],
  selectedItems: {},
  aiProcessing: {
    type: null,
    isLoading: false,
  },
  currentSummarize: '',
  approvalButtonsPreview: true,
  dateTimePreview: true,
};

export default (state = defaultState, action) => {
  const { payload, type } = action;
  switch (type) {
    case EDIT_TITLE: {
      const { isUpdateForbidden } = payload;
      const newState = { ...state };
      if (state.lastModifiedDate !== payload.lastModifiedDate) newState.hasModification = true;
      return { ...newState, title: payload.title, isUpdateForbidden };
    }
    case EditPlaylist.EditDescriptionR: {
      return {
        ...state,
        description: payload.description,
      };
    }
    case CurrentPage.Share.UPDATE_SHARE_STATE: {
      return {
        ...state,
        shareState: { ...state.shareState, ...payload.updateState },
      };
    }
    case SWITCH_PAGE: {
      const newState = { ...state };
      if (state.lastModifiedDate !== payload.lastModifiedDate) newState.hasModification = true;
      return { ...newState, ...payload, tryPublish: false };
    }
    case UPDATE_LAYERS: {
      if (payload.playlistField) {
        state.layers[payload.playlistField] = payload.layers;
        return { ...state };
      }
      return { ...state, ...payload };
    }
    case CurrentPage.ShowLibraryWidget: {
      if (!payload.isShowLibraryWidget) {
        return {
          ...state,
          ...payload,
          isLargeRightBar: false,
          isShowPagesWidget: false,
        };
      }
      return { ...state, ...payload, isShowPagesWidget: false };
    }
    case CurrentPage.ShowPagesWidget: {
      if (!payload.isShowPagesWidget) {
        return { ...state, ...payload, isLargeRightBar: false };
      }
      return { ...state, ...payload, isShowLibraryWidget: false };
    }
    case CurrentPage.ResizeLibraryWidget: {
      return { ...state, ...payload };
    }
    case UPDATE_BLOCK: {
      const oldState = [...state.blocks];
      const index = state.blocks.findIndex((i) => i.id === payload.id);
      let block = { ...oldState[index] };

      if (block?.state || block.isNew) {
        if (payload?.width) {
          block.width = payload.width;
        }
        if (payload?.type) {
          block.type = payload.type;
        }
        if (payload?.innerHtml) {
          block.innerHtml = payload.innerHtml;
        }
        if (payload?.state) {
          block.state = payload.state;
        }
        if (payload?.content) {
          block.content = payload.content;
        }
        if (payload?.imageUrl) {
          block = { ...block, ...payload.imageUrl };
        }
        const blockState = [
          ...state.blocks.slice(0, index),
          block,
          ...state.blocks.slice(index + 1),
        ];
        return { ...state, blocks: blockState };
      }
      return state;
    }
    case LibraryComponents.Pages.AddPageInLibraryRS: {
      return payload?.isNew ? { ...state, isNew: true } : state;
    }
    case PAGE_IS_DOWNLOAD: {
      return { ...state, ...payload };
    }
    case PAGE_WILL_DOWNLOAD: {
      return { ...state, isDownload: true };
    }
    case GO_TO_VIEW_PAGE: {
      return { ...state, isDownload: true };
    }
    case SET_DRAGGABLE_BLOCKS: {
      return { ...state, draggableBlocks: payload };
    }
    case HAS_DRAGGABLE: {
      return { ...state, hasDraggable: payload };
    }
    case DRAG_PAGE: {
      return { ...state, ...payload };
    }
    case CurrentPage.ToggleLibraryDrag: {
      return { ...state, libraryDragStatus: payload };
    }
    case DRAG_MANY_PAGE: {
      return { ...state, ...payload };
    }
    case SAVE_CURRENT_PLAYLIST: {
      return { ...state, ...payload };
    }
    case SAVE_CURRENT_PLAYLIST_TIME: {
      return { ...state, time: { ...payload } };
    }
    case EditPage.ChangeEditableBlockRedux: {
      const {
        payload: { id, newState, innerHtml },
      } = action;
      const block = state.blocks.find((item) => item.id === id);
      if (block) {
        block.state = newState;
        block.innerHtml = innerHtml;
        return { ...state };
      }
      return state;
    }
    case UPDATE_CURRENT_PLAYLIST_TIME: {
      return { ...state, time: { ...state.time, ...payload } };
    }
    case CurrentPage.ToggleIsCardView: {
      return { ...state, isCardView: !state.isCardView };
    }
    case CurrentPage.ClearCurrentPage: {
      return { ...defaultState, isCardView: state.isCardView };
    }
    // case CurrentPage.handleFolderTitleChangeREDUX: {
    //   return { ...state, title: payload.title };
    // }
    case CurrentPage.ToggleComponentIsHidden: {
      const {
        payload: { id, value, playlistField },
      } = action;
      if (playlistField) {
        const block = state.additionalInformation[payload.playlistField].find(
          (i) => i.id === payload.id,
        );
        if (block) block.isHidden = value;
        return {
          ...state,
          additionalInformation: { ...state.additionalInformation },
        };
      }
      const block = state.blocks.find((item) => item.id === id);
      if (block) block.isHidden = value;
      return { ...state };
    }
    case CurrentPage.BulkToggleComponentIsHidden: {
      const { idsValuesArray, playlistField } = action.payload;
      const checkObj = {};
      idsValuesArray.forEach((item) => {
        checkObj[item.id] = item.isHidden;
      });
      if (playlistField) {
        const blocks = state.additionalInformation[payload.playlistField];
        blocks.forEach((block) => {
          if (Object.keys(checkObj).includes(block.id)) {
            block.isHidden = checkObj[block.id];
          }
        });
        return {
          ...state,
          additionalInformation: { ...state.additionalInformation },
        };
      }
      const newState = { ...state };
      newState.blocks.forEach((block) => {
        if (Object.keys(checkObj).includes(block.id)) {
          block.isHidden = checkObj[block.id];
        }
      });
      return { ...newState };
    }
    case CurrentPage.ChangeStateComponentButtonREDUX: {
      const {
        payload: { buttonState },
      } = action;
      const blockIndex = state.blocks.findIndex(
        (item) => item.id === buttonState.id,
      );
      const newBlock = { ...state.blocks[blockIndex] };
      const newBlocks = [...state.blocks];
      newBlocks[blockIndex] = newBlock;
      newBlock.state = { ...buttonState.state };
      newBlock.data = { ...buttonState.data };
      newBlock.innerHtml = buttonState.innerHtml;
      return { ...state, blocks: [...newBlocks] };
    }
    case CurrentPage.ClearBlockPreSaveFlag: {
      const {
        payload: { id, playlistField },
      } = action;
      if (playlistField) {
        const blocks = state.additionalInformation[payload.playlistField].find(
          (i) => i.id === payload.id,
        );
        const block = blocks.find((item) => item.id === id);
        block.preSave = false;
        return {
          ...state,
          additionalInformation: { ...state.additionalInformation },
        };
      }
      const block = state.blocks.find((item) => item.id === id);
      block.preSave = false;
      return { ...state };
    }
    case CurrentPage.saveInputPlaylist: {
      state.inputs[payload.field] = payload.state;
      return { ...state };
    }
    case CurrentPage.toggleHideAdditionalInformation: {
      state.hidden[payload.field] = payload.state;
      return { ...state };
    }
    case CurrentPage.TryPublishPlaylist: {
      state.tryPublish = true;
      return { ...state };
    }
    case CurrentPage.UpdateSingleBlock: {
      const {
        index,
        foreignLastModifiedDate,
        foreignLastModifiedUserName,
        isOpenCreateComponent,
      } = payload;
      if (isOpenCreateComponent) return state;
      const block = state.blocks[index];

      if (!block) return state;

      const newBlock = {
        ...block,
        state: {
          ...block.state,
          data: {
            ...block.state.data,
            foreignLastModifiedDate,
            foreignLastModifiedUserName,
            isUpdated: true,
          },
        },
      };

      return {
        ...state,
        blocks: state.blocks.map((blockItem, blockIndex) => (index === blockIndex ? newBlock : blockItem),
        ),
      };
    }
    case CurrentPage.RemoveBlock: {
      const { blockIdToDelete, isOpenCreateComponent } = payload;
      if (isOpenCreateComponent) return state;
      const newBlocks = [
        ...state.blocks.filter((block) => block.id !== blockIdToDelete),
      ];
      return { ...state, blocks: newBlocks };
    }
    case CurrentPage.InsertSingleBlock: {
      const { firstIndex, block, isOpenCreateComponent } = payload;

      if (isOpenCreateComponent) return state;

      return {
        ...state,
        blocks: [
          ...state.blocks.slice(0, firstIndex),
          block,
          ...state.blocks.slice(firstIndex),
        ],
      };
    }
    case CurrentPage.ReplaceSingleBlock: {
      const { block, isOpenCreateComponent } = payload;

      if (isOpenCreateComponent) return state;

      return {
        ...state,
        blocks: [
          ...state.blocks.map((pageBlock) => (pageBlock.id === block.id ? block : pageBlock),
          ),
        ],
      };
    }
    case CurrentPage.ClearTryPublishPlaylist: {
      state.tryPublish = false;
      return { ...state };
    }
    case CurrentPage.RemoveLinkPageFromPlaylist: {
      const { textComponentIds, linkPageIds } = payload;
      const textComponentId = textComponentIds;
      const linkPageId = linkPageIds;
      if (state.linkPages) {
        if (Array.isArray(linkPageIds)) {
          const linkPages = state.linkPages.filter((i) => !linkPageIds.includes(i.id));
          const newUserComponents = { ...state.usedLibraryComponents };
          textComponentIds?.forEach((id) => { newUserComponents[id] = false; });
          return {
            ...state,
            linkPages,
            usedLibraryComponents: newUserComponents,
          };
        }
        const linkPages = state.linkPages.filter((i) => i.id !== linkPageId);
        const newUserComponents = {
          ...state.usedLibraryComponents,
          [textComponentId]: false,
        };
        return {
          ...state,
          linkPages,
          usedLibraryComponents: newUserComponents,
        };
      }

      const newUserComponents = {
        ...state.usedLibraryComponents,
        [textComponentId]: false,
      };
      return { ...state, usedLibraryComponents: newUserComponents };
    }
    case CurrentPage.ReplaceLinkPagePlaylistPage: {
      const { idDragPage } = payload;
      const newUserComponents = {
        ...state.usedLibraryComponents,
        [idDragPage]: true,
      };
      return { ...state, usedLibraryComponents: newUserComponents };
    }

    case CurrentPage.CreateLibLinkPagePlaylist: {
      const { idDragPage } = payload;
      const newUserComponents = {
        ...state.usedLibraryComponents,
        [idDragPage]: true,
      };
      return { ...state, usedLibraryComponents: newUserComponents };
    }
    case CurrentPage.ClearLinkPageType: {
      const { addedElementId } = payload;
      const newUserComponents = {
        ...state.usedLibraryComponents,
        [addedElementId]: false,
      };
      return { ...state, usedLibraryComponents: newUserComponents };
    }

    case EditPlaylist.AddPlaylistToChannelR: {
      const { playlist, channels } = payload;
      const test = {};
      playlist?.inChannel.forEach((item) => {
        test[item.id] = item;
      });
      return {
        ...state,
        usedInChannels: { ...state.usedInChannels, ...channels },
      };
    }
    case EditPlaylist.RemovePlaylistFromChannelR: {
      const { channel } = payload;
      const newState = { ...state };
      delete newState.usedInChannels[channel.id];
      return newState;
    }

    case EditPlaylist.ToggleFavorite: {
      if (state.id === payload.id) {
        const newState = { ...state };
        newState.isFavorite = payload.isFavorite;
        return newState;
      }
      return state;
    }

    case EditPlaylist.ToggleIsShowDescriptionPlaylist: {
      if (state.id === payload.id) {
        const newState = { ...state };
        newState.isShowDescription = payload.isShowDescription;
        return newState;
      }
      return state;
    }
    case EditPlaylist.EditComplementaryPlaylists: {
      const { addIdsPlaylist, removeIdsPlaylist } = payload;
      if (!state.complementaryPlaylists) state.complementaryPlaylists = {};

      removeIdsPlaylist.forEach((id) => {
        if (state.complementaryPlaylists[id]) state.complementaryPlaylists[id] = false;
      });
      addIdsPlaylist.forEach((id) => {
        state.complementaryPlaylists[id] = true;
      });

      return { ...state };
    }

    case CurrentPage.SetAvailabilityDate: {
      const { availableFrom, availableTo } = action.payload;
      const newState = { ...state };
      newState.inputs.availableFrom = availableFrom
        ? Math.floor(availableFrom.getTime() / 1000)
        : 0;
      newState.inputs.availableTo = availableTo
        ? Math.floor(availableTo.getTime() / 1000)
        : 0;
      return newState;
    }
    case EditPlaylist.EditImageREDUX: {
      // state[payload.id].movedToTrash = payload.state;
      const { content } = state;
      content.oldImg = content.img;
      content.oldComponentId = content.componentId;
      content.oldPosition = content.position;
      content.edit = true;
      if (payload.src) {
        content.img = payload.src;
        content.componentId = payload.id;
        content.position = 0;
      }
      return { ...state, content: { ...content } };
    }
    case EditPlaylist.AddImage: {
      const content = { ...state.content };
      content.position = payload.position;
      content.crop = payload.crop;
      const upd = {};
      if (payload.componentId) {
        upd.img = { id: payload.componentId, urlFile: payload.img };
      }
      content.edit = false;
      return { ...state, ...upd, content };
    }
    case EditPlaylist.ChangeDefaultColor: {
      const content = { ...state.content };
      content.defaultPreviewColor = payload.defaultPreviewColor;
      const upd = {};
      if (payload.componentId) {
        upd.defaultPreviewColor = payload.defaultPreviewColor;
      }
      content.edit = false;
      return { ...state, ...upd, content };
    }
    case EditPlaylist.DeleteImageRedux: {
      const { content } = state;
      content.position = '';
      content.componentId = '';
      content.img = '';
      content.edit = false;
      return { ...state, cropUrl: '', img: {}, content: { ...content } };
    }

    case EditPlaylist.RemoveChangesImageREDUX: {
      const { content } = state;
      content.img = content.oldImg;
      content.componentId = content.oldComponentId;
      content.position = content.oldPosition;
      content.edit = false;
      return { ...state, content: { ...content } };
    }
    case CurrentPage.UpdatePlaylistLastModifiedDate: {
      const { lastModifiedDate } = payload;
      const newState = { ...state };
      if (lastModifiedDate !== state.lastModifiedDate) newState.hasModification = true;
      return { ...newState, lastModifiedDate };
    }
    case CurrentPage.InsertGoogleUploadBlockR: {
      const { playlistId, lastModifiedDate } = payload;
      if (state.id !== playlistId) return state;
      return { ...state, lastModifiedDate };
    }
    case CurrentPage.DeleteLinkPageR: {
      const { playlistId, lastModifiedDate } = payload;
      if (state.id !== playlistId) return state;
      return { ...state, lastModifiedDate };
    }
    case CurrentPage.UpdateGoogleUploadBlockR: {
      const { playlistId, lastModifiedDate } = payload;
      if (state.id !== playlistId) return state;
      return { ...state, lastModifiedDate };
    }
    case LibraryComponents.Pages.UpdatePageLastModified: {
      const { lastModifiedDate } = payload;
      return { ...state, lastModifiedDate, hasModification: true };
    }
    case Hashtag.ClearPlaylistHashtags: {
      return { ...state, hashtags: {} };
    }
    case CurrentPage.UpdateLinkPage: {
      const pageData = payload?.pageData;
      if (!(state.linkPages || state.linkPages.length || pageData)) {
        return { ...state, newBlockId: null };
      }
      const linkPages = [...state.linkPages];
      const target = linkPages.find((it) => it.id === pageData.id);
      if (!target) {
        return { ...state, newBlockId: null };
      }
      target.libraryComponent = {
        ...target.libraryComponent,
        ...pageData.libraryComponent,
      };
      return { ...state, linkPages };
    }
    case CurrentPage.ManyUpdatePositionLinkPages: {
      return { ...state, newBlockId: null };
    }
    case CurrentPage.UpdatePositionLinkPagesMaker: {
      // const { lastModifiedDate } = payload;

      const newLinkPage = state.linkPages
        .map((item) => {
          if (payload[item.id]) return { ...item, ...payload[item.id] };
          return item;
        })
        .sort((a, b) => a.position - b.position);

      return { ...state, linkPages: newLinkPage };
    }
    case EditPlaylist.ChangeTextElementBlockRedux: {
      return { ...state, newBlockId: null };
    }
    case CurrentPage.UpdateCurrentPageTitle: {
      const { newTitle } = payload;
      return { ...state, title: newTitle };
    }
    case Hashtag.CreateHashtag: {
      const { hashtag } = payload;
      if (state.hashtags?.length) {
        return {
          ...state,
          hashtags: [...state.hashtags, { ...hashtag, type: 'hashtags' }],
        };
      }
      return { ...state, hashtags: [{ ...hashtag, type: 'hashtags' }] };
    }
    case Hashtag.DeleteHashtag: {
      const { hashtag, parent } = payload;
      const newState = { ...state };
      if (state.lastModifiedDate !== parent.lastModifiedDate) newState.hasModification = true;
      newState.hashtags = state.hashtags.filter(
        (i) => i.hashtagTitle !== hashtag.title && i.title !== hashtag.title,
      );
      return { ...newState };
    }
    case Hashtag.AddHashtagsToState: {
      const { hashtags } = payload;
      return { ...state, hashtags: { ...state.hashtags, ...hashtags } };
    }
    case EditPlaylist.UpdateTime: {
      const { durationTime, durationTimePage } = payload;
      if (durationTimePage) return { ...state, durationTime, durationTimePage };
      return { ...state, durationTime };
    }

    case Tags.AddTagToPlaylist: {
      const { tag, playlist } = payload;
      const newState = { ...state };
      if (state.lastModifiedDate !== playlist.lastModifiedDate) newState.hasModification = true;
      return { ...newState, tags: [...state.tags, tag] };
    }
    case Tags.attachTagParentAndCurrentPageRedux: {
      const { tag, isSelected } = payload;
      const newState = { ...state };
      if (state.lastModifiedDate !== payload.lastModifiedDate) newState.hasModification = true;
      if (isSelected && state.tags.some((t) => t.id === tag.id)) return newState;
      return { ...newState, tags: [...state.tags, tag] };
    }
    case Tags.ChangeTagColor: {
      const { id, newColor, newBackgroundColor } = payload;
      // if (type === 'LibraryComponent') return state;

      const newArr = state.tags.map((item) => {
        if (item.id === id) {
          const newItem = { ...item };
          if (newColor) newItem.color = newColor;
          if (newBackgroundColor) newItem.backgroundColor = newBackgroundColor;
          return newItem;
        }
        return item;
      });
      return { ...state, tags: newArr };
    }
    case Tags.ChangeTagTitle: {
      const { id, newTitle } = payload;
      // if (type === 'LibraryComponent') return state;

      const newArr = state.tags.map((item) => {
        if (item.id === id) {
          return { ...item, title: newTitle };
        }
        return item;
      });
      return { ...state, tags: newArr };
    }
    case Tags.DeleteTag: {
      const { tag, index, lastModifiedDate } = payload;
      const newState = { ...state };
      if (state.lastModifiedDate !== lastModifiedDate) newState.hasModification = true;
      if (index) {
        const newArr = newState.tags.filter(
          (_, stateIndex) => stateIndex !== index,
        );
        if (state.lastModifiedDate !== lastModifiedDate) newState.hasModification = true;
        return { ...newState, tags: newArr };
      }
      const newArr = state.tags.filter((item) => item.id !== tag.id);
      return { ...newState, tags: newArr };
    }
    case SupportAction.updateTag.DeleteLocal: {
      const { tag, index, lastModifiedDate } = payload;
      const newState = { ...state };
      const newTags = [...state.tags];
      if (state.lastModifiedDate !== lastModifiedDate) newState.hasModification = true;
      if (index) {
        const newArr = newTags.filter((_, stateIndex) => stateIndex !== index);
        if (state.lastModifiedDate !== lastModifiedDate) newState.hasModification = true;
        return { ...newState, tags: newArr };
      }
      const newArr = newTags.filter((item) => item.id !== tag.id);
      return { ...newState, tags: newArr };
    }
    case SupportAction.ToggleSettingsSideBar: {
      return { ...state, isShowPagesWidget: false, isShowLibraryWidget: false };
    }

    case Channels.SetCurrentChannel: {
      return { ...state, id: payload.id };
    }

    case Channels.UpdateChannelR: {
      return {
        ...state,
        id: payload.id,
        inputs: {
          description: payload.description,
          isPrivate: payload.isPrivate,
          name: payload.name,
          subscribers: payload.subscribers,
          category: payload.category,
        },
        owner: payload.owner,
      };
    }
    case Channels.SaveChannelR: {
      return {
        ...state,
        id: payload.id,
        inputs: {
          description: payload.description,
          isPrivate: payload.isPrivate,
          name: payload.name,
          subscribers: payload.subscribers,
          category: payload.category,
        },
        owner: payload.owner,
      };
    }

    // case Channels.AddChannelR: {
    //   return { ...state,
    //     id: payload.channelId,
    //     role: RawUserRoleMap.Admin,
    //     status: Statuses.Active,
    //     inputs: {
    //       description: payload.description,
    //       isPrivate: payload.isPrivate,
    //       name: payload.name,
    //       subscribers: payload.subscribers,
    //       category: payload.category,
    //     },
    //     owner: {
    //       id: payload.user.id,
    //       name: `${payload.user.first_name} ${payload.user.last_name}`,
    //       email: payload.user.email,
    //     },
    //     playlistsCounter: 0,
    //     position: payload.newPosition,
    //   };
    // }

    case Channels.DeleteChannel: {
      return { ...state, inputs: {} };
    }
    case CurrentPage.ToggleIsCollapsedContent: {
      return { ...state, isCollapsedContent: payload.value };
    }
    case CurrentPage.SetResetScale: {
      return { ...state, resetScale: payload.value };
    }

    case SupportAction.SettingsSideBarOn: {
      return { ...state, isShowLibraryWidget: false };
    }
    case LibraryComponents.UpdatePdf: {
      const { relationId, fieldsArr, valuesArr, isNew } = action.payload;
      const index = state.blocks.findIndex((item) => item.id === relationId);
      const currentBlock = {
        ...state.blocks[index],
        state: {
          ...state.blocks[index].state,
          data: { ...state.blocks[index].state?.data, isNew },
        },
      };
      const needUpdateKeys = [];
      const needUpdateValues = [];
      if (fieldsArr.length) {
        fieldsArr.forEach((item, itemIndex) => {
          if (
            currentBlock?.state?.data
            && currentBlock?.state.data[item] !== valuesArr[itemIndex]
          ) {
            needUpdateKeys.push(item);
            needUpdateValues.push(valuesArr[itemIndex]);
          }
        });
      }

      delete currentBlock.isNew;
      needUpdateKeys.forEach((item, itemIndex) => {
        currentBlock.state.data[item] = needUpdateValues[itemIndex];
      });
      const blockState = [
        ...state.blocks.slice(0, index),
        currentBlock,
        ...state.blocks.slice(index + 1),
      ];
      return { ...state, blocks: blockState };
    }
    case Tags.DeleteTagFromSystem: {
      const { tagId } = payload;
      return {
        ...state,
        tags: [...state.tags?.filter((item) => item.id !== tagId)],
      };
    }
    case ServiceUser.Logout: {
      return { ...defaultState };
    }
    case EditPlaylist.MarkViewedR: {
      const { isFinished } = payload;
      return { ...state, isFinished: !isFinished };
    }

    case CurrentPage.markAllLinkPagesAsIncomplete: {
      const linkPages = state.linkPages.map((item) => {
        return { ...item, isViewed: false };
      });

      return { ...state, linkPages };
    }

    case '@@router/LOCATION_CHANGE':
      return [...state, action.payload];

    case EditPage.SendingOnEmail: {
      const { emailsToInvite, pageId, sendStatus } = payload;
      if (state.id !== pageId) {
        return state;
      }
      const inviteStatus = {};
      emailsToInvite.forEach((i) => {
        inviteStatus[i] = sendStatus;
      });
      return { ...state, shareState: { ...state.shareState, inviteStatus } };
    }
    case EditPlaylist.SendingOnEmail: {
      const { emailsToInvite, playlistId, sendStatus } = payload;
      if (state.id !== playlistId) {
        return state;
      }
      const inviteStatus = {};
      emailsToInvite.forEach((i) => {
        inviteStatus[i] = sendStatus;
      });
      return { ...state, shareState: { ...state.shareState, inviteStatus } };
    }
    case EditPage.RemoveUserToSharing: {
      const { user, pageId } = payload;
      if (state.id !== pageId) {
        return state;
      }
      const singleUserShareState = { ...state.singleUserShareState };
      delete singleUserShareState[user.email];

      return {
        ...state,
        singleUserShareState,
      };
    }
    case EditPlaylist.RemoveUserToSharing: {
      const { user, pageId } = payload;
      if (state.id !== pageId) {
        return state;
      }
      const singleUserShareState = { ...state.singleUserShareState };
      delete singleUserShareState[user.email];

      const usersToSharing = state.shareState.usersToSharing.filter((i) => i.email !== user.email);
      return {
        ...state,
        singleUserShareState,
        shareState: { ...state.shareState, usersToSharing },
      };
    }
    case EditPlaylist.RemoveChannel: {
      const { channelId } = payload;

      const usedInChannels = { ...state.usedInChannels };
      delete usedInChannels[channelId];
      if (!Object.keys(usedInChannels).length) {
        return {
          ...state,
          usedInChannels,
          isPublish: false,
        };
      }
      return {
        ...state,
        usedInChannels,
      };
    }
    case EditPlaylist.UpdateChannel: {
      const { usedInChannels } = payload;

      return {
        ...state,
        usedInChannels,
      };
    }
    case EditPlaylist.updatePublishStatePlaylistSR: {
      const { channelId, updateTime } = payload;
      const newState = {};
      if (updateTime.dateRangeMark === DateRangeMark4SharedPlaylist.OPEN_TO_READ) {
        newState.availableFrom = 0;
        newState.availableTo = 0;
      } else {
        const {
          availableFrom,
          availableTo,
        } = updateTime;

        newState.availableFrom = availableFrom ? Math.floor(
          availableFrom?.getTime() / 1000,
        ) : 0;
        newState.availableTo = availableTo ? Math.floor(
          availableTo?.getTime() / 1000,
        ) : 0;
      }
      const usedInChannels = { ...state.usedInChannels,
        [channelId]: { ...state.usedInChannels[channelId], ...newState } };
      return {
        ...state,
        usedInChannels,
      };
    }
    case EditPage.AddUserToSharing: {
      const { user, pageId } = payload;
      if (state.id !== pageId) {
        return state;
      }
      const newEmails = [...(state.shareState.usersToSharing || []), user];

      return {
        ...state,
        shareState: { ...state.shareState, usersToSharing: newEmails },
      };
    }
    case EditPlaylist.AddUserToSharing: {
      const { user, pageId } = payload;
      if (state.id !== pageId) {
        return state;
      }
      const newEmails = [...(state.shareState.usersToSharing || []), user];

      return {
        ...state,
        shareState: { ...state.shareState, usersToSharing: newEmails },
      };
    }
    case CurrentPage.RemoveBlockR: {
      const { id, index } = payload;
      const layerId = [...state.layers][index];

      const newBlocks = [...state.blocks.filter((block) => block.id !== id)];
      const newLayers = [
        ...state.layers.filter((layer) => layer.id !== layerId),
      ];

      return { ...state, blocks: newBlocks, layers: newLayers };
    }

    case EditPage.ConvertBlockIntoAddMedia: {
      const { blockId, isOpenCreateComponent } = payload;
      if (isOpenCreateComponent) return state;

      const newBlock = {
        ...state.blocks.find((block) => block.id === blockId),
        type: 'image',
        state: null,
      };
      const newBlocks = [
        ...state.blocks.filter((block) => block.id !== blockId),
        newBlock,
      ];
      const newLayers = getLayersForPage(newBlocks);

      return { ...state, blocks: newBlocks, layers: newLayers };
    }
    case EditPage.ConvertBlockIntoEmbed: {
      const { isOpenCreateComponent, block } = payload;
      if (isOpenCreateComponent) return state;

      const newBlock = {
        ...state.blocks.find((item) => item.id === block.id),
        ...block,
      };
      const newBlocks = [
        ...state.blocks.filter((item) => item.id !== block.id),
        newBlock,
      ];
      const newLayers = getLayersForPage(newBlocks);

      return { ...state, blocks: newBlocks, layers: newLayers };
    }

    case EditPage.AddBlock: {
      const { block, isOpenCreateComponent } = payload;
      if (isOpenCreateComponent) return state;

      const newBlock = {
        ...state.blocks.find((item) => item.id === block.id),
        ...block,
        type: 'image',
        state: null,
      };
      const newBlocks = [
        ...state.blocks.filter((item) => item.id !== block.id),
        newBlock,
      ];
      const newLayers = getLayersForPage(newBlocks);

      return { ...state, blocks: newBlocks, layers: newLayers };
    }
    case EditPage.RemoveBlock: {
      const { id, isOpenCreateComponent } = payload;
      if (isOpenCreateComponent) return state;

      const newBlocks = [...state.blocks.filter((block) => block.id !== id)];
      const newLayers = getLayersForPage(newBlocks);

      return { ...state, blocks: newBlocks, layers: newLayers };
    }

    case EditPage.DeleteManyBlocksRedux: {
      const { isOpenCreateComponent, blocks } = payload;
      if (isOpenCreateComponent) return state;

      const ids = blocks.reduce((acc, item) => {
        acc[item.id] = true;
        return acc;
      }, {});

      const newBlocks = [...state.blocks.filter((item) => !ids[item.id])];
      const newLayers = getLayersForPage(newBlocks);

      return { ...state, blocks: newBlocks, layers: newLayers };
    }
    case EditPage.AddManyBlocksRedux: {
      const { isOpenCreateComponent, blocks } = payload;
      if (isOpenCreateComponent) return state;

      const newBlocks = [...state.blocks, ...blocks];
      const newLayers = getLayersForPage(newBlocks);

      return { ...state, blocks: newBlocks, layers: newLayers };
    }
    case Playlist.UpdateReadStateLinkPage: {
      const { isViewed, linkPageId, id } = payload;
      if (!id) return state;
      const linkPages = state.linkPages?.map((item) => {
        if (item.id !== linkPageId) return item;
        return { ...item, isViewed: !isViewed };
      });
      if (linkPages) {
        return { ...state, linkPages };
      } return state;
    }
    case Playlist.UpdateApproveStateLinkPage: {
      const { isApprove, id, mainUserInterface } = payload;
      if (!mainUserInterface?.id) return state;
      const linkPages = state.linkPages.map((item) => {
        if (item.id !== id) return item;
        return { ...item, isApprove: !isApprove };
      });

      return { ...state, linkPages };
    }

    case CurrentPage.UpdateRemixStateUPV: {
      const {
        payload: { linkPageId, value },
      } = action;
      const linkPages = [...state.linkPages];
      const linkPage = linkPages.find((it) => it.id === linkPageId);
      linkPage.isRemixLocked = value;
      return { ...state, linkPages };
    }
    case CurrentPage.CreateLinkPageR: {
      const { pageData } = payload;
      const linkPages = state.linkPages;
      if (!linkPages) return state;
      pageData.approveButtonState = {};
      pageData.isShowCaption = false;
      const newLinkPage = [
        ...linkPages,
        { ...pageData, createDate: Math.floor(Date.now() / 1000) },
      ].sort((a, b) => a.position - b.position);
      return { ...state, linkPages: newLinkPage };
    }
    case CurrentPage.AddEmptyLinkPageToPlaylist: {
      const { pageData } = payload;
      const linkPages = state.linkPages;
      if (!linkPages) return state;

      const newLinkPage = [...linkPages, pageData].sort(
        (a, b) => a.position - b.position,
      );
      return { ...state, linkPages: newLinkPage };
    }
    case CurrentPage.ChangeLinkPageTypeR: {
      const { pageData, isSocket } = payload;
      const linkPages = state.linkPages;
      if (!linkPages) return state;
      if (!isSocket) {
        const newLinkPage = linkPages.map((item) => {
          if (item.id === pageData.id) {
            return { ...item, ...pageData };
          }
          return item;
        });
        return { ...state, linkPages: newLinkPage };
      }
      const newLinkPage = linkPages.map((item) => {
        if (item.id === pageData.id) {
          return {
            ...item,
            ...pageData,
            isEdit: false,
            textComponent: {
              ...pageData.textComponent,
              state:
                pageData.textComponent?.state
                && draftDataConvertToLoad(pageData.textComponent?.state || ''),
            },
          };
        }
        return item;
      });
      return { ...state, linkPages: newLinkPage };
    }
    case CurrentPage.RenameEdit: {
      const { itemId, isSocket, isEdit } = payload;
      const linkPages = state.linkPages;
      if (!linkPages || isSocket) return state;
      const newLinkPage = linkPages.map((item) => {
        if (item.id === itemId) {
          return { ...item, isEdit };
        }
        return item;
      });
      return { ...state, linkPages: newLinkPage };
    }
    case EditPlaylist.removeNewInLinkPage: {
      const {
        payload: { isSocket, state: newState },
      } = action;
      const linkPages = state.linkPages;

      if (!linkPages || isSocket) return state;
      const newLinkPage = linkPages.map((item) => {
        if (item.isEdit) {
          let updateState = newState;
          if (newState?.getCurrentContent) {
            updateState = newState?.getCurrentContent && EditorState.createWithContent(newState?.getCurrentContent());
          }
          return {
            ...item,
            isEdit: false,
            textComponent: { ...item.textComponent, state: updateState },
          };
        }
        return item;
      });
      return { ...state, linkPages: newLinkPage, newBlockId: null };
    }
    case Channels.SetPlaylistButtonsStatesR: {
      const { playlistId, channelId, buttonsState } = payload;
      const usedInChannels = state.usedInChannels;

      if (!playlistId || !channelId || !usedInChannels) return state;

      return {
        ...state,
        usedInChannels: {
          ...state.usedInChannels,
          [channelId]: {
            ...state.usedInChannels[channelId],
            ...buttonsState,
            id: channelId,
            isLoaded: true,
          },
        },
      };
    }

    case CurrentPage.RenameLinkedPage: {
      const {
        payload: { newTitle, linkPageId },
      } = action;
      const currentDate = Math.floor(Date.now() / 1000);
      const exLinkPages = state.linkPages.map((it) => {
        if (it.id !== linkPageId) return it;
        return { ...it, title: newTitle, lastModifiedDate: currentDate };
      });
      return { ...state, linkPages: exLinkPages };
    }

    case CurrentPage.UpdateLinkPageDuration: {
      const { linkPageId, value } = payload;
      const exLinkPages = state.linkPages.map((it) => {
        if (it.id !== linkPageId) return it;
        return { ...it, duration: value };
      });
      return { ...state, linkPages: exLinkPages };
    }
    case CurrentPage.ChangeTextElementBlockRedux: {
      const {
        payload: {
          newState,
          innerHtml,
          linkPageId,
          type: localType,
          isSocket,
        },
      } = action;
      const exLinkPages = state.linkPages.map((it) => {
        if (it.id !== linkPageId) return it;
        if (it?.textComponent) {
          if (!newState?.getCurrentContent) return it;
          if (isSocket) {
            it.textComponent.state = EditorState.createWithContent(
              convertFromRaw(newState),
            );
          } else it.textComponent.state = EditorState.createWithContent(newState.getCurrentContent());

          if (innerHtml) it.textComponent.innerHtml = innerHtml;
          if (localType) it.textComponent.type = localType;
          return { ...state, linkPages: exLinkPages };
        }
        return it;
      });

      return state;
    }

    // case CurrentPage.CreateLinkPageR: {
    //   const { pageData } = payload;
    //   const linkPages = state.linkPages;
    //   if (!linkPages) return state;
    //   const newLinkPage = [...linkPages, pageData].sort((a, b) => a.position - b.position);
    //   return { ...state, linkPages: newLinkPage };
    // }

    case CurrentPage.UpdateApproveButtonRedux: {
      const { linkPageId, interactiveItemData } = payload;
      const linkPages = state.linkPages;
      if (!linkPages) return state;
      const exLinkPages = linkPages.map((it) => {
        const newLinkPage = { ...it };
        if (newLinkPage.id !== linkPageId) return newLinkPage;
        if (!interactiveItemData && newLinkPage.approveButtonState) {
          delete newLinkPage.approveButtonState;
          return newLinkPage;
        }
        newLinkPage.approveButtonState = newLinkPage.approveButtonState
          ? Object.assign(newLinkPage.approveButtonState, interactiveItemData)
          : { ...interactiveItemData };
        return newLinkPage;
      });
      return { ...state, linkPages: exLinkPages };
    }

    case CurrentPage.ReplaceLinkPagePlaylistPageR: {
      const { linkPageId, linkPageData } = payload;
      const linkPages = state.linkPages;
      if (!linkPages) return state;
      const exLinkPages = linkPages.map((it) => {
        if (it.id !== linkPageId) return it;
        return { ...it, ...linkPageData };
      });
      return { ...state, linkPages: exLinkPages };
    }
    case CurrentPage.UpdateIsShowCaptionState: {
      const {
        payload: { isNew = false, linkPageId, value },
      } = action;
      const exLinkPages = state.linkPages.map((it) => {
        if (it.id !== linkPageId) return it;
        it.isShowCaption = value;
        it.isNew = isNew;
        return it;
      });
      return { ...state, linkPages: exLinkPages };
    }

    case CurrentPage.UpdateCaption: {
      const {
        payload: { newCaption, playlistId, linkPageId },
      } = action;
      const exLinkPages = state.linkPages.map((it) => {
        if (it.id !== linkPageId) return it;
        it.caption = newCaption;
        it.isNew = false;
        return it;
      });
      return { ...state, linkPages: exLinkPages };
    }
    case CurrentPage.LinkPageTypeUploadR: {
      const {
        payload: { linkPageId },
      } = action;
      const exLinkPages = state.linkPages.map((it) => {
        if (it.id !== linkPageId) return it;
        it.type = 'upload';
        return it;
      });
      return { ...state, linkPages: exLinkPages };
    }

    case CurrentPage.UpdateRemixState: {
      const {
        payload: { value, linkPageId, linkPagesIdsMap },
      } = action;
      const exLinkPages = state.linkPages.map((it) => {
        if ((linkPageId && it.id !== linkPageId) || (linkPagesIdsMap && !linkPagesIdsMap?.[it.id])) return it;
        it.isRemixLocked = value;
        return it;
      });
      return { ...state, linkPages: exLinkPages };
    }

    case CurrentPage.RemoveLinkPageR: {
      const {
        payload: { id },
      } = action;
      const exLinkPages = state.linkPages.filter((it) => it.id !== id);
      if (!state?.linkPages?.length) return { ...state };
      return { ...state, linkPages: exLinkPages };
    }

    case CurrentPage.UpdateCollapseLinkPageRS: {
      const {
        payload: { isCollapsed, linkPageId, isCollapsedAll },
      } = action;
      const updateLinkPage = state.linkPages.map(i => {
        if (i.id === linkPageId || isCollapsedAll) return { ...i, isCollapsed };
        return i;
      });
      return { ...state, linkPages: updateLinkPage };
    }
    case EditPlaylist.ChangeTextElementBlockReduxMaker: {
      const {
        payload: {
          newState,
          innerHtml,
          playlistId,
          linkPageId,
          type: localType,
          isSocket,
        },
      } = action;
      const item = state.linkPages.find((it) => it.id === linkPageId);
      const textComponent = {};
      if (item?.textComponent) {
        if (isSocket && newState?.getCurrentContent) {
          textComponent.state = EditorState.createWithContent(
            convertFromRaw(newState),
          );
        } else if (newState?.getCurrentContent) {
          textComponent.state = EditorState.createWithContent(newState.getCurrentContent());
        } else {
          textComponent.state = newState;
        }
        if (innerHtml) textComponent.innerHtml = innerHtml;
        if (localType) textComponent.type = localType;
        const currentDate = Math.floor(Date.now() / 1000);
        const block = {
          ...item,
          lastModifiedDate: currentDate,
          textComponent: { ...item.textComponent, ...textComponent },
        };
        const newLinkPage = state.linkPages.map((i) => {
          if (i.id === linkPageId) {
            return { ...i, ...block };
          }
          return i;
        });
        return {
          ...state,
          linkPages: newLinkPage,
        };
      }
      return state;
    }
    case CurrentPage.clearId: {
      const newState = { ...state };
      delete newState.id;
      return {
        ...newState,
      };
    }

    case CurrentPage.ClickToApproveButtonR: {
      const { linkPageId, isApprove } = payload;
      const linkPages = [...state.linkPages];
      const theTarget = linkPages.find(
        (it) => it?.libraryComponent?.id === linkPageId,
      );
      if (!theTarget) return { ...state };
      theTarget.interactiveItemData.isApprove = isApprove;
      return { ...state, ...linkPages };
    }

    case MuxContentStatus.addNew: {
      const linkPages = state.linkPages;
      if (!linkPages) {
        return { ...state };
      }
      const { id, playbackID } = payload;
      const theTarget = linkPages.find((it) => it?.libraryComponent?.id === id);
      if (!theTarget) return { ...state };
      theTarget.libraryComponent.playbackID = playbackID;
      return { ...state, ...linkPages };
    }
    case CurrentPage.setSingleUserShareState: {
      const singleUserShareState = { ...state.singleUserShareState };
      singleUserShareState[action.payload.email] = { ...singleUserShareState[action.payload.email], ...action.payload };
      return { ...state, singleUserShareState };
    }
    case CurrentPage.setManyUserShareState: {
      if (!state.id) return state; // if current page not upload

      const { users } = payload;
      const singleUserShareState = { ...state.singleUserShareState };

      users.forEach(i => {
        singleUserShareState[i.email] = { ...singleUserShareState[i.email], ...i };
      });
      return { ...state, singleUserShareState };
    }
    case CurrentPage.setAllUserShareState: {
      return { ...state, singleUserShareState: { ...state.singleUserShareState, ...action.payload } };
    }
    case CurrentPage.clearSingleUserShareState: {
      return { ...state, singleUserShareState: { } };
    }
    case CurrentPage.updateShareStateBySocket: {
      const { shareState } = payload;

      return { ...state, ...shareState };
    }
    case CurrentPage.updateShareStateBySocketPage: {
      const { shareState } = payload;

      return { ...state,
        shareState: {
          ...state.shareState,
          availableTo: shareState.sharedAvailableTo,
          availableFrom: shareState.sharedAvailableFrom,
        } };
    }
    case CurrentPage.setGlobalShareStateToSingleUserState: {
      const newSingleUserShareState = { ...state.singleUserShareState };
      const { dateRangeMark, sharedAvailableFrom, sharedAvailableTo, isCoEdit } = state.shareState;
      Object.keys(newSingleUserShareState).forEach(key => {
        if (newSingleUserShareState[key].globalStateApplied) {
          newSingleUserShareState[key].dateRangeMark = dateRangeMark;
          newSingleUserShareState[key].sharedAvailableFrom = sharedAvailableFrom;
          newSingleUserShareState[key].sharedAvailableTo = sharedAvailableTo;
          newSingleUserShareState[key].isCoEdit = isCoEdit;
        }
      });
      return { ...state, singleUserShareState: newSingleUserShareState };
    }

    case CurrentPage.changePlaylistDurationRedux: {
      if (!payload) {
        return state;
      }
      return { ...state, totalPlaylistDuration: payload };
    }

    case CurrentPage.changeLinkPageDurationByLinkPageIdRedux: {
      const { linkPageId, duration } = payload;
      if (!linkPageId || !duration) {
        return state;
      }
      const linkPages = state.linkPages.map(linkPage => {
        if (linkPage.id === linkPageId) {
          linkPage.duration = duration;
          return linkPage;
        }
        return linkPage;
      });
      return { ...state, linkPages };
    }

    case CurrentPage.ToggleSideBarExpand: {
      return { ...state, isLargeRightBar: action.payload };
    }
    case CurrentPage.ClearItemsSelectionInSmartfile: {
      return {
        ...state, selectedItems: {},
      };
    }
    case CurrentPage.SelectItemInSmartfile: {
      const newState = { ...state };
      const newSelectedItems = { ...newState.selectedItems };
      if (state.selectedItems[action.payload.itemId] && !action.payload.isMouseSelection) {
        delete newSelectedItems[action.payload.itemId];
        return { ...newState, selectedItems: newSelectedItems };
      }
      return {
        ...state,
        selectedItems: {
          ...state.selectedItems,
          [action.payload.itemId]: true,
        },
      };
    }
    case CurrentPage.SelectAllItemsInSmartfile: {
      let newSelectedItems = {};
      if (action.payload.checkBoxState) {
        newSelectedItems = state.linkPages.reduce((acc, cur) => {
          acc[cur.id] = action.payload.checkBoxState;
          return acc;
        }, {});
      }
      return {
        ...state,
        selectedItems: newSelectedItems,
      };
    }

    case CurrentPage.SaveSummarize: {
      const { responseText } = payload;
      return { ...state, currentSummarize: responseText };
    }
    case CurrentPage.SetProcessingItemsInSmartfile: {
      const { ids } = payload;
      return { ...state, currentProcessingTasks: ids };
    }
    case CurrentPage.AIProcessingToggleLoading: {
      const { isLoading, aiType } = payload;
      return { ...state, aiProcessing: { isLoading, type: aiType } };
    }
    case CurrentPage.aiToggleVectorizeLinkPageRS: {
      const { linkPageIds, newState } = payload;
      const newLinkPages = state.linkPages.map(linkPage => {
        const newData = linkPage.textComponent?.id
          ? { isTextSavedToVectorDb: newState }
          : { libraryComponent: { ...linkPage.libraryComponent, hasDescriptionCreatedByAi: newState } };

        if (linkPageIds.includes(linkPage.id)) return { ...linkPage, ...newData };
        return linkPage;
      });
      return { ...state, linkPages: newLinkPages };
    }
    case CurrentPage.aiChangeLinkPageProcessingStatus: {
      const { linkPageIds, status, text } = payload;
      const newLinkPages = state.linkPages.map(linkPage => {
        const newLinkPage = {
          ...linkPage,
          libraryComponent:
          {
            ...linkPage.libraryComponent,
          },
        };
        if (status === aiProcessingStatusEnum.ITEM_PENDING_IN_AI) {
          newLinkPage.libraryComponent.parsedStatusByAiR = null;
        }
        if (linkPageIds.includes(linkPage.id)) {
          newLinkPage.libraryComponent.parsedStatusByAiR = status;
          newLinkPage.libraryComponent.parsedStatusByAi = status;
          newLinkPage.libraryComponent.summaryAI = text;
        }
        return newLinkPage;
      });
      return { ...state, linkPages: newLinkPages };
    }
    case CurrentPage.ToggleSettingPreviewRS: {
      const { settingType, isHidden } = payload;
      return {
        ...state, manager: { ...state.manager, [settingType]: isHidden },
      };
    }
    default:
      return state;
  }
};
