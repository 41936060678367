import React, { useRef, RefObject } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UndoneSvg } from '../../images/icons/undone.svg';
import { ReactComponent as SummarizeSvg } from '../../images/icons/batchActions/summarize.svg';
import { ReactComponent as DeleteSvg } from '../../images/icons/batchActions/delete.svg';
import { ReactComponent as LockSvg } from '../../images/icons/batchActions/lock.svg';
import { ReactComponent as UnlockSvg } from '../../images/icons/batchActions/unlock.svg';
import { ReactComponent as DuplicateSvg } from '../../images/icons/batchActions/copy.svg';
import { ReactComponent as DownloadSvg } from '../../images/icons/batchActions/download.svg';
import { ReactComponent as AddToSvg } from '../../images/icons/batchActions/add_to.svg';
import { ReactComponent as TranslateSvg } from '../../images/icons/batchActions/translate.svg';
import { ReactComponent as SelectSvg } from '../../images/icons/batchActions/select_all.svg';
import {
  BatchActionPopupWrapper,
  SelectedItemsCounter, PopupHeader,
  HorizontalDivider, PopupSectionTitle,
  ItemsSection, SingleItem, HotkeyWrapper,
  ItemName, SmartfilesListWrapper
} from './ai_styled';
import { DATA_PARENTS, emptyCallback, MessageType } from '../../utils/constants';
import PlaylistSelector from './MakerCommon/Table/PlaylistSelector';
import { actionSelectItemsInSmartfile } from '../../redux/currentPage/action';
import { actionShowMessage } from '../../redux/support/action';

interface IItem {
  id: string,
  isRemixLocked: boolean,
  libraryComponent: {
    id: string,
  }
}

interface IBatchActionsPopup { 
  popupRef: React.MutableRefObject<HTMLDivElement | null>,
  selectedItems: { [key: string]: boolean },
  setClosed: React.Dispatch<React.SetStateAction<string | boolean>>
  addToLibraryHandler: (e: React.MouseEvent<Element, MouseEvent>) => void,
  summarizeHandler: (e: React.MouseEvent<Element, MouseEvent>) => void,
  duplicateHandler: (e: React.MouseEvent<Element, MouseEvent>) => void,
  downloadHandler: (e: React.MouseEvent<Element, MouseEvent>) => void,
  deleteHandler: (e: React.MouseEvent<Element, MouseEvent>) => void,
  lockHandler: (e: React.MouseEvent<Element, MouseEvent>) => void,
  isSixDots?: boolean,
  item?: IItem,
  isLocked?: boolean;
}

interface ISingleOption { 
  name: string,
  icon: any,
  hotKey: string,
  type: string,
  isDelete?: boolean,
}


const PopupSingleItem: React.FC<{
  elem: ISingleOption, isAI?: boolean,
  setClosed: React.Dispatch<React.SetStateAction<string | boolean>>,
  handler: (e: React.MouseEvent<Element, MouseEvent>) => void,
  dispatch: any,
  isDisabled?: boolean,
  item?: IItem,
}> = ({ elem, isAI, setClosed, handler, item, isDisabled, dispatch }) => {
  const refMenu: RefObject<HTMLDivElement> | null = useRef(null);
  const onClickHandler = (e: React.MouseEvent) => {
    setClosed(false);
    if (isDisabled) {
      dispatch(
        actionShowMessage({
          type: MessageType.NoItemsSelected,
        }),
      );
      return;
    }
    handler(e);
  };
  return (
    <SingleItem
      onClick={onClickHandler}
      data-parent={DATA_PARENTS.checkItem}
    >
      <ItemName isDelete={elem.isDelete} isAI={isAI} data-parent={DATA_PARENTS.checkItem}>
        {elem.icon}
        {elem.name}
      </ItemName>
      <HotkeyWrapper isDelete={elem.isDelete} data-parent={DATA_PARENTS.checkItem}>
        {elem.hotKey}
      </HotkeyWrapper>
      {elem.type === 'addToSF' && (
        <SmartfilesListWrapper data-parent={DATA_PARENTS.checkItem}>
          <PlaylistSelector
            isMultiselect={false}
            isForLib={false}
            pageID={item?.id}
            lcID={item?.libraryComponent?.id}
            isWholePlaylistOperation={false}
            refMenu={refMenu}
            setIsDropDownVisible={() => setClosed(false)}
            upvCard={false}
            isBatchActions
          />
        </SmartfilesListWrapper>
      )}
    </SingleItem>
  )
};

export const BatchActionsPopup: React.FC<IBatchActionsPopup> = ({
  popupRef,
  selectedItems,
  isSixDots,
  setClosed,
  duplicateHandler,
  downloadHandler,
  deleteHandler,
  lockHandler,
  summarizeHandler,
  addToLibraryHandler,
  item,
  isLocked,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const optionsObj = {
    regular: [
      {
        handler: downloadHandler,
        name: t('downloadT'),
        icon: <DownloadSvg />,
        hotKey: 'd',
        type: 'download',
      },
      {
        handler: duplicateHandler,
        name: t('duplicateT'),
        icon: <DuplicateSvg />,
        hotKey: 'ctrl + d',
        type: 'duplicate',
      },
      {
        handler: lockHandler,
        name: (isSixDots && item?.isRemixLocked || isLocked) ? t('unlockT') : t('lockT'),
        icon: (isSixDots && item?.isRemixLocked || isLocked) ? <UnlockSvg /> : <LockSvg />,
        hotKey: 'l',
        type: 'lock',
      },
      {
        handler: emptyCallback,
        name: t('addToAnotherSmartfileDropdownOptionT'),
        icon: <AddToSvg />,
        hotKey: 'ctrl + m',
        type: 'addToSF',
      },
      {
        handler: addToLibraryHandler,
        name: t('addToLibraryDropdownOptionT'),
        icon: <AddToSvg />,
        hotKey: 'ctrl + l',
        type: 'addToLib',
      },
    ],
    ai: [
      {
        handler: summarizeHandler,
        name: t('addSummaryT'),
        icon: <SummarizeSvg />,
        hotKey: 's',
        type: 'summarize',
      },
      {
        handler: emptyCallback,
        name: 'Translate',
        icon: <TranslateSvg />,
        hotKey: 't',
        type: 'translate',
      },
      {
        handler: deleteHandler,
        name: t('deleteT'),
        icon: <DeleteSvg />,
        hotKey: 'del',
        type: 'delete',
        isDelete: true,
      },
    ],
  };

  const handleSelect = () => {
    dispatch(actionSelectItemsInSmartfile(true));
  };

  const isDisabled = !isSixDots && Object.keys(selectedItems).length === 0;

  return (
    <BatchActionPopupWrapper ref={popupRef}>
      {!isSixDots && (
        Object.keys(selectedItems).length ? (
          <PopupHeader>
            <UndoneSvg />
            <SelectedItemsCounter>
              {Object.keys(selectedItems).length}
            </SelectedItemsCounter>
            {t('batchActionsT')}
          </PopupHeader>
        ) : (
          <PopupHeader data-parent={DATA_PARENTS.checkItem} onMouseDown={handleSelect}>
            <SelectSvg data-parent={DATA_PARENTS.checkItem} />
            {t('selectAllT')}
          </PopupHeader>
        )
      )}
      {!isSixDots && <HorizontalDivider data-parent={DATA_PARENTS.checkItem} />}
      <PopupSectionTitle data-parent={DATA_PARENTS.checkItem}>
        {t('manageT')}
      </PopupSectionTitle>
      <ItemsSection data-parent={DATA_PARENTS.checkItem}>
        {optionsObj.regular.map(elem => (
          <PopupSingleItem
            elem={elem}
            setClosed={setClosed}
            handler={elem.handler}
            item={item}
            isDisabled={isDisabled}
            dispatch={dispatch}
          />
        ))}
      </ItemsSection>
      <HorizontalDivider data-parent={DATA_PARENTS.checkItem} />
      <PopupSectionTitle data-parent={DATA_PARENTS.checkItem}>
        {t('aIAssistantT')}
      </PopupSectionTitle>
      <ItemsSection data-parent={DATA_PARENTS.checkItem}>
        {optionsObj.ai.map(elem => (
          <PopupSingleItem
            elem={elem}
            isAI
            setClosed={setClosed}
            handler={elem.handler}
            isDisabled={isDisabled}
            dispatch={dispatch}
          />
        ))}
      </ItemsSection>
    </BatchActionPopupWrapper>
  );
};
