import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentState, EditorState } from 'draft-js';
import { useDispatch, useSelector } from 'react-redux';
import createImagePlugin from 'draft-js-image-plugin';
import styles from './MomentBlock.module.css';
import './MomentBlock.css';
import 'draft-js/dist/Draft.css';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { requestUpdateLibraryComponent } from '../../utils/request';
import BlockOption from '../DropDownOption/BlockOption';
import addComponentIcon from '../../images/icons/add_component.svg';
import {
  actionBulkToggleIsBlockHidden,
  actionChangeStateButton,
  actionClearPreSaveFlag,
  actionSwitchPage,
  actionToggleIsBlockHidden,
  actionToggleLibraryDrag,
  actionUpdateBlock,
} from '../../redux/currentPage/action';
import CreateBlockOption from '../DropDownOption/CreateBlockOption';
import SeparatorLineBlock from './SeparatorLineBlock';
import { ReactComponent as DragDotsSvg } from '../../images/icons/drag_dots.svg';
import { ReactComponent as EyeOffSvg } from '../../images/icons/eye_off.svg';

import { ReactComponent as DragsSvg } from '../../images/icons/many_drag.svg';
import LinkPreviewBlock from './LinkPreviewBlock';
import BlockImageAdd from '../BlockImageAdd/MediaAdd';
import {
  actionBlockUnloading,
  actionUnblockUnloading,
} from '../../redux/user/action';
import { BlockTypes, empty, UiComponentTypes } from '../../utils/constants';
import useThrottle from '../../utils/hooks/useThrottle';
import ActionDraggable from '../../redux/dragable/action';
import ErrorBoundary from '../ErrorBoundary';
import ResizeImage from '../ResizeImage';
import { ApproveButton } from './CustomBlocks';
import ActionButton from './CustomBlocks/ActionButton';
import ShortText from './CustomBlocks/ShortText';
import Tooltip from '../../shared/Tooltips/Tooltip';
import PageLink from './CustomBlocks/PageLink';
import { innerHtmlImageTemplateShort } from '../../utils/helpers';
import {
  actionChangeSupportEditableBlockRedux,
  actionUpdateSupportBlock,
} from '../../redux/support/action';
import EmbedBlock from './CustomBlocks/EmbedBlock';
import PdfBlock from './CustomBlocks/pdfBlock/PdfBlock';
import { actionCreator } from '../../shared/redux/actionHelper';
import { EditPage } from '../../redux/pages/types';
import GoogleEmbedPreviewBlock from './GoogleEmbedPreviewBlock';
import MediaAddPlaceholder from '../BlockImageAdd/MediaAddPlaceholder';
import DropboxEmbedPreviewBlock from './DropboxEmbedPreviewBlock';
import { createUpdateComponentBody } from '../../utils/query/helper';
import BlockUpload from './CustomBlocks/BlockUpload';

// const OSName = window.navigator.platform;

const imagePlugin = createImagePlugin();

const MomentBlockOther = ({
  stopPropagation,
  clear,
  index,
  setIdOpenMenuBlock,
  idOpenMenuBlock,
  isDragging,
  state,
  componentId,
  position,
  innerHtml,
  dragHandleProps,
  addEditableBlock,
  deleteEditableBlock,
  onChangeEditableBlock,
  onChangeBlocksWithData,
  type1,
  handlerClick,
  canUpdForRedoUndo,
  setCanUpdForRedo,
  page,
  isLibraryCreate,
  isBlockHidden,
  currentPageId,
  isBuilderTextElement,
  isLibraryPage,
  data,
  ...props
}) => {
  const [refMenu, isOpenMenu, setIsOpenMenu] = useComponentVisible(false);
  const [refMenuBrowse, isOpenMenuBrowse, setOpenMenuBrowse] = useComponentVisible(false);
  const [throttleSave] = useThrottle();
  const [header, setHeader] = useState(false);
  const [localState, setLocalState] = useState(state || empty);
  const [type, setType] = useState(type1);
  const [isNeedToUpdate, setNeedToUpdate] = useState(false);
  const editor = useRef(null);
  const editorWR = useRef(null);
  const editorWrapperRef = useRef(null);
  const { draggableBlocks } = useSelector((reduxState) => reduxState.draggable);
  const { newBlockId, blocks } = useSelector((reduxState) => (
    isLibraryCreate ? reduxState.support : reduxState.currentPage),
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isHasContent = localState
    && localState.getCurrentContent
    && (localState.getCurrentContent().blockMap.size !== 1
      || (type === BlockTypes.image && !props.isNew));
  const isMedia = type === BlockTypes.image || type === BlockTypes.video;
  const isPage = type === BlockTypes.page;
  const isEmbed = type === BlockTypes.embed;
  const isUpload = type === BlockTypes.upload;
  const isPdf = type === BlockTypes.pdf;
  const isHasPdfPreview = UiComponentTypes.ms[type]
    || UiComponentTypes.xls[type]
    || UiComponentTypes.pp[type]
    || UiComponentTypes.presentation[type];

  const isButton = type === BlockTypes.approveButton
    || type === BlockTypes.actionButton
    || type === BlockTypes.shortText;
  const isWebSite = type === BlockTypes.webSite;
  const isGoogleEmbed = type === BlockTypes.googleEmbed;
  const isDropboxEmbed = type === BlockTypes.dropboxEmbed;
  const isSeparatorLine = type === BlockTypes.lineSeparator;
  const isUnloadingBlocked = useSelector(
    (reduxState) => reduxState.user.isUnloadingBlocked,
  );
  const isHasMediaContentOrNotMedia = (isMedia && isHasContent) || !isMedia;
  const isLargeRightBar = useSelector(
    (reduxState) => reduxState.currentPage.isLargeRightBar,
  );
  const [hoverBlock, setHoverBlock] = useState(false);
  const playlistField = false;
  useEffect(
    () => {
      if (!isOpenMenu && setIdOpenMenuBlock) setIdOpenMenuBlock('');
    },
    [isOpenMenu],
  );

  const selectAddEditableBlock = (
    itemType,
    id,
    isClone,
    eraseContent,
    text,
  ) => {
    if (isOpenMenu) setTimeout(() => setIsOpenMenu(false), 0);
    if (props.changeTypeBlock) {
      // for page
      props.changeTypeBlock(itemType, eraseContent, id, text);
      return;
    }
    addEditableBlock(itemType, id, isClone, eraseContent, text);
  };

  const duplicateBlock = () => {
    if (isOpenMenu) setTimeout(() => setIsOpenMenu(false), 0);
    if (props.addDuplicateBlock) {
      props.addDuplicateBlock();
    } else addEditableBlock('', '', true);
  };

  const addNewEmptyEditableBlock = (order) => {
    if (isOpenMenu) setTimeout(() => setIsOpenMenu(false), 0);
    if (props.addNewEmptyEditableBlock) {
      props.addNewEmptyEditableBlock(order);
    } else addEditableBlock(order);
  };

  const save = (newState, newHtml, width) => {
    if (isPdf || isHasPdfPreview) return;
    if (
      type1 === BlockTypes.embed
      || type === BlockTypes.embed
      || type1 === BlockTypes.dropboxEmbed
      || type === BlockTypes.dropboxEmbed
      || type1 === BlockTypes.googleEmbed
      || type === BlockTypes.googleEmbed
    ) {
      if (isUnloadingBlocked) dispatch(actionUnblockUnloading());
      return;
    }
    if (
      newState
      && newState?.innerHtml
      && newState?.innerHtml === localState?.innerHtml
      && !props.createComponentWidget
    ) {
      if (isUnloadingBlocked && !props.createComponentWidget) {
        dispatch(actionUnblockUnloading());
      }
      return;
    }
    const html = editorWR?.current?.innerHTML;
    const htmlForUpd = type !== BlockTypes.image
      ? innerHtmlImageTemplateShort(
        props.urlVerySmallImage || props.urlFile,
        width || props.width,
      )
      : newHtml || html;
    if (!isLibraryCreate && componentId) {
      const lastModifiedDate = Math.floor(Date.now() / 1000);
      if (newState?.data?.lineType) {
        requestUpdateLibraryComponent(
          createUpdateComponentBody(
            {
              id: componentId,
              state: newState || localState,
              position: isBuilderTextElement ? 0 : position,
              innerHtml: htmlForUpd,
              type: BlockTypes.lineSeparator,
            },
            currentPageId,
            null,
            isBuilderTextElement,
            null,
            lastModifiedDate,
          ),
        ).then(() => {
          if (isUnloadingBlocked) {
            dispatch(actionSwitchPage({ lastModifiedDate }));
            dispatch(actionUnblockUnloading());
          }
        });
      } else {
        if (
          type !== BlockTypes.shortText
          && ((isBuilderTextElement && !canUpdForRedoUndo?.current)
            || !isBuilderTextElement)
        ) {
          const newState2 = newState || localState;
          const selectionState = newState2.getSelection();
          if (!canUpdForRedoUndo?.current) {
            canUpdForRedoUndo.current = false;
            onChangeEditableBlock(
              EditorState.create({
                currentContent: newState2.getCurrentContent(),
                selection: selectionState,
              }),
              htmlForUpd,
            );
          }
        }
        requestUpdateLibraryComponent(
          createUpdateComponentBody(
            {
              id: componentId,
              state: newState || localState,
              position: isBuilderTextElement ? 1 : position,
              innerHtml: htmlForUpd,
              type,
            },
            currentPageId,
            width,
            isBuilderTextElement,
            null,
            lastModifiedDate,
          ),
        ).then(() => {
          if (isUnloadingBlocked) {
            dispatch(actionSwitchPage({ lastModifiedDate }));
            dispatch(actionUnblockUnloading());
          }
        });
      }
    } else if (isLibraryCreate && componentId) {
      onChangeEditableBlock(newState || localState, htmlForUpd);
    }
  };

  useEffect(() => {
    if (localState && props.preSave && props.createComponentWidget) {
      if (localState.data) {
        setTimeout(() => {
          const html = editorWR?.current?.innerHTML;
          throttleSave(() => () => save(localState, html));
        }, 100);
      } else if (localState?.getCurrentContent) {
        if (!isUnloadingBlocked) dispatch(actionBlockUnloading());
        setTimeout(() => {
          const html = editorWR?.current?.innerHTML;
          throttleSave(() => () => save(localState, html));
        }, 100);
      }
      dispatch(actionClearPreSaveFlag(componentId, playlistField));
    }
  }, [props.preSave]);

  useEffect(() => {
    if (editorWR?.current?.getElementsByClassName('show-link').length) return () => {};
    if (
      editorWR?.current?.getElementsByClassName(
        'public-DraftEditorPlaceholder-inner',
      ).length
    ) return () => {};
    if (
      editorWR?.current?.innerHTML
      && editorWR?.current?.innerHTML !== innerHtml
      && type === BlockTypes.image
    ) {
      // TODO GIX FOR EMPTY
      // save();
    } else if (!innerHtml && type !== 'page') {
      // need more test
      // save();
    }
  }, [localState]);

  // show-hide widget. no onDrag event available
  useEffect(() => {
    if (props.showDragWidget) {
      dispatch(actionToggleLibraryDrag(true));
      setIsOpenMenu(false);
    }
  }, [props.showDragWidget]);

  useEffect(() => {
    if (type1 !== type) {
      setType(type1);
      setLocalState(state);
    }
  }, [type1]);

  useEffect(() => {
    if (newBlockId === componentId) {
      setTimeout(() => {
        editor?.current?.focus();
      }, 0);
    }
  }, [componentId, type, newBlockId]);

  const selectAddEditableBlockClear = (itemType, id, isClone, eraseContent) => {
    const editorState = EditorState.push(
      localState,
      ContentState.createFromText(''),
    );
    if (type !== itemType) {
      selectAddEditableBlock(itemType, id, isClone, eraseContent);
    } else {
      onChangeEditableBlock(editorState, '');
      selectAddEditableBlock(itemType, id, isClone);
    }
  };
  const selectAddEditableBlockSave = (itemType, id, isClone) => {
    if (
      type !== BlockTypes.approveButton
      && type !== BlockTypes.shortText
      && type !== BlockTypes.actionButton
    ) {
      onChangeEditableBlock(localState, editorWR?.current?.innerHTML);
    }
    if (!isLibraryCreate && isUnloadingBlocked) dispatch(actionUnblockUnloading());
    selectAddEditableBlock(itemType, id, isClone);
  };

  useEffect(() => {
    if (page.title) {
      setHeader(page.title);
    }
  }, [page.title]);

  useEffect(() => {
    if (
      isNeedToUpdate
      || isWebSite
      || isGoogleEmbed
      || canUpdForRedoUndo?.current
      || type === BlockTypes.image
    ) {
      isNeedToUpdate && setNeedToUpdate(false);
      setLocalState(state);
    }
  }, [state]);

  const updateSize = (width) => {
    const htmlForUpd = innerHtmlImageTemplateShort(
      props.urlVerySmallImage || props.urlFile,
      width,
    );
    if (props.createComponentWidget) {
      dispatch(
        actionUpdateSupportBlock(
          componentId,
          localState,
          width,
          null,
          playlistField,
        ),
      );
    } else {
      dispatch(
        actionCreator(EditPage.ResizeImageInBlock, {
          id: componentId,
          width,
          html: htmlForUpd,
          state: localState,
          position,
        }),
      );
    }
  };

  const handlerClickOnDots = (event) => {
    handlerClick(event, componentId);
    setIsOpenMenu(true);
    event.stopPropagation();

    if (event.currentTarget !== event.target) {
      dispatch(ActionDraggable.BlocksOnPage([]));
      dispatch(ActionDraggable.SetHasDraggable(false));
    }
  };
  const onDotsMouseDownHandler = (event) => {
    event.stopPropagation();
    if (!props.blocksIds.length || !props.blocksIds.includes(componentId)) {
      props.selectBlock([componentId]);
    }
    if (props.blocksIds.length) dispatch(ActionDraggable.BlocksOnPage(props.blocksIds));
    editor.current?.blur();
    props.createComponentWidget
      ? dispatch(
        actionUpdateSupportBlock(
          componentId,
          localState,
          null,
          null,
          playlistField,
        ),
      )
      : dispatch(
        actionUpdateBlock(componentId, localState, null, null, playlistField),
      );
  };

  useEffect(() => {
    if (idOpenMenuBlock !== componentId) setIsOpenMenu(false);
  }, [idOpenMenuBlock]);

  const saveState = (stateButton, optionalHtml) => {
    if (props.createComponentWidget) {
      dispatch(
        actionChangeSupportEditableBlockRedux(
          componentId,
          stateButton,
          optionalHtml || editorWrapperRef?.current?.innerHTML,
        ),
      );
    } else {
      dispatch(
        actionChangeStateButton({
          state: stateButton,
          innerHtml: optionalHtml || editorWrapperRef?.current?.innerHTML,
          type,
          id: componentId,
        }),
      );
    }
  };
  if (isDragging && !!draggableBlocks.length) {
    return (
      <div className={` wrapper_drag_${type}`}>
        <div className={styles.count}>
          <DragsSvg />
          <div> {draggableBlocks.length} BLOCKS</div>
        </div>
      </div>
    );
  }
  const dragEndHandler = () => {
    const div = document.getElementById('draggable_page');
    // todo obsolete Page drag
    // dispatch(actionDragPage(null, null));
    // dispatch(actionDragManyPage(false));
    dispatch(ActionDraggable.BlocksOnPage([]));
    const parent = document.getElementById('root');
    if (div) parent.removeChild(div);
    dispatch(ActionDraggable.SetHasDraggable(false));
  };

  const dragStartHandler = (e) => {
    setIsOpenMenu(false);
    const draggableId = draggableBlocks.filter((i) => i);
    let handledBlocks;
    if (draggableId.includes(componentId)) {
      handledBlocks = draggableId;
    } else {
      handledBlocks = [componentId];
    }
    props.selectBlock(handledBlocks);

    dispatch(ActionDraggable.BlocksOnPage(handledBlocks, playlistField));
    const div = document.createElement('div');
    div.className = 'draggable_page';
    div.id = 'draggable_page';
    if (handledBlocks.length > 1) {
      div.textContent = `${handledBlocks.length} BLOCKS`;
    } else {
      div.textContent = '1 BLOCK';
    }
    // todo obsolete Page Drag
    // if (!isLibraryCreate) dispatch(actionDragManyPage(true));
    const parent = document.getElementById('root');
    parent.appendChild(div);
    e.dataTransfer.setDragImage(div, 117, 20);
  };

  const toggleIsHidden = (e, id, value) => {
    if (draggableBlocks?.length > 1) {
      const processedBlocks = [];
      blocks.forEach((item) => {
        if (draggableBlocks.includes(item.id)) {
          processedBlocks.push({
            id: item.id,
            isHidden: !item.isHidden,
            type: item.type,
          });
        }
      });
      dispatch(actionBulkToggleIsBlockHidden(processedBlocks));
      e.preventDefault();
      e.stopPropagation();
    } else {
      dispatch(actionToggleIsBlockHidden(id, value, null, playlistField));
      e.preventDefault();
      e.stopPropagation();
    }
    clear();
  };

  return (
    <div
      className={`${styles.wrapper_component_balancer} 
      ${isBuilderTextElement ? styles.builder_text_element : ''}
       `}
    >
      <div
        className={`wrapper_component_${type}  
      ${
        isLibraryCreate || playlistField
          ? styles.wrapper_component_in_library
          : styles.wrapper_component
      } 
      ${isBuilderTextElement ? styles.wrapper_component_in_builder : ''} 
      ${isLargeRightBar ? styles.wrapper_component_small : ''} 
      `}
      >
        {!isBuilderTextElement && (
          <div
            className={` 
            ${styles.add_line} ${
              isLibraryCreate || playlistField ? styles.add_line_in_library : ''
            }
          `}
          >
            <div className={` ${styles.img_wrapper} `}>
              <img
                draggable={false}
                src={addComponentIcon}
                onClick={() => addNewEmptyEditableBlock('new_before')}
                alt="add component"
              />
            </div>

            <div className={` ${styles.separating_line} `} />
            <div className={` ${styles.img_wrapper} `}>
              <img
                draggable={false}
                src={addComponentIcon}
                onClick={() => addNewEmptyEditableBlock('new')}
                alt="add component"
              />
            </div>
          </div>
        )}

        <div
          onMouseUp={stopPropagation}
          onMouseMove={stopPropagation}
          onMouseDown={stopPropagation}
          className={`component_${type} 
          component 
          ${isBuilderTextElement ? styles.builder_component : styles.component}
          ${isBuilderTextElement ? 'builder_component' : ''}`}
        >
          {!isBuilderTextElement && (
            <div
              // onMouseDown={(e) => { e.stopPropagation(); }}
              className={`height_wrapper_component_${type} height_wrapper_component`}
            >
              <div
                ref={refMenu}
                data-parent="data-parent"
                draggable
                onDragEnd={dragEndHandler}
                onDragStart={dragStartHandler}
                onMouseDown={onDotsMouseDownHandler}
                onClick={handlerClickOnDots}
                onMouseUp={() => {
                  dispatch(ActionDraggable.BlocksOnPage([]));
                  dispatch(ActionDraggable.SetHasDraggable(false));
                }}
                className={`${styles.rectangle} ${
                  isOpenMenu && styles.rectangle_active
                }`}
              >
                <DragDotsSvg
                  className={`${styles.dots} ${
                    isOpenMenu && styles.dots_active
                  }`}
                />

                {isOpenMenu && (
                  <>
                    <BlockOption
                      blocksToCreateLComponent={props.blocksToCreateLComponent}
                      setBlocksToCreateLComponent={
                        props.setBlocksToCreateLComponent
                      }
                      blocksIds={props.blocksIds}
                      blocksIndices={props.blocksIndices}
                      mouseup={props.mouseup}
                      isOpenMenu={isOpenMenu}
                      isLibraryCreate={isLibraryCreate}
                      setIsOpenMenu={setIsOpenMenu}
                      isCreateComponentMenuVisible={
                        props.isCreateComponentMenuVisible
                      }
                      setIsCreateComponentMenuVisible={
                        props.setIsCreateComponentMenuVisible
                      }
                      selectAddEditableBlock={selectAddEditableBlockSave}
                      canTurnInto={!isPage && !isMedia}
                      parentRef={refMenu}
                      playlistField={playlistField}
                      addCloneBlock={() => {
                        duplicateBlock();
                        // to cancel selection on duplicated block
                        clear();
                      }}
                      deleteEditableBlock={deleteEditableBlock}
                      type={type}
                      setOpenMenuDetach={props.setOpenMenuDetach}
                      isBlockHidden={isBlockHidden}
                      toggleIsHidden={toggleIsHidden}
                      componentId={componentId}
                      clear={clear}
                    />
                  </>
                )}
              </div>
            </div>
          )}
          <div
            className={`${hoverBlock ? styles.hiddenBlockWrapperBg : ''} ${
              styles.hiddenWrapper
            } opacityWrapper`}
          >
            {!isBuilderTextElement && (
              <div
                className={`${styles.hiddenContainer} ${
                  isBlockHidden ? styles.visible : ''
                }`}
                onMouseDown={(e) => toggleIsHidden(e, componentId, false)}
                onMouseEnter={() => setHoverBlock(true)}
                onMouseLeave={() => setHoverBlock(false)}
              >
                <Tooltip text={t('showBlockT')} direction="down">
                  <div className={`${styles.imageContainer} ${styles.visible}`}>
                    <EyeOffSvg />
                  </div>
                </Tooltip>
              </div>
            )}

            <div
              ref={editorWrapperRef}
              className={` ${styles.wrapper} ${
                isBlockHidden ? styles.addOpacity : ''
              } `}
              onKeyDown={() => {}}
            >
              {isHasMediaContentOrNotMedia
                && !isButton
                && !isPage
                && !isEmbed
                && !isPdf
                && !isHasPdfPreview
                && !isWebSite
                && !isGoogleEmbed
                && !isSeparatorLine && (
              // {isHasMediaContentOrNotMedia && !isPage && !isWebSite && !isSeparatorLine && !!plugins.length && (
                <>
                  <div ref={editorWR}>
                    <div className="customEditor">
                      <ResizeImage
                        save={updateSize}
                        index={index}
                        startWith={props.width}
                        type={type}
                        innerHtml={innerHtml}
                        localState={localState}
                      >
                        <ErrorBoundary>
                          <div>
                            <figure>
                              <img
                                src={props.urlSmallImage || props.urlFile}
                                alt=""
                              />
                            </figure>
                          </div>
                        </ErrorBoundary>
                      </ResizeImage>
                    </div>
                  </div>
                </>
              )}
              {isWebSite && localState?.data && (
                <LinkPreviewBlock data={localState.data} editorWR={editorWR} />
              )}
              {isGoogleEmbed && localState?.data && (
                <GoogleEmbedPreviewBlock
                  data={localState.data}
                  editorWR={editorWR}
                  index={index}
                />
              )}
              {isDropboxEmbed && localState?.data && (
                <DropboxEmbedPreviewBlock
                  data={localState.data}
                  editorWR={editorWR}
                  index={index}
                />
              )}
              {isSeparatorLine && (
                <SeparatorLineBlock
                  data={localState.data}
                  setLocalState={setLocalState}
                  editorWR={editorWR}
                  componentId={componentId}
                  saveState={saveState}
                />
              )}
              {isPage && (
                <PageLink
                  page={page}
                  header={header}
                  isViewMode={props.isViewMode}
                  isLibraryCreate={isLibraryCreate}
                />
              )}

              {type === BlockTypes.approveButton && (
                <ApproveButton
                  saveState={saveState}
                  state={localState}
                  id={componentId}
                />
              )}
              {type === BlockTypes.actionButton && (
                <ActionButton
                  saveState={saveState}
                  state={localState}
                  id={componentId}
                />
              )}
              {type === BlockTypes.shortText && (
                <ShortText
                  saveState={saveState}
                  state={localState}
                  id={componentId}
                />
              )}
              {isEmbed && (
                <EmbedBlock
                  state={state}
                  innerHtml={innerHtml}
                  saveItem={onChangeBlocksWithData}
                />
              )}
              {isUpload && (
                <BlockUpload
                  index={index}
                  libCompId={props.libCompId}
                  id={componentId}
                />
              )}
              {type === BlockTypes.image && !isHasContent && (
                <BlockImageAdd
                  index={index}
                  playlistField={playlistField}
                  libCompId={props.libCompId}
                  id={componentId}
                  modifier={(newUrl) => onChangeEditableBlock(
                    imagePlugin.addImage(localState, newUrl),
                  )
                  }
                  isLibraryCreate={isLibraryCreate}
                  data={data}
                  position={position}
                  nextItemPosition={props.nextItemPosition}
                />
              )}
              {type === BlockTypes.imageUploadPlaceHolder && !isHasContent && (
                <MediaAddPlaceholder
                  index={index}
                  data={data}
                  id={componentId}
                />
              )}
              {(isPdf || isHasPdfPreview) && (
                <PdfBlock
                  state={localState}
                  innerHtml={innerHtml}
                  relationId={componentId}
                  componentId={componentId}
                  // isSharePage={isSharePage}
                />
              )}
            </div>
          </div>
          <div ref={refMenuBrowse}>
            {isOpenMenuBrowse && (
              <>
                <CreateBlockOption
                  selectAddEditableBlock={selectAddEditableBlockClear}
                  parentRef={refMenuBrowse}
                  setIsComponentVisible={setOpenMenuBrowse}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MomentBlockOther;
